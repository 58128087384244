@import '../../../styles/ifu-shared.scss';

.ccl__dcx_ifu-wrapper {
  &--title {
    color: black;
  }

  &--breadcrumb {
    margin-top: 16px;

    .ccl-e-breadcrumb__item {
      font-weight: 500;
      text-decoration: none;
      color: $cochlear-purple;
      font-size: 14px;
      line-height: 20px;
      font-family: $font-circular-medium;
      margin-top: 0;
      display: inline;

      &.ccl-e-breadcrumb__item__separator {
        &:last-child {
          display: none;
        }
      }
    }

    li.ccl-e-breadcrumb__item > span {
      color: $color-base-brandxtra-brandnavy;
      vertical-align: bottom;
      margin-right: 8px;
    }
  }

  .ccl-e-formatted-composite {
    display: none;

    &--dcx {
      display: block;
    }

    .ccl__data-table--dcx .ccl__results-heading & {
      font-size: 16px;
      line-height: 24px;
      color: $color-base-brandxtra-brandnavy;
      font-weight: 400;
      font-family: $font-circular-regular;
    }
  }

  .ccl__data-table--dcx {
    width: 100%;

    .ccl-e-image {
      background-color: transparent;
      width: 5.25rem;
      margin: auto;
      display: none;
    }

    .ccl__icon--chevron-right {
      cursor: pointer;
    }

    .ccl__dcx-error {
      margin: auto;

      .ccl-c-error-message {
        &__button-control {
          display: none;
        }
        &__content {
          display: flex;
        }
        &__text {
          margin: 1rem 0 0 1rem;
        }
      }
    }

    .ccl-e-table {
      &__thead {
        &__tr {
          &__th {
            border-width: 0 0 2px;
            background-color: transparent;
            max-width: 65%;

            .ccl-e-table__column-title {
              font-weight: 500;
              font-family: $font-circular-medium;
            }

            .ccl-e-table__thead__column-title {
              font-weight: 500;
              font-family: $font-circular-medium;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      &__tbody {
        &__tr {
          &__td {
            vertical-align: top;
            max-width: 65%;
            border-bottom: 1px solid #cfcfd0;
            background-color: #fff;
            &:first-child {
              .ccl-e-link--text {
                .ccl__text--interactive-small-text {
                  font-weight: 700;
                  font-family: $font-circular-medium;
                }
              }
            }

            &:last-child {
              .ccl-e-link--text {
                .ccl__text--interactive-small-text {
                  font-family: $font-circular-regular;
                  text-decoration: underline;
                  color: $font-darkblue;
                }
              }
            }
          }

          &--highlight {
            background-color: $color-lightmode-semantic-background-highlightsubtle;
          }

          &:hover {
            background-color: $color-lightmode-semantic-background-highlightsubtle;
          }
        }
      }
    }

    &--equipment {
      th:nth-child(3) {
        width: 35%;
      }

      th:nth-child(4) {
        width: 35%;
      }
    }
  }

  &--detailsView-table {
    margin-top: 24px;
    margin-bottom: 52px;

    .ccl__ifu-search--tips {
      margin-top: 42px;
    }
  }

  .ccl__title--heading-4 {
    color: #000;
    font-family: $font-circular-medium;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
  }

  .ccl-e-formatted-composite {
    font-family: $font-circular-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-base-brandxtra-brandnavy;
  }

  .ccl__container {
    width: 100%;
    display: block;
  }

  &--footerTips {
    font-family: $font-circular-regular;
    margin: 50px auto;
    font-size: 16px;
    line-height: 24px;

    p,
    span,
    a {
      font-family: $font-circular-regular;
    }
  }
}
