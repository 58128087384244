@font-face {
  font-family: "Knockout-30";
  src: url("./Knockout/Knockout-30JuniorWelterwt.eot");
  src:
    url("./Knockout/Knockout-30JuniorWelterwt.eot?#iefix")
      format("embedded-opentype"),
    url("./Knockout/Knockout-30JuniorWelterwt.woff2") format("woff2"),
    url("./Knockout/Knockout-30JuniorWelterwt.woff") format("woff"),
    url("./Knockout/Knockout-30JuniorWelterwt.svg#Knockout-30JuniorWelterwt")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BlissPro-Regular";
  src: url("./BlissPro/BlissPro-Regular.eot");
  src:
    url("./BlissPro/BlissPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("./BlissPro/BlissPro-Regular.woff2") format("woff2"),
    url("./BlissPro/BlissPro-Regular.woff") format("woff"),
    url("./BlissPro/BlissPro-Regular.svg#BlissPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BlissPro-Light";
  src: url("./BlissPro/BlissPro-Light.eot");
  src:
    url("./BlissPro/BlissPro-Light.eot?#iefix") format("embedded-opentype"),
    url("./BlissPro/BlissPro-Light.woff2") format("woff2"),
    url("./BlissPro/BlissPro-Light.woff") format("woff"),
    url("./BlissPro/BlissPro-Light.svg#BlissPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
	font-family: 'Circular-Bold';
	src: url('./Circular/CircularXX-Bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
  font-family: "Circular-Medium";
  src:
    url("./Circular/CircularXXWeb-Medium.woff2") format("woff2"),
    url("./Circular/CircularXXWeb-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Circular-Regular";
  src:
    url("./Circular/CircularXXWeb-Regular.woff2") format("woff2"),
    url("./Circular/CircularXXWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FontAwesome";
  src: url("./FontAwesome/fontawesome-webfont.eot");
  src:
    url("./FontAwesome/fontawesome-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./FontAwesome/fontawesome-webfont.woff2") format("woff2"),
    url("./FontAwesome/fontawesome-webfont.woff") format("woff"),
    url("./FontAwesome/fontawesome-webfont.svg#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./NotoSans/NotoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./NotoSans/NotoSans-Bold.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans-Italic";
  src: url("./NotoSans/NotoSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "NotoSans-BoldItalic";
  src: url("./NotoSans/NotoSans-Regular.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Acumin-Pro-Extra-Condensed-Black";
  src: url("./Acumin/acumin-pro-extra-condensed-extlt.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-Pro-Extra-Condensed-Black";
  src: url("./Acumin/acumin-pro-extra-condensed-med.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-Pro-Extra-Condensed-Black";
  src: url("./Acumin/acumin-pro-extra-condensed-bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Acumin-Pro-Extra-Condensed-Black";
  src: url("./Acumin/acumin-pro-extra-condensed-black.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Ultralight.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./SFCompactDisplay/SF-Compact-Display-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: bold;
}
