@import '../../../styles/ifu-shared.scss';

.landing-banner {
  &.ifu-banner {
    margin-bottom: 20px;

    @media only screen and (min-width: 64em) {
      margin-bottom: 0;
    }

    .l-padding {
      padding: 0;
    }

    .background {
      height: 100%;
      background-color: $light-grey;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;

      @media only screen and (min-width: 64em) {
        width: 48%;
        border-radius: 0 0 40px 0;
      }
    }

    .content {
      padding: 0;

      h1 {
        margin-top: 0;
        color: #fff;
      }

      &::before {
        display: none;
      }

      @media only screen and (min-width: 64em) {
        width: 49.4%;
        max-width: none;
        padding: 0 0 40px 0;
        min-height: 231px;
      }

      .content-grouper {
        display: block;
        margin: 0 8px;
        padding: 28px 20px 20px 20px;
        position: relative;

        @media only screen and (min-width: 64em) {
          padding: 40px 0 0 36px;
          margin: 0 150px 0 36px;
        }

        .header-highlight {
          height: 100%;
          width: 5px;
          background-color: $cochlear-yellow;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }

        p {
          padding-bottom: 15px;

          @media only screen and (min-width: 64em) {
            padding-bottom: 40px;
          }
        }
      }
    }

    .image {
      position: relative;
      z-index: 2;
      width: calc(100% - 20px);

      @media only screen and (min-width: 64em) {
        width: 56%;
        max-width: none;
        left: 44%;
        margin-top: 0;
        position: absolute;
        height: 90%;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: auto;
        position: relative;
      }
    }

    &.no-image {
      .background {
        border-radius: 0 0 40px 0;
      }

      .image {
        display: none;
      }

      .content {
        padding: 0 0 70px 0;
        min-height: 0;

        @media only screen and (min-width: 64em) {
          padding: 0 0 50px 0;
          width: 100%;
          background-color: $light-grey;
          border-radius: 0 0 40px 0;
        }

        .content-grouper {
          @media only screen and (min-width: 64em) {
            margin-right: 0;
            width: 55%;
          }
        }
      }
    }

    &.full-width {
      margin-bottom: 0;
      min-height: 231px;
      @media only screen and (min-width: 64em) {
        min-height: 252px;
      }
      .background {
        background-color: #384967;
        max-height: 252px;
        @media only screen and (min-width: 64em) {
          display: none;
        }
      }

      .content {
        padding: 0;
        @media only screen and (min-width: 64em) {
          width: 60%;
          border-radius: 0 0 40px 0;
          margin-bottom: 0;
          background-color: transparent;
          z-index: 3;
          position: absolute;
        }

        .content-grouper {
          padding: 60px 0 0 0;
          margin: auto;
          width: fit-content;
          @media only screen and (min-width: 64em) {
            padding: 40px 0 0 36px;
            margin: 0 80px 0 36px;
          }

          p {
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;

            &:last-child {
              font-size: 18px;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: 0px;
              text-align: left;
            }
            @media only screen and (min-width: 64em) {
              font-size: 36px;
              font-weight: 500;
              line-height: 48px;
              letter-spacing: 0px;
              text-align: left;
              color: #fff;
            }
          }

          .header-highlight {
            width: 10px;
          }

          .title-image {
            width: 100%;
            height: auto;
          }
        }
      }

      .image {
        width: 100%;
        margin-bottom: -10px;
        background-repeat: no-repeat;
        background-size: cover;

        @media only screen and (min-width: 64em) {
          max-width: none;
          left: 0;
          z-index: 1;
          margin-top: 0;
          position: absolute;
          height: 100%;
          overflow: hidden;
          max-height: 231px;
        }

        img {
          @media only screen and (min-width: 77.75em) {
            margin-top: -250px;
          }
          display: none;
        }
      }
    }
  }
}

div#root > div[dir='rtl'] {
  .ifu-banner {
    &.landing-banner {
      &.full-width {
        .background {
          border-radius: 0;
        }

        .content {
          .content-grouper {
            margin: 0;
            padding: 30px 30px 0 30px;
          }
        }
      }

      .background {
        left: inherit;
        right: 0;
        border-radius: 0 0 0 40px;
      }

      .content {
        border-radius: 0 0 0 40px;

        .content-grouper {
          padding: 28px 20px 20px 20px;
          margin: 0 8px;

          .header-highlight {
            left: inherit;
            right: 0;
          }
        }
      }
    }

    @media only screen and (min-width: 64em) {
      &.landing-banner {
        .content {
          .content-grouper {
            padding: 40px 36px 0px 0;
            margin: 0 36px 0 150px;
          }
        }
      }
    }

    @media only screen and (min-width: 64em) {
      &.landing-banner {
        &.full-width {
          .content:before {
            left: 100%;
          }
        }

        &:not(.full-width) {
          fa .image {
            left: auto;
            right: 44%;
          }
        }
      }
    }
  }
}
