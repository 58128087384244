.product-card-container {
  margin: 24px 0;

  @media only screen and (min-width: 64em) {
    column-gap: 24px;
    display: flex;
    flex-direction: row;
    margin: 48px 0;
  }

  .product-card {
    display: flex;
    flex: 0 1 50%;
    flex-direction: row;
    padding: 24px;
    border-radius: 8px;
    background-color: $bg-honey-yellow;

    @media only screen and (max-width: 1023px) {
      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .product-card-content .product-card-title {
      color: $font-darkblue;
      font-family: $font-circular-medium;
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .product-card-link .cta-button {
      border-radius: 12px;
      font-family: $font-circular-medium;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
    }
  }

  .product-card-image img {
    height: 120px;
    width: auto;

    @media only screen and (min-width: 48em) {
      height: 144px;
    }
  }
}
