main > header.simple-header {
  &.logo-header {
    border-top: 5px solid $cochlear-yellow;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 100;
    .logo {
      float: none;
      height: auto;
      margin: 0 auto;
      padding: 15px 0 5px 0;
      display: block;
      text-align: center;
      &.pc-show {
        display: none;
        margin: 0;
      }
      &.mb-show {
        display: block;
      }
      img {
        width: auto;
        height: 21px;
      }
    }
    @media only screen and (min-width: 64em) {
      .logo {
        height: 115px;
        text-align: left;
        &.pc-show {
          display: block;
        }
        &.mb-show {
          display: none;
        }
        img {
          width: auto;
          height: 80px;
        }
      }
    }
  }
}
.professionals-page {
  main > header.simple-header {
    &.logo-header {
      border-top: 5px solid $link-colour;
    }
  }
}
// rtl styles
div#root > div[dir='rtl'] {
  .simple-header {
    .logo {
      &.pc-show {
        text-align: right;
      }
    }
  }
}
body > img {
  display: none;
}
