.ceros-container {
  position: relative;
  width: auto;
  height: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  border: 0 none;

  .ceros-experience {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    border: 0 none;
    width: 1px;
    height: 1px;
    min-height: 100%;
    min-width: 100%;
  }
}
