.campaign-cta-container {
  @include fullwidth-component;
  display: flex;
  align-items: center;
  flex-direction: column;

  .cta-question-container {
    display: flex;
    flex-wrap: wrap;
    width: 1158px;
    margin-bottom: 80px;

    .question-bubble {
      height: auto;
      padding: 25px 32px;
      background-color: $bg-question-bubble-grey;
      border-radius: 16px;
      font-family: $font-circular-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      position: relative;
      margin-right: 24px;
      margin-top: 47px;
      color: $font-darkblue;

      @media only screen and (max-width: 47.9375em) {
        font-size: 24px;
        line-height: 32px;
        padding: 28px 50px;
        margin-top: 28px;
      }

      &.twoCol {
        width: 567px !important;
        height: 122px;

        @media only screen and (max-width: 85.312em) {
          width: calc(50% - 12px) !important;
          height: fit-content;
        }

        @media only screen and (max-width: 47.9375em) {
          width: 100% !important;
          min-height: 146px;

          &:nth-child(n) {
            margin-right: 0;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .question-text {
          margin: 0 auto;
        }
      }

      &.threeCol {
        width: 370px !important;
        height: 158px;

        @media only screen and (max-width: 85.312em) {
          width: calc(33.33% - 16px) !important;
          height: fit-content;
        }

        @media only screen and (max-width: 47.9375em) {
          width: 100% !important;
          min-height: 146px;

          &:nth-child(n) {
            margin-right: 0;
          }
        }

        .question-text {
          margin: 0 auto;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &.fourCol {
        width: 271px !important;
        height: 230px;

        @media only screen and (max-width: 85.312em) {
          width: calc(50% - 12px) !important;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 47.9375em) {
          width: 100% !important;
          min-height: 146px;

          &:nth-child(n) {
            margin-right: 0;
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      &::after {
        content: ' ';
        position: absolute;
        left: auto;
        right: 40px;
        bottom: -23px;
        width: 40px;
        height: 23px;
      }

      &:last-child {
        margin-right: 0;
      }

      @media only screen and (max-width: 64em) {
        margin-bottom: 28px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media only screen and (max-width: 85.312em) {
      width: 100%;
    }

    @media only screen and (max-width: 47.9375em) {
      flex-direction: column;
      margin-bottom: 64px;
    }
  }

  .campaign-lists {
    padding: 104px;
    max-width: 1366px;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 85.312em) {
      max-width: 100%;
      width: calc(100% - 16px);
    }
    @media only screen and (min-width: 47.9375em) and (max-width: 62em) {
      padding: 104px 10px;
    }
    @media only screen and (max-width: 47.9375em) {
      border: none;
      padding: 16px;
    }
  }

  .cta-question-text {
    font-family: $font-circular-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0 auto 1px;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $font-darkblue;

    @media only screen and (max-width: 47.9375em) {
      margin-bottom: 8px;
      font-size: 22px;
      line-height: 28px;
    }
  }

  .cta-description-text {
    font-family: $font-circular-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: center;
    color: $font-darkblue;

    @media only screen and (max-width: 47.9375em) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .cta-button-container {
    .cta-button {
      font-family: $font-circular-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: $cochlear-purple;
      border-radius: 16px;
      padding: 12px 32px;
      height: 52px;
      margin: 0 auto;

      &::after {
        top: 3px;
      }

      @media only screen and (max-width: 47.9375em) {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        max-width: 347px;
        text-align: center;
      }
    }
  }

  &.version-1 {
    .cta-question-container {
      .question-bubble {
        &::after {
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOSIgaGVpZ2h0PSIyMyIgZmlsbD0ibm9uZSIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNLjUgMGgzOHYxNC44MDRjMCA2LjgwMi03Ljk1IDEwLjUwMS0xMy4xNTMgNi4xMTlMLjUgMHoiIGZpbGw9IiNjZGNmZDkiLz48L3N2Zz4=');
        }
      }
    }
  }

  &.version-2 {
    background-color: $cochlear-lightyellow;
    border-color: $cochlear-lightyellow;

    .cta-question-container {
      .question-bubble {
        &::after {
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOCIgaGVpZ2h0PSIyMyIgZmlsbD0ibm9uZSIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMCAwaDM4djE0LjgwNGMwIDYuODAyLTcuOTUgMTAuNTAxLTEzLjE1MyA2LjExOUwwIDB6IiBmaWxsPSIjZjFiZTQ4Ii8+PC9zdmc+');
        }

        &.twoCol,
        &.threeCol,
        &.fourCol {
          background-color: $cochlear-yellow-card;
        }
      }
    }
  }

  &.version-3 {
    background-color: $cochlear-lightorange;
    border-color: $cochlear-lightorange;

    .cta-question-container {
      .question-bubble {
        &::after {
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOCIgaGVpZ2h0PSIyMyIgZmlsbD0ibm9uZSIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMCAwaDM4djE0LjgwNGMwIDYuODAyLTcuOTUgMTAuNTAxLTEzLjE1MyA2LjExOUwwIDB6IiBmaWxsPSIjZmNjODliIi8+PC9zdmc+');
        }

        &.twoCol,
        &.threeCol,
        &.fourCol {
          background-color: $cochlear-orange;
        }
      }
    }
  }
}
