.three-up-icon-container {
  @include fullwidth-component;

  .break-line {
    border-bottom: 1px solid $bg-dark-green;
    margin: 80px 0;

    @media only screen and (max-width: 47.9375em) {
      margin: 48px 0;
    }
  }

  &-content {
    padding: 104px;
    max-width: 1366px;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: 79.9375em) {
      padding: 40px 16px;
    }

    @media only screen and (max-width: 47.9375em) {
      padding: 48px 16px;
    }

    &-image-container {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-flow: row wrap;

      @media only screen and (max-width: 47.9375em) {
        flex-direction: column;
      }

      &-icon-image {
        width: 303px;
        margin-right: 67px;
        margin-top: 60px;

        @media only screen and (max-width: 79.9375em) {
          margin-right: 47px;
          margin-top: 40px;
        }

        @media only screen and (max-width: 79.9375em) {
          margin-right: 15px;
          margin-top: 35px;
        }

        @media only screen and (max-width: 47.9375em) {
          max-width: 303px;
          margin: 32px auto 0 auto;
        }

        img {
          width: 128px;
          height: 128px;
          margin-bottom: 24px;

          @media only screen and (max-width: 47.9375em) {
            width: 96px;
            height: 96px;
            margin-bottom: 16px;
          }
        }

        &:nth-child(3n) {
          margin-right: 0;

          @media only screen and (max-width: 47.9375em) {
            margin-right: auto;
          }
        }

        &-text {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
          color: $font-darkblue;

          @media only screen and (max-width: 79.9375em) {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }

    &-title {
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
      font-family: $font-circular-medium;
      color: $font-darkblue;

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      @media only screen and (max-width: 47.9375em) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-getstarted-button {
      margin-top: 60px;

      @media only screen and (max-width: 79.9375em) {
        margin-top: 32px;
      }

      .cta-button {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        font-family: $font-circular-medium;
        color: $cochlear-purple;
        min-width: 190px;
        border-radius: 16px;
        background-color: $cochlear-yellow-card;
        width: fit-content;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        @media only screen and (max-width: 47.9375em) {
          width: 100%;
          max-width: 343px;
          font-size: 16px;
          line-height: 24px;
        }

        &::after {
          left: 12px;
          margin-top: -9px;
        }
      }
    }
  }
}
