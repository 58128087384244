.feature-listing-container {
  color: $font-darkblue;
  margin-top: 100px; /* space between for selection panel */

  .section-title {
    font-family: $font-circular-medium;
    font-size: 20px;
    margin-block: 40px 24px;

    @media only screen and (min-width: 768px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .accordion-container {
    border: 1px solid $cool-greylight;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;

    @media only screen and (min-width: 768px) {
      font-size: 18px;
    }
  }

  .feature-accordion-item:not(:first-child) .accordion-item-title {
    border-top: 1px solid $mid-grey;
  }

  button.accordion-trigger {
    align-items: center;
    background-color: transparent;
    border: none;
    font-family: $font-circular-regular;
    display: flex;
    margin: 0;
    padding: 16px;
    width: 100%;

    &.clickable {
      cursor: pointer;

      &::after {
        background-image: sax(Chevron, $cochlear-purple);
        background-size: 100%;
        content: "";
        width: 24px;
        margin: auto 0;
        height: 24px;
      }
    }

    /* base stylesheet has cursor for all buttons */
    &:not(.clickable) {
      cursor: unset;
    }

    &.active::after {
      transform: rotate(180deg);
    }
  }

  .accordion-container .ccl__icon {
    margin-inline-end: 16px;
  }

  /* custom icon colours not in CDS */
  .accordion-container.compatible .ccl__icon {
    background-color: #007c23;
  }

  .accordion-container.should-be-compatible .ccl__icon {
    background-color: #125fb9;
  }

  .accordion-container.not-compatible .ccl__icon {
    background-color: #be0032;
  }

  .accordion-trigger-title {
    align-self: center;
    flex: 1;
    text-align: start;
  }

  .accordion-item-description .accordion-panel {
    border-top: 1px solid $cochlear-yellow-New;
    padding: 24px;
    padding-inline-start: 48px;

    a {
      color: $cochlear-purple;

      &:hover {
        color: $clr-magenta-blue;
      }
    }

    a.cta,
    a.cta-button {
      font-family: $font-circular-medium;

      &:has(+ a) {
        margin-bottom: 8px;

        @media only screen and (min-width: 768px) {
          margin-inline-end: 16px;
        }
      }
    }

    a.cta {
      /* clear framework styles */
      border: none;
      padding: 0;
      text-align: unset;
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        background-color: inherit;
      }
      /* end framework styles */

      /* external link icon */
      &::after {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE7SURBVHgB7VTdTcMwEP7OpO+R2ryHDRgAlKzQDdwRukBxYQE2oEwAG2CUBboBeU8r5R3w4YQW0TZOHCokHvpJfjnf3Xc/nw38MaiP8+VIpUSIDy4Mymytno4iSEMVcyBeXfeGMcnWs8W+XcATulQ5sRkTmcn22PLyrrjgu7qBeHZ6MZYvq9lY/xhDEt3cW3tsT46mse10MEBaOxuEzW60Y98kl0T8QMLM0dXBFgaYZsXhHF3JdXEt00jJNn/vHTQl94nxJnAm569Fn8GUvyZoq1yvlKZ3c64d76CTwJU8Gd4+JqN5rbxKwq74oC15OlSSXTMXfOHzTts7+IAmg6nvQpvQ2sGm9TscgV4y/ZcEwR5bchUpeKH6VgTKXgRWFFKwkPBBJSDmvMutJtCFWlhJlraiEH3whiVOOOETBr2KM5/mSf8AAAAASUVORK5CYII=");
        content: "";
        display: inline-block;
        height: 24px;
        margin-inline-start: 4px;
        vertical-align: bottom;
        width: 24px;

        [dir="rtl"] & {
          transform: scaleX(-1);
        }
      }
    }

    a.cta-button {
      background-color: $brand-white;
      border: 1px solid $cochlear-purple;
      border-radius: 14px;
      font-family: $font-circular-medium;
      padding-block: 10px;
      padding-inline: 24px 56px;

      &:hover {
        border: 1px solid $clr-magenta-blue;
        background: transparent;
      }

      &::after {
        background-image: sax(Chevron, $clr-magenta-blue);
      }
    }

    ul {
      padding-inline-start: 0;
      padding-left: 0; /* override framework */

      li {
        display: inline-flex;
        list-style: none;
        padding-inline-start: 16px;
        position: relative;
        width: 100%;

        @media only screen and (min-width: 768px) {
          &:not(:only-child) {
            width: 48%;
          }
        }

        &::before {
          content: "\2022";
          display: inline-block;
          padding-inline-end: 27px; /* includes width of bullet */
          width: 11px;
        }

        sup {
          top: unset; /* override for display: inline-flex */
        }
      }
    }
  }
}
