.cochlear-app-container {
  .resize-sensor-react,
  .resize-sensor-react > div,
  .resize-sensor-react .resize-sensor-react__contract-child {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }

  .resize-sensor-react {
    background: #eee;
    overflow: auto;
    direction: ltr; /* otherwise not working when direction: rtl - bug in chrome */
  }

  .resize-sensor-react .resize-sensor-react__contract-child {
    width: 200%;
    height: 200%;
  }

  @keyframes resize-sensor-react-animation {
    from {
      opacity: 0;
    }

    to {
      opacity: 0;
    }
  }

  // This is needed to trigger resizing once the element becomes visible
  .resize-sensor-react {
    animation-name: resize-sensor-react-animation;
    animation-duration: 1ms;
  }
}
