#chat-app {
  z-index: 10000;
  position: fixed;
  bottom: 100px;
  right: 0px;
  .close-chat {
    background: #d2d2d2;
    width: 20px;
    height: 20px;
    border: #fff 1px solid;
    border-radius: 10px;
    text-align: center;
    padding: 0;
    float: left;
    margin: 0px 20px 0 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:hover {
      background: #56565a;
    }
    img {
      width: 8px;
      display: block;
      margin: 0 auto;
    }
  }
  #tool-tip {
    float: right;
    .bubble {
      background: white;
      padding: 9px;
      border-radius: 8px;
      width: 330px;
      margin-right: 71px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      p {
        margin: 0;
        padding: 10px 0;
        text-align: center;
      }
      #chat-choice {
        display: flex;
        justify-content: space-around;
        button {
          border: 0;
          background: none;
          &:hover,
          &:focus {
            text-decoration: underline;
            color: $link-colour;
          }
        }
      }
    }
    .bubble-arrow img {
      float: right;
      margin: -2px 100px 0 0;
    }
  }
  .chat-click {
    float: right;
    clear: both;
    width: 190px;
    // height: 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    border: 0px none;
    border-radius: 10px 0px 0px 10px;
    background-color: #fdc82f;
    //color: white;
    overflow: hidden;
    cursor: pointer;
    padding: 10px;
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    &:hover,
    &:active {
      width: 205px;
      box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.5);
    }
    &:active {
      box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    }
    .chat-icon {
      img {
        width: 40px;
        display: block;
      }
    }
    .chat-text {
      color: #656565;
      h2 {
        font: normal 17px/18px Trebuchet MS;
        letter-spacing: -0.4px;
        margin: 0;
        padding: 0 5px;
      }
    }
  }
}

// #chat-app.message-live:hover .close-chat {
//   display: block;
// }

#chat-data {
  display: none;
}
.offline-area {
  background: white;
  padding: 9px;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
  height: 614px;
  height: 64vh;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  > div {
    height: 100%;
    iframe {
      height: 100%;
    }
  }
}
