.modal-global {
  width: calc(100vw - 40px);
  margin: 50vh 50vw auto;
  position: fixed;
  top: calc(-50vh + 20px);
  left: calc(-50vw + 20px);
  z-index: 4;
  background-color: $brand-white;
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: auto;

  @media only screen and (min-width: 64em) {
    top: 140px;
    width: 800px;
    padding: 60px;
    margin: 0 auto 0 -400px;
    top: 240px;
    left: 50%;
  }

  h2 {
    margin-top: 0;
    border-bottom: 5px solid $cochlear-yellow;
    padding-bottom: 10px;
  }

  .btn-row {
    margin-bottom: -20px;
  }

  .cta-button {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .close-btn {
    background-image: sax(Close, $cool-grey);
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
    opacity: 0.5;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: color, background-color, border-color;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    border: 0;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &.dark {
    // margin: auto;
    // left: 0;
    // right: 0;
    background-color: black;
    color: white;
    padding: 20px 30px;
    // width: 590px;

    p,
    li {
      color: white;
      font-size: 10pt;
      line-height: 14pt;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      background: none;

      &:after {
        content: '\d7';
        font-size: 20px;
        color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 20px;
      }
    }

    .btn-row {
      text-align: center;
    }
    .cta-button {
      font-size: 10pt;

      &.primary {
        font-style: italic;
        background: none;
        color: $brand-white;
        text-decoration: underline;
        border: 0;
      }

      &.secondary {
        background-color: $cochlear-purple;
        color: $brand-white;

        &::after {
          @include transition(0.2s);
          background-image: sax(Chevron, $brand-white);
        }

        &:hover {
          background-color: $brand-white;
          color: $cochlear-purple;

          &::after {
            @include transition(0.2s);
            background-image: sax(Chevron, $cochlear-purple);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 64em) {
  .global-header {
    .offscreen-background {
      &.popup-disclaimer {
        z-index: 4;
      }
    }
  }
}
