table.bordered {
  border: 1px solid $mid-grey;

  thead {
    th,
    td {
      background-color: $cool-grey;
      color: white;
      border-color: $mid-grey;
    }
  }

  tbody {
    td {
      border-color: $mid-grey;
    }
  }
}
