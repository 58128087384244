.related-block {
  background-color: $light-grey;
  padding: 60px 0 50px 0;
  margin-top: 90px;

  .content-card {
    @media only screen and (max-width: 64em) {
      .img-container {
        width: 30%;
        height: 120px;
        float: left;

        img {
          height: 100%;
          width: auto;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      .content-container {
        .content-container__grouper {
          float: right;
          width: 70%;
          position: relative;
          height: 120px;

          h2 {
            font-size: 18px;
            line-height: initial;
            padding: 15px 20px 0px 20px;
            border: initial;
          }

          p {
            display: none;
          }

          .cta-button {
            width: 100%;
            padding: 15px 20px;
            border: initial;
            font-size: 16px;

            &::after {
              top: 20px;
              margin-top: 0;
              right: auto;
              margin-left: 10px;
            }
          }
        }

        &.no-image {
          .content__grouper {
            float: left;
            width: 100%;
          }
        }
      }
    }

    @media screen and (min-width: 64em) {
      h2 {
        font-size: 28px;
        line-height: 32px;
      }

      .img-container {
        img {
          margin: 0;
          transform: translateX(-50%);
          height: auto;
          width: 100%;
          top: 0;
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .related-block {
    .content-card {
      @media only screen and (max-width: 64em) {
        .img-container {
          float: right;

          img {
            right: 50%;
            left: auto; /*replaced*/
            transform: translateX(50%) translateY(-50%);
          }
        }

        .content-container {
          .content-container__grouper {
            float: left;

            .cta-button {
              border: initial;
            }
          }
        }
      }
    }
  }
}
