.global-footer {
  background-color: #51515a;
  color: $brand-white;
  padding: 0;
  font-size: 14px;

  h2 {
    font-size: 22px;
    margin: 0;
  }

  a {
    font-size: 18px;
    color: $brand-white;
    text-decoration: none;
  }

  p {
    font-size: 16px;
  }

  .l-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &.is-hidden {
    @include mobile-menu-background-visibility;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .brandImg img {
    display: block;
    width: 120px;
    height: 105px;
    margin: 0 auto;
  }

  .nav-footer.has-5-items {
    display: grid;
    gap: 20px;
    grid-template: auto / repeat(2, 1fr);

    > li {
      margin: 0;
    }

    @media only screen and (max-width: 1023px) {
      > :not(.columns2) {
        grid-column: 1 / span 2;
      }
    }

    @media only screen and (min-width: 64em) {
      gap: 36px;
      grid-template: auto / repeat(5, 1fr);
    }
  }

  .nav-footer-social {
    text-align: center;
    width: 100%;

    @media only screen and (min-width: 64em) {
      width: max-content;
    }

    .socials-items {
      display: flex;
      gap: 16px;
      justify-content: center;
    }

    .icon {
      display: block;
      height: 30px;
      width: 30px;

      &.insta {
        width: 23px;
        margin: 5px 0;
        background-position: 0 5px;

        @media only screen and (min-width: 64em) {
          margin: 0;
          background-position: 0 3px;
        }
      }
    }
  }
}

/* subfooter styles are also used by CompatibilityFooter inside .global-footer-2
should localise to .global-footer once CompatibilityFooter is deprecated */
.subfooter-container {
  margin-bottom: 60px;
  display: flex;
  flex-flow: row wrap;
  background-color: #000;

  @media only screen and (min-width: 64em) {
    margin-bottom: 0;
  }

  a {
    font-size: 14px;
  }
}

.subfooter-link {
  justify-content: space-around;
  padding: 20px;
  margin: 0 auto;

  @media only screen and (min-width: 64em) {
    padding: 30px;
  }
}

.subfooter-copyright {
  flex: auto;
  text-align: center;

  @media only screen and (min-width: 64em) {
    text-align: end;
  }

  p {
    margin: 0;
    justify-content: center;
    color: $brand-white;
    padding: 20px;
    font-size: 14px;

    @media only screen and (min-width: 64em) {
      padding: 30px;
    }
  }
}

div#root > .Campaign {
  .global-footer {
    background-color: $font-darkblue;
  }
  .brandImg {
    margin-bottom: 10px;
    img {
      margin: 0 auto;
    }
    @media only screen and (min-width: 64em) {
      margin-bottom: 0;
      padding: 0 18px 0 0;
      img {
        margin: 0;
      }
    }
  }
  .nav-footer {
    ul,
    li,
    a {
      font-family: $font-circular-regular;
      line-height: 22px;
    }
    .nav-footer-social {
      @media only screen and (min-width: 64em) {
        .socials-items {
          &.mtf-items {
            width: 165px;
            li {
              &:nth-last-child(2) {
                margin-left: 22px;
              }
            }
          }
        }
      }
    }
  }
  .nav-footer-item {
    &.column,
    &.columns2 {
      ul {
        li {
          margin-top: 18px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    @media only screen and (max-width: 64em) {
      padding-left: 20px;
      padding-right: 0;
      &.column,
      &.columns2 {
        margin-bottom: 30px;
      }
    }
  }
  .subfooter-container {
    .subfooter-copyright {
      p {
        font-family: $font-circular-regular;
      }
    }
    @media only screen and (min-width: 48em) {
      .subfooter-copyright {
        width: 768px;
        flex: none;
        margin: 0 auto;
      }
    }
    @media only screen and (min-width: 64em) {
      .subfooter-copyright {
        width: 1024px;
        flex: none;
        margin: 0 auto;
        p {
          padding: 30px 36px;
        }
      }
    }
    @media only screen and (min-width: 77.75em) {
      .subfooter-copyright {
        width: 1180px;
        flex: none;
        margin: 0 auto;
      }
    }
  }
}

div#root > div[dir="rtl"] {
  .nav-footer {
    .nav-footer-social {
      @media only screen and (min-width: 64em) {
        .socials-items {
          &.mtf-items {
            li {
              &:nth-last-child(2) {
                margin-left: 0;
                margin-right: 22px;
              }
            }
          }
        }
      }
      .icon {
        text-indent: 0;
      }

      ul {
        margin-left: 0;
        margin-right: -10px;
        @media only screen and (min-width: 64em) {
          li {
            float: right;
          }
        }
      }
    }
  }
  .nav-footer-item {
    float: right;
    padding-left: 0;
    padding-right: 20px;
  }

  @media only screen and (min-width: 64em) {
    .nav-footer {
      & > li {
        float: right;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .subfooter-copyright {
    text-align: left;
  }
  &.Campaign {
    .brandImg {
      @media only screen and (min-width: 64em) {
        padding: 0 0 0 18px;
      }
    }
    .nav-footer-item {
      @media only screen and (max-width: 64em) {
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }
}
