.LeadMagnet {
  width: 100%;
  padding: 40px 0;
  background-color: $lead-magnet;
  background-image: none;
  @media screen and (max-width: 64em) {
    .l-padding {
      padding: 0;
    }
  }
  .lead-media-title {
    margin: 0 20px;
    h2 {
      font-size: 28px;
      font-family: $font-circular-medium;
      color: $font-darkblue;
      margin: 0 0 35px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 40px;
    }
    &.mb-show {
      display: block;
    }
  }
  .lead-media-description {
    margin: 0 20px;
    .content-container__grouper {
      .content {
        color: $font-darkblue;
        font-family: $font-circular-regular;
        margin-bottom: 0;
        font-size: 18px;
        margin-top: 25px;
        letter-spacing: -0.03px;
        font-weight: normal;
        line-height: 28px;
        p,
        li {
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          letter-spacing: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: inherit;
          font-family: inherit;
        }
      }
    }
    &.lead-left {
      display: none;
    }
    &.lead-right {
      display: block;
      .lead-media-title {
        display: none;
      }
    }
  }
  .lead-media-container {
    .lead-media-banner {
      position: relative;
      background: rgba(0, 0, 0, 0);
      .l-padding {
        width: 100%;
        .image {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .schematik-form {
    margin: -10px 0 0;
    background: transparent;
    .form-box {
      background: transparent;
      margin: 0;
      padding: 0;
      .underlined {
        display: none;
      }
      .form-input {
        label {
          font-family: $font-circular-regular;
          font-weight: normal;
          font-size: 18px;
          letter-spacing: -0.03px;
          line-height: 28px;
        }
        input {
          margin-top: 5px;
        }
      }
      &.render-buttons {
        .details-box {
          button {
            width: 100%;
            margin: 20px 0 0;
            font-weight: 400;
            display: inline-block;
            text-align: center;
            background: $cochlear-yellow-card;
            color: $cochlear-purple;
            border: 2px solid $cochlear-yellow-card;
            box-sizing: border-box;
            text-decoration: none;
            border-radius: 40px;
            position: relative;
            line-height: initial;
            font-size: 16px;
            font-family: $font-circular-regular;
            padding: 15px 50px 15px 35px;
            transition-duration: 150ms;
            transition-property: color, background-color, border-color;
            &::after {
              right: 20px;
              left: inherit;
              position: absolute;
              top: 25px;
              background-image: sax(Chevron, $cochlear-purple);
              content: ' ';
              width: 20px;
              height: 20px;
              margin-top: -10px;
              background-size: 100%;
              transform: rotate(-90deg);
              transition-duration: 150ms;
              transition-property: left, right, padding, border;
            }
            &:hover {
              background: $cochlear-yellow-New;
              border: 2px solid $cochlear-yellow-New;
              box-sizing: border-box;
              &::after {
                right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .form-box {
    p,
    h2,
    .form-box-title,
    .form-box-description {
      color: $font-darkblue;
    }
    &.form-box-success {
      background: transparent;
      width: 100%;
      @media screen and (max-width: 64em) {
        margin: 0;
        padding: 20px 0;
      }
      h2 {
        display: none;
      }
      .form-box-description {
        min-height: 70px;
        color: $font-darkblue;
        font-size: 18px;
        line-height: 28px;
        padding-left: 90px;
        background-image: sax(Checkmark);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 70px;
        font-family: $font-circular-medium;
      }
    }
  }

  @media screen and (min-width: 64em) {
    padding: 120px 0;
    position: relative;
    .backImage {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      position: absolute;
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 815px 600px;
      &.is-secondary {
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
    .lead-media-title {
      margin: 0;
      h2 {
        font-size: 34px;
        font-family: $font-circular-medium;
        font-weight: 500;
        color: $font-darkblue;
        margin: 0 0 35px;
      }
      &.mb-show {
        display: none;
      }
    }
    .lead-media-container {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: 0;
      .lead-media-banner {
        height: inherit;
        .l-padding {
          padding: 0;
        }
      }
    }
    .lead-media-description {
      margin: 0;
      width: 50%;
      display: inline-block;
      .content-container__grouper {
        .content {
          font-size: 18px;
          margin-bottom: 0px;
          font-family: $font-circular-regular;
          color: $font-darkblue;
          margin-top: 0;
        }
      }
      &.lead-left {
        padding: 0 80px 0 0;
        display: none;
        &.is-active {
          display: inline-block;
        }
      }
      &.lead-right {
        padding: 0 0 0 80px;
        display: none;
        &.is-active {
          display: inline-block;
          .lead-media-title {
            display: block;
          }
        }
      }
    }
    .schematik-form {
      .form-box {
        &.render-buttons {
          .details-box {
            button {
              width: inherit;
            }
          }
        }
      }
    }
    .form-box-success {
      .form-box-description {
        padding-left: 68px;
        background-size: 48px;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .LeadMagnet {
    .backImage {
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      &.is-secondary {
        -moz-transform: none;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
      }
    }
    .schematik-form {
      .form-box {
        &.render-buttons {
          .details-box {
            position: inherit;
            right: inherit;
            button {
              width: 100%;
              &::after {
                left: 20px;
                right: inherit;
                transform: rotate(90deg);
              }
              &:hover {
                &::after {
                  left: 10px;
                }
              }
            }
          }
        }
      }
    }
    .form-box-success {
      .form-box-description {
        padding-left: 0;
        padding-right: 90px;
        background-position: right center;
      }
    }
    @media only screen and (min-width: 64em) {
      .schematik-form {
        .form-box {
          &.render-buttons {
            .details-box {
              position: absolute;
              button {
                padding: 15px 35px 15px 50px;
              }
            }
          }
        }
      }
      .form-box-success {
        .form-box-description {
          padding-left: 0;
          padding-right: 68px;
          background-size: 48px;
        }
      }
      .lead-media-description {
        &.lead-left {
          padding: 0 0 0 80px;
        }
        &.lead-right {
          padding: 0 80px 0 0;
        }
      }
    }
  }
}
