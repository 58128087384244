.key-points {
  border-top: 5px solid $cochlear-yellow;
  border-bottom: 2px solid $mid-grey;
  margin: 0 0 40px 0;
  padding: 20px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 22px;
    line-height: 28px;
  }

  li,
  p {
    a {
      color: $link-colour;
    }
  }

  @media screen and (min-width: 64em) {
    li {
      padding-left: 25px;
    }
  }
}

.product-page,
.Campaign {
  .key-points {
    border-bottom: 0;

    h2,
    h3 {
      font-size: 32px;
      line-height: 38px;
    }
  }
}
