.landing-banner {
  margin-bottom: 20px;

  @media only screen and (min-width: 64em) {
    margin-bottom: 0;
  }

  .l-padding {
    padding: 0;
  }

  .background {
    height: 100%;
    background-color: $light-grey;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

    @media only screen and (min-width: 64em) {
      width: 48%;
      border-radius: 0 0 40px 0;
    }
  }

  .content-link {
    color: $link-colour;
    text-decoration: none;
    font-family: $font-primary-bold;
    font-size: 18px;
    font-weight: 700;
    background-color: transparent;
    border: 0;

    &::after {
      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      background-image: sax(Chevron, $cochlear-purple);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(-90deg);
      margin-left: 5px;
      margin-top: 6px;
    }
  }

  .content {
    padding: 0;

    h1 {
      margin-top: 0;
    }

    @media only screen and (min-width: 64em) {
      width: 49.4%;
      max-width: none;
      padding: 0 0 40px 0;
      min-height: 400px;
    }

    .content-grouper {
      display: block;
      margin: 0 8px;
      padding: 28px 20px 20px 20px;
      position: relative;

      @media only screen and (min-width: 64em) {
        padding: 40px 0 0 36px;
        margin: 0 150px 0 36px;
      }

      .header-highlight {
        height: 100%;
        width: 5px;
        background-color: $cochlear-yellow;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
      }

      p {
        padding-bottom: 15px;

        @media only screen and (min-width: 64em) {
          padding-bottom: 40px;
        }
      }

      .arrow {
        background-image: sax(Arrow, $cool-grey);
        background-size: 20px;
        width: 20px;
        height: 20px;
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }

  .image {
    position: relative;
    z-index: 2;
    width: calc(100% - 20px);

    @media only screen and (min-width: 64em) {
      width: 56%;
      max-width: none;
      left: 44%;
      margin-top: 0;
      position: absolute;
      height: 90%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  &.no-image {
    .background {
      border-radius: 0 0 40px 0;
    }

    .image {
      display: none;
    }

    .content {
      padding: 0 0 70px 0;
      min-height: 0;

      @media only screen and (min-width: 64em) {
        padding: 0 0 50px 0;
        width: 100%;
        background-color: $light-grey;
        border-radius: 0 0 40px 0;
      }

      .content-grouper {
        @media only screen and (min-width: 64em) {
          margin-right: 0;
          width: 55%;
        }

        .arrow {
          display: none;
        }
      }
    }
  }

  &.full-width {
    margin-bottom: 0;

    .background {
      background-color: white;

      @media only screen and (min-width: 64em) {
        display: none;
      }
    }

    .content {
      padding: 0 0 38px 0;

      @media only screen and (min-width: 64em) {
        width: 42.3%;
        border-radius: 0 0 40px 0;
        margin-bottom: 120px;
        background-color: rgba(255, 255, 255, 0.8);

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.8);
          top: 0;
          left: -100%;
        }
      }

      .content-grouper {
        margin: 0;
        padding: 30px 30px 0 30px;

        @media only screen and (min-width: 64em) {
          padding: 40px 0 0 36px;
          margin: 0 80px 0 36px;
        }

        p {
          padding-bottom: 40px;
          font-size: 18px;

          @media only screen and (min-width: 64em) {
            font-size: 24px;
          }
        }

        .header-highlight {
          width: 10px;
        }

        .arrow {
          transform: rotate(90deg);
          width: 20px;
        }

        .title-image {
          width: 100%;
          height: auto;
        }
      }
    }

    .image {
      width: 100%;
      margin-bottom: -10px;

      @media only screen and (min-width: 64em) {
        max-width: none;
        left: 0;
        z-index: 1;
        margin-top: 0;
        position: absolute;
        height: 100%;
        overflow: hidden;
      }

      img {
        @media only screen and (min-width: 77.75em) {
          margin-top: -250px;
        }
      }
    }
  }
}

div#root > div[dir='rtl'] {
  .landing-banner {
    &.full-width {
      .background {
        border-radius: 0;
      }
      .content {
        .content-grouper {
          margin: 0;
          padding: 30px 30px 0 30px;
        }
      }
    }
    .background {
      left: inherit;
      right: 0;
      border-radius: 0 0 0 40px;
    }
    .content {
      border-radius: 0 0 0 40px;
      .content-grouper {
        padding: 28px 20px 20px 20px;
        margin: 0 8px;

        .header-highlight {
          left: inherit;
          right: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 64em) {
    .landing-banner {
      .content {
        .content-grouper {
          padding: 40px 36px 0px 0;
          margin: 0 36px 0 150px;
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
    .landing-banner {
      &.full-width {
        .content:before {
          left: 100%;
        }
      }

      &:not(.full-width) {
        .image {
          left: auto;
          right: 44%;
        }
      }
    }
  }
  .landing-banner {
    .content-link {
      &::after {
        content: '';
        transform: rotate(90deg);
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}
