/* Style the buttons that are used to open and close the accordion panel */

.accordion.questionnaire {
  h2 {
    font-weight: 500;
    color: $cochlear-black;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 1.1;
  }

  .qa-accordion-item {
    color: $cochlear-black;
    margin-top: 2px;
    box-shadow: none;
    border-bottom: 1px solid $mid-grey;
  }

  .qa-accordion-item-0 {
    border-top: 1px solid $mid-grey;
  }

  .qa-accordion-panel-heading {
    background-color: $brand-white;
    border-color: $mid-grey;
    border-radius: 0;
    padding: 0;
    padding-right: 20px;
    position: relative;
    font-size: 14px;
    line-height: 20px;

    h4 {
      font-weight: normal;
      font-size: 20px;
      line-height: 1.1;
      padding-bottom: 0px;
      margin-top: 15px;
      margin-bottom: 15px;
      a {
        font-size: 20px;
        padding: 10px 15px;
        padding-left: 30px;
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        position: relative;

        &::before {
          content: 'Q';
          background: $cochlear-yellow;
          border-radius: 50%;
          left: 0;
          top: 6px;
          color: $brand-white;
          width: 25px;
          height: 25px;
          display: block;
          text-align: center;
          font-weight: bold;
          line-height: 25px;
          position: absolute;
          font-size: 14px;
        }

        &::after {
          content: '\f106';
          font-family: 'FontAwesome';
          font-weight: bold;
          font-size: 30px;
          background: transparent;
          color: $cochlear-yellow;
          position: absolute;
          top: 50%;
          right: -10px;
          width: 25px;
          text-align: center;
          transform: translateY(-50%);
        }

        &.collapsed {
          &::after {
            content: '\f107';
            font-family: 'FontAwesome';
            font-size: 30px;
            background: transparent;
            color: $cochlear-yellow;
            top: 50%;
            right: -10px;
            width: 25px;
            text-align: center;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .collapse {
    display: none;

    &.in {
      display: block;
    }
  }

  .qa-accordion-panel {
    border-top: 0;
    padding-left: 30px;
    padding-top: 0;
    position: relative;
    padding-bottom: 10px;

    &::before {
      content: 'A';
      background: $cochlear-yellow;
      border-radius: 50%;
      left: 0;
      color: $brand-white;
      width: 25px;
      height: 25px;
      display: block;
      text-align: center;
      font-weight: bold;
      line-height: 25px;
      position: absolute;
      font-size: 14px;
    }
  }
}

//rtl-styles
div#root > div[dir='rtl'] {
  .accordion.questionnaire {
    &.full-width-border {
      margin-left: 0;
      margin-right: calc(50% - 50vw);
    }

    //add rtl styles
    .qa-accordion-panel-heading {
      h4 {
        a {
          &::before {
            content: 'Q';
            right: -20px;
            left: auto;
          }

          &::after {
            left: 0px;
            right: auto;
          }

          &.collapsed {
            &::after {
              left: 0;
            }
          }
        }
      }
    }

    .qa-accordion-panel-title {
      text-align: right;

      &::after {
        right: inherit;
        left: 0;
      }
    }
    .qa-accordion-panel {
      text-align: right;
      padding-right: 30px;

      &::before {
        right: 0;
      }
    }
  }
}
