.full-width-video {
  .product-media-title {
    h2 {
      font-size: 28px;
      font-family: $font-circular-medium;
      color: $font-darkblue;
      margin: 0 0 40px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 40px;
      @media only screen and (min-width: 64em) {
        font-size: 34px;
        margin: 45px 0 40px;
      }
    }
  }
  .product-media-banner {
    width: 100%;
    background-color: $cochlear-yellow;
    margin-bottom: 35px;

    @media only screen and (min-width: 64em) {
      height: 480px;
      overflow: hidden;
      margin-bottom: 0;
    }

    .video-modal-cta {
      @include video-play-icon;
      border: 0;
      padding: 0;
      margin: 0;
      display: block;
      &::after {
        left: calc(50% + 5px);
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 25px solid #fff;
      }

      @media only screen and (min-width: 64em) {
        height: 480px;
      }
    }

    .l-padding {
      padding: 0;
    }

    .image-banner,
    .video-banner {
      height: 100%;
      overflow: hidden;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center;
      }

      @media only screen and (min-width: 64em) {
        height: auto;
        overflow: hidden;
        margin-top: -100px;
      }

      img {
        width: 100%;
        height: auto;
        display: inherit;
      }
    }

    .modal-link {
      display: block;
    }
  }
  .product-media-description {
    margin: 40px 0 25px;
    .content-container__grouper {
      .content {
        color: $font-darkblue;
        font-family: $font-circular-regular;
        margin-bottom: 20px;
        font-size: 18px;
        letter-spacing: -0.03px;
        font-weight: normal;
        line-height: 28px;
        p,
        li {
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          letter-spacing: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: inherit;
          font-family: inherit;
        }
      }
      .cta-grouping {
        .cta-button {
          display: block;
          padding-left: 40px;
          width: auto;
          font-weight: 400;
          text-align: center;
          margin-bottom: 20px;
          font-size: 16px;
          font-family: $font-circular-regular;
          padding: 15px 50px 15px 35px;
          &.video-tertiary {
            background: $cochlear-yellow-card;
            color: $link-colour;
            border: 2px solid $cochlear-yellow-card;
            box-sizing: border-box;
            &::after {
              right: 20px;
              left: inherit;
              position: absolute;
              top: 25px;
              background-image: sax(Chevron, $cochlear-purple);
            }
            &:hover {
              background: $cochlear-yellow-New;
              border: 2px solid $cochlear-yellow-New;
              box-sizing: border-box;
              &::after {
                right: 10px;
              }
            }
          }
          &.secondary {
            background: transparent;
            border: 1px solid $link-colour;
            box-sizing: border-box;
            margin-right: 0;
            &::after {
              margin-left: 20px;
              font-weight: 400;
              background-image: sax(Chevron, $cochlear-purple);
              right: 20px;
              transition-duration: 0ms;
            }
            &:hover {
              background: transparent;
              color: $link-colour;
              border: 2px solid $link-colour;
              box-sizing: border-box;
              &::after {
                right: 10px;
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 64em) {
      .content-container__grouper {
        display: flex;
        .content {
          font-size: 18px;
          color: $font-darkblue;
        }
        .cta-grouping {
          min-width: 480px;
          display: inline-block;
          text-align: right;
          .cta-button {
            margin: 0 20px 20px 0;
            padding: 15px 50px 15px 35px;
            font-weight: 400;
            display: inline-block;
            text-align: left;
            &.video-tertiary {
              color: $link-colour;
              box-sizing: border-box;
              &::after {
                right: 20px;
                left: inherit;
                position: absolute;
                top: 25px;
              }
              &:hover {
                box-sizing: border-box;
                &::after {
                  right: 10px;
                }
              }
            }
            &.secondary {
              margin: 0 1px 10px 1px;
              &::after {
                margin-left: 20px;
                font-weight: 400;
                background-image: sax(Chevron, $cochlear-purple);
                right: 20px;
              }
              &:hover {
                margin: 0 0 10px 0;
                background: #ffffff;
                color: $link-colour;
                border: 2px solid $link-colour;
                box-sizing: border-box;
                &::after {
                  right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .full-width-video {
    .product-media-description {
      .content-container__grouper {
        .cta-grouping {
          text-align: left;
          .cta-button {
            margin: 0 0 20px 0;
            &.video-tertiary {
              &::after {
                left: 20px;
              }
              &:hover {
                &::after {
                  left: 10px;
                }
              }
            }
            &.secondary {
              &::after {
                margin-right: 20px;
                margin-left: 0;
              }
              &:hover {
                &::after {
                  left: 10px;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (min-width: 64em) {
      .product-media-description {
        .content-container__grouper {
          .cta-grouping {
            text-align: left;
            .cta-button {
              margin: 0 0 20px 20px;
              &.video-tertiary {
                &::after {
                  left: 20px;
                }
                &:hover {
                  &::after {
                    left: 15px;
                  }
                }
              }
              &.secondary {
                margin: 0 calc(2% + 1px) 10px 1px;
                &::after {
                  margin-right: 20px;
                  margin-left: 0;
                }
                &:hover {
                  margin: 0 2% 10px 0;
                  &::after {
                    left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.l-padding {
  @media only screen and (max-width: 64em) {
    &.is-full-width-video {
      padding: 0;
      .full-width-video {
        .product-media-title {
          padding: 0 25px;
        }
        .product-media-description {
          padding: 0 20px;
          .content-container__grouper {
            .content {
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
