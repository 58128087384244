/* Style the buttons that are used to open and close the accordion panel */

.stacked-accordions {
  padding: 0;
  margin: 0;

  .accordion-section-heading {
    border-bottom: 5px solid $cochlear-yellow;
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  .accordion-header-link {
    text-decoration: none;
  }

  .accordion-item {
    padding-right: 0;
    margin-right: 0;

    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:not(:first-of-type) .accordion-item-title {
      border-top: 1px solid $mid-grey;
    }
  }

  .accordion-item-title {
    cursor: pointer;
  }

  .accordion-trigger {
    background-color: $brand-white;
    color: $cool-grey;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    text-align: left;
    border: 0;
    outline: none;
    display: block;
    font-family: $font-header;
    margin: 0;
    position: relative;
    font-weight: 900;

    &:hover {
      text-decoration: underline;
      color: $cochlear-purple;
      background-color: $light-grey;
    }

    &::after {
      background: sax(Chevron, $cochlear-purple);
      white-space: pre;
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      top: 40%;
      right: 10px;
      background-size: 100%;

      @media only screen and (min-width: 48em) {
        right: 20px;
      }
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active,
  .accordion:hover {
    background-color: $light-grey;
    color: $cochlear-purple;
  }

  .accordion:focus {
    outline: auto 5px -webkit-focus-ring-color;
  }

  .expanded {
    display: block;
  }

  .notExpanded {
    display: none;
  }

  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: $brand-white;
    display: none;
  }

  .accordion-panel {
    text-decoration: none;
    margin: 20px 20px 0 20px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    p > a,
    a {
      color: $cochlear-purple;
    }
  }

  &.full-width-border {
    border-top: 1px solid $mid-grey;
    border-bottom: 1px solid $mid-grey;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);

    &.no-border {
      border: none;
    }
  }
}

//rtl-styles
div#root > div[dir="rtl"] {
  .stacked-accordions {
    &.full-width-border {
      margin-left: 0;
      margin-right: calc(50% - 50vw);
    }
    .accordion-trigger {
      text-align: right;

      &::after {
        right: inherit;
        left: 0;
      }
    }
    tr {
      td {
        border-left: none;
      }
    }
  }
}
