.stakes {
  width: 100%;
  padding: 40px 0;
  .stakes-info {
    padding: 0 20px;
    h2 {
      font-family: $font-circular-medium;
      color: $font-darkblue;
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 40px;
    }
    p {
      color: $font-darkblue;
      font-family: $font-circular-regular;
      font-size: 18px;
      text-align: left;
      margin-bottom: 10px;
      letter-spacing: -0.03px;
      line-height: 28px;
      font-weight: normal;
    }
  }
  .stakes-cards {
    padding: 0 20px;
    .content-container__grouper {
      .sl {
        width: 100%;
        .sl-list {
          .sl-item {
            padding: 0 10px;
            margin-top: 20px;
          }
        }
      }
      .card {
        background: $bg-dark-grey-stakes;
        padding: 10px 20px 25px 20px;
        .card-info {
          margin-bottom: 20px;
          h3 {
            color: $font-darkblue;
            font-family: $font-circular-medium;
            font-size: 20px;
            margin: 10px 0;
            font-weight: 500;
            letter-spacing: -0.03px;
            line-height: 34px;
          }
          .content,
          p {
            font-size: 18px;
            color: $font-darkblue;
            font-weight: normal;
            letter-spacing: -0.03px;
            line-height: 28px;
            font-family: $font-circular-regular;
          }
        }
        .card-list {
          .lists {
            &.right-list {
              li {
                background-image: sax(Cross);
                background-position: left 6px;
              }
            }
            &.left-list {
              ul {
                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                color: $font-darkblue;
                list-style-type: none;
                background-image: sax(Checkmark);
                padding-left: 30px;
                background-repeat: no-repeat;
                background-position: left 8px;
                letter-spacing: -0.03px;
                font-weight: normal;
                line-height: 28px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 64em) {
    .l-padding {
      z-index: 9;
      padding: 0;
    }
    .stakes-cards {
      .content-container__grouper {
        .sl {
          margin: 0;
          .sl-list {
            .sl-item {
              padding: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 64em) {
    padding: 80px 0;
    .stakes-info {
      padding: 0;
      h2 {
        font-size: 34px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
      }
    }
    .stakes-cards {
      margin-bottom: 0;
      .content-container__grouper {
        .sl {
          display: table;
          margin-bottom: 0;
          .sl-list {
            .sl-item {
              padding: 0 10px;
              display: table-cell;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        .card {
          padding: 8% 10%;
          height: 100%;
          .card-info {
            h3 {
              text-align: left;
              font-size: 28px;
              margin: 15px 0 10px;
            }
            .content,
            p {
              font-size: 18px;
            }
          }
          .card-list {
            .lists {
              &.left-list {
                ul {
                  vertical-align: top;
                  &:first-child {
                    margin-bottom: 0;
                  }
                }
              }
              ul {
                display: inline-block;
                min-width: 49%;
              }
            }
          }
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .stakes {
    .stakes-info {
      p {
        text-align: right;
      }
    }
    .stakes-cards {
      .content-container__grouper {
        .card {
          .card-info {
            h3 {
              text-align: right;
            }
            .content {
              text-align: right;
            }
          }
          .card-list {
            .lists {
              ul {
                li {
                  background-position: right 8px;
                  padding-left: 0;
                  padding-right: 30px;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: 64em) {
      .stakes-cards {
        .content-container__grouper {
          .sl {
            .sl-list {
              .sl-item {
                padding: 0 10px;
                &:first-child {
                  padding-right: 0;
                }
                &:last-child {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
