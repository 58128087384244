.announcement {
  padding: 10px 20px;
  background-color: rgb(253, 200, 46);
  color: rgb(86, 86, 90);
  position: relative;
  z-index: 999;
  border-bottom: 5px solid #fdc82f;

  .l-padding {
    position: relative;

    .announcement-icon {
      display: block;
      float: left;
      padding: 0 15px;
      height: auto !important;
      width: auto !important;
    }
    .content {
      float: left;
      width: calc(100% - 60px);
      word-spacing: 0px !important;
    }
    .show-previous {
      float: right;
      width: calc(100% - 60px);
      word-spacing: 0px !important;
      cursor: pointer;
    }
  }
}
