.help-container {
  padding: 64px 0 0;
  font-family: $font-circular-regular;

  &-back-link {
    display: flex;
    font-family: $font-circular-regular;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: $cochlear-purple;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    &::before {
      background-image: sax(Chevron, $cochlear-purple);
      background-repeat: no-repeat;
      content: " ";
      flex: 0 0 auto;
      width: 20px;
      height: 20px;
      transform: rotate(90deg);
      margin-top: 2px;
      margin-inline-end: 4px;

      [dir="rtl"] & {
        transform: scaleX(-1) rotate(90deg);
      }
    }

    &:hover {
      color: $link-colour-hover;

      &::before {
        background-image: sax(Chevron, $link-colour-hover);
      }
    }

    &.external-link {
      padding-left: 0;
      padding-right: 30px;

      &::before {
        background-image: sax(Chevron, $cochlear-purple);
        content: " ";
        transform: rotate(-90deg);
        left: unset;
        right: 5px;
        top: 5px;
      }

      &:hover {
        color: $link-colour-hover;

        &::before {
          background-image: sax(Chevron, $link-colour-hover);
        }
      }
    }
  }

  &-title-text {
    margin-top: 8px;
    font-family: $font-circular-regular;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: $font-darkblue;
    max-width: 600px;
  }

  &-legends {
    background-color: $bg-footnotes-grey;
    border: 1px solid $border-color-grey92;
    border-radius: 8px;
    padding: 0 24px 16px;
    margin-top: 32px;

    &-item {
      padding-right: 24px;
      display: inline-block;
    }
  }

  p,
  .help-container-legends-item {
    color: $font-darkblue;
    font-size: 18px;
    line-height: 28px;
    font-family: $font-circular-regular;
    margin: 16px 0;

    &.inner-heading {
      font-size: 21px;
      line-height: 28px;
      font-weight: 600;
    }
  }

  &-content {
    margin-top: 40px;

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-top: 50px;
        font-family: $font-circular-regular;

        ul {
          padding-inline-start: 30px;
        }
      }
    }

    &-heading-text {
      font-size: 21px;
      line-height: 28px;
      color: $font-darkblue;
      border-bottom: 2px solid $cochlear-yellow-card;
      padding-bottom: 5px;
      font-family: $font-circular-regular;
    }

    &-cta-button {
      background: $cochlear-yellow-card;
      border-radius: 14px;
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 24px;
      text-decoration: none;
      color: $cochlear-purple;
      font-weight: 600;
      font-family: $font-circular-regular;
      cursor: pointer;

      &-white {
        background: $brand-white;
        border: 1px solid $cochlear-purple;
        border-radius: 14px;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 24px;
        text-decoration: none;
        color: $cochlear-purple;
        font-weight: 600;
        font-family: $font-circular-regular;
        cursor: pointer;
      }
    }

    &-list-items {
      margin-top: 8px !important;
      list-style-type: disc;
      color: $font-darkblue;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}
