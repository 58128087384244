.anchor-nav {
  background-color: $brand-white;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 3;
  border-bottom: 2px solid $border-color-grey92;
  overflow: hidden;

  &::after {
    clear: both;
  }

  &::before {
    clear: both;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: $brand-white;
  }

  li {
    padding: 16px 16px 16px 48px;
    list-style: none;
    display: block;
    margin: 0;
    border-top: 2px solid $border-color-grey92;
    position: relative;

    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $font-darkblue;
      text-decoration: none;
    }

    &:hover {
      background-color: $bg-floral-white;
    }

    &:focus {
      background-color: $honied-white;
    }

    &.selected {
      background-color: $bg-floral-white;

      &::before {
        content: '';
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADJSURBVHgBpdG/DgExHAfwXw+D7UZDhxswSy6SjrVdbN5BxOgNGD2CR7FhMxB2hg73DiK5U79fB+E42vombfon/eTXFuCPSDkJRTKUNGbgGUKu9ctKA3RueTYIwCPPCE5VENSOzhUVEdCst10ulBNUhtCeNfQNsYZ+IZSKSMYRb8UH3o7D9LTf+CAU+rXINM1moj+a+iCmovS8U7zZZXhJiTeVWBlQZS6IgahDbF3E8mo2t0Ue0CcMlxq2yAv0jtkjpcGflPRGLmfuDPmKteuDMX8AAAAASUVORK5CYII=');
        width: 20px;
        height: 15px;
        display: inline-block;
        background-size: 100%;
        top: 20px;
        position: absolute;
        left: 20px;
      }
    }
  }

  .get-started {
    float: right;
    margin-right: -200px;
    margin-top: 15px;
    padding: 10px 12px 10px 16px;
    font-size: 14px;
    font-weight: 500;
    font-family: $font-circular-medium;
    color: $cochlear-purple;
    border-radius: 12px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    background-color: $cochlear-yellow-card;
    border-color: $cochlear-yellow-card;

    &:hover {
      background-color: $bg-deep-honey-yellow;
      border-color: $bg-deep-honey-yellow;
    }

    &.show {
      margin-right: 10px; /* Bringing the button into view */
      opacity: 1;
    }
  }

  .menu {
    background-color: $brand-white;
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }

  .menu-icon {
    cursor: pointer;
    display: block;
    float: left;
    padding: 20px;
    position: relative;
    user-select: none;
    color: $cochlear-purple;
    background: none;
    border: none;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background-image: url('data:image/svg+xml,%3Csvg width=%2224px%22 height=%2224px%22 viewBox=%220 0 24 24%22 xmlns=%22http://www.w3.org/2000/svg%22 xmlns:xlink=%22http://www.w3.org/1999/xlink%22%3E%3Ctitle%3EIcon/Chevron%3C/title%3E%3Cg id=%22Symbols%22 stroke=%22none%22 stroke-width=%221%22 fill=%22none%22 fill-rule=%22evenodd%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22%3E%3Cg id=%22Icon/Chevron%22 stroke=%22white%22 stroke-width=%222%22%3E%3Cpolyline id=%22Stroke-6%22 points=%2217 10 12 15 7 10%22 stroke=%22%233F1482%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
      background-size: 100%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      top: 7px;
      position: relative;
      left: 5px;
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  .menu-btn {
    display: none;

    &.active {
      ~ {
        .menu {
          max-height: 500px;
        }
      }
    }
  }

  .mobile-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 1;
  }

  .z-index-2 {
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 47.99em) {
  .anchor-nav {
    li {
      display: inline-block;
      border-top: none;
      padding: 12px 0;
      margin: 0;

      a {
        font-weight: 450;
        font-size: 16px;
        line-height: 38px;
        color: $font-darkblue;
        text-decoration: none;
        padding: 12px 16px 14px;
      }

      &:hover {
        background-color: $bg-lighter-grey;
      }

      &:focus {
        background-color: $honied-white;
      }

      &.selected {
        border-bottom: 2px solid $link-colour;
        background-color: $brand-white;

        &::before {
          content: none;
        }
      }
    }

    .menu {
      background-color: $brand-white;
      clear: none;
      float: left;
      max-height: none;
    }

    .menu-icon {
      display: none;
    }

    .get-started {
      margin-top: 10px;
    }
  }
}
