.global-header-2 {
  border-top: 5px solid $cochlear-yellow;
  height: 96px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.16);
  position: relative;

  .l-padding {
    @media only screen and (max-width: calc(48em - 1px)) {
      padding: 0 16px;
    }
  }

  @media only screen and (min-width: 77.75em) {
    .l-container,
    .l-padding {
      max-width: 1366px;
      width: 100%;
      padding: 0 104px;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: calc(48em - 1px)) {
    height: 56px;
    display: block;
  }

  .logobox {
    float: left;

    @media only screen and (min-width: 47.99em) and (max-width: 63.99em) {
      display: flex;
      align-items: center;
      min-height: 96px;
    }

    @media only screen and (max-width: calc(48em - 1px)) {
      display: flex;
      align-items: center;
      height: 51px;
    }

    img {
      max-width: 210px;

      @media only screen and (max-width: calc(64em - 1px)) {
        max-width: 125px;
        height: auto;
      }
    }
  }

  .contact {
    margin: 0;
    float: right;

    @media only screen and (min-width: 47.99em) and (max-width: 63.99em) {
      display: flex;
      align-items: center;
      min-height: 96px;
    }
  }

  .languageBox {
    float: right;

    .langauge-dropdown {
      position: relative;
      padding: 0;
      width: auto;
      list-style: none;

      .dropdown-toggle {
        color: $cochlear-purple;
        padding: 11px 52px 11px 28px;
        line-height: 20px;
        border: 0;
        position: relative;
        background: transparent;

        @media only screen and (max-width: calc(48em - 1px)) {
          padding: 11px 32px 11px 28px;
        }

        .langauge-dropdown__cta {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
          font-family: $font-circular-medium;

          @media only screen and (max-width: calc(48em - 1px)) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        &::after {
          content: " ";
          right: 20px;
          position: absolute;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%233F1482' d='M17 10l-5 5-5-5' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          width: 24px;
          height: 24px;
          background-size: 100%;
          transition: all 0.2s ease-in-out;
          transform: rotate(0deg);
          border: none;
          margin-top: 2px;

          @media only screen and (max-width: calc(48em - 1px)) {
            right: 0;
          }
        }
      }

      .dropdown-menu {
        border: none;
        position: absolute;
        top: 100%;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        text-align: left;
        list-style: none;
        background-color: $brand-white;
        background-clip: padding-box;
        right: 0;
        left: auto;
        box-shadow: -5px 7px 10px rgba(0, 0, 0, 0.1);

        &.open {
          display: block;
        }

        @media only screen and (min-width: 74.93em) {
          right: -105px;
        }

        @media only screen and (min-width: 64em) {
          top: calc(100% + 20px);
          min-width: 327px;
        }

        @media only screen and (max-width: calc(64em - 1px)) {
          top: auto;
          z-index: 5;
          position: fixed;
          width: 100%;
          border: 0;
          min-height: 100vh;
          background-color: transparent;
          bottom: 0;
          border-top: 2px solid #fdc82f;
        }
        .dropdown-item {
          padding: 0;
          display: flex;
          clear: both;
          text-align: inherit;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid #efefef;
          position: relative;
          text-decoration: none;
          width: 100%;

          .dropdown-item-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            color: $font-darkblue;
            min-height: 60px;
            padding: 16px 16px 16px 45px;
            transition-duration: 0.15s;
            font-family: $font-circular-regular;
            position: relative;
            display: block;
            width: 100%;

            &:hover {
              background-color: $extralighter-grey;

              &::before {
                opacity: 1;
              }
            }

            &:not(:last-child) {
              border-bottom: 1px solid $cool-greylight;
            }

            &::before {
              content: " ";
              left: 16px;
              top: 50%;
              width: 16.76px;
              height: 12.57px;
              margin-top: -6px;
              position: absolute;
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADJSURBVHgBpdG/DgExHAfwXw+D7UZDhxswSy6SjrVdbN5BxOgNGD2CR7FhMxB2hg73DiK5U79fB+E42vombfon/eTXFuCPSDkJRTKUNGbgGUKu9ctKA3RueTYIwCPPCE5VENSOzhUVEdCst10ulBNUhtCeNfQNsYZ+IZSKSMYRb8UH3o7D9LTf+CAU+rXINM1moj+a+iCmovS8U7zZZXhJiTeVWBlQZS6IgahDbF3E8mo2t0Ue0CcMlxq2yAv0jtkjpcGflPRGLmfuDPmKteuDMX8AAAAASUVORK5CYII=");
              background-size: 100%;
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;
              opacity: 0;
            }

            &.selected {
              background-color: $extralighter-grey;

              &::before {
                opacity: 1;
              }
            }
          }

          .dropdownBox {
            width: 100%;
          }
        }

        .dropdown-items {
          background-color: $brand-white;
          box-shadow: 0 1px 16px rgba(0, 0, 0, 0.25);
          max-height: 270px;

          @media only screen and (max-width: calc(64em - 1px)) {
            min-height: 100vh;
            width: 100%;
            overflow: scroll;
          }

          .dropdown-item {
            &.close {
              border-top: 5px solid $cochlear-yellow;
              text-align: center;
              height: 56px;

              @media only screen and (min-width: 64em) {
                display: none;
              }

              .dropdown-item-title {
                font-size: 18px;
                line-height: 28px;
                font-weight: 500;
                color: $font-darkblue;
                font-family: $font-circular-medium;

                @media only screen and (max-width: calc(64em - 1px)) {
                  height: 56px;
                  min-height: fit-content;
                  padding: 9px 16px;
                }

                &::before {
                  display: none;
                }

                &:hover {
                  background-color: transparent;
                }
              }

              &::after {
                content: " ";
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBfZC9DcIwEIXvnZGQqCIxALABLMBfxQh0jIK9AetQxUzACtBRUFAhCnSOHSmREvlyxel031c8PVBmtoVdyohP/BeHHAyGSwIVINmxDoPzb+uhw7NNfwzBWohwHuE9B9N0Mghx6Ic2j5//LMabKwFHgA6zyZ6e3/LWCmlF6aVJpjE1abgoyIq0qtdTd0l3BeeBSMldYPolAAAAAElFTkSuQmCC");
                width: 7px;
                height: 12px;
                background-repeat: no-repeat;
                left: 20px;
                top: 50%;
                margin-top: -8px;
                position: absolute;
                transform: rotate(0deg);
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }
      }

      &.is-open {
        .dropdown-toggle {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
