.TopFeatures {
  width: 100%;
  padding: 40px 0;
  @media screen and (max-width: 64em) {
    .l-padding {
      padding: 0;
    }
  }
  .cta-grouping {
    .cta-button-link {
      display: inline-block;
      width: auto;
      height: 53px;
      margin-right: 20px;
      &.secondary {
      }
      .cta-button-image {
        width: auto;
        height: 50px;
      }
    }
    .cta-button-ni-link {
      width: 100%;
      margin: 0 2% 10px 0;
      font-weight: 400;
      display: inline-block;
      text-align: center;
      background: $cochlear-yellow;
      color: $link-colour;
      border: 2px solid $cochlear-yellow;
      box-sizing: border-box;
      text-decoration: none;
      border-radius: 40px;
      position: relative;
      line-height: initial;
      font-size: 16px;
      font-family: $font-primary-bold;
      padding: 15px 65px 15px 45px;
      transition-duration: 150ms;
      transition-property: color, background-color, border-color;
      &::after {
        right: 20px;
        left: inherit;
        position: absolute;
        top: 25px;
        background-image: sax(Chevron, $cochlear-purple);
        content: ' ';
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-size: 100%;
        transform: rotate(-90deg);
        transition-duration: 150ms;
        transition-property: left, right, padding, border;
      }
      &:hover {
        background: $cochlear-yellow-New;
        border: 2px solid $cochlear-yellow-New;
        box-sizing: border-box;
        &::after {
          right: 10px;
        }
      }
      &.secondary {
        box-sizing: border-box;
        background: transparent;
        color: $link-colour;
        border: 1px solid $link-colour;
        box-sizing: border-box;
        &::after {
          font-weight: 400;
          background-image: sax(Chevron, $cochlear-purple);
          right: 20px;
          transition-duration: 0ms;
        }
        &:hover {
          background: transparent;
          color: $link-colour;
          border: 2px solid $link-colour;
          box-sizing: border-box;
          &::after {
            right: 10px;
          }
        }
      }
    }
    &.mb-show {
      display: block;
      padding: 0 20px;
    }
    &.pc-show {
      display: none;
      padding: 0;
    }
  }
  .product-media-description {
    padding: 0 20px;
    vertical-align: top;
    .content-container__grouper {
      .product-media-title {
        h2 {
          color: $font-darkblue;
          font-size: 28px;
          font-family: $font-circular-medium;
        }
      }
      .product-media {
        width: 100%;
        height: auto;
        text-align: center;
        img {
          width: auto;
          height: auto;
          max-width: 92%;
          max-height: 92%;
        }
      }
      .content {
        color: $font-darkblue;
        font-family: $font-circular-regular;
        margin-bottom: 20px;
        font-size: 18px;
        letter-spacing: -0.03px;
        line-height: 28px;
        font-weight: normal;
        p,
        li {
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          letter-spacing: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
        strong,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: inherit;
          font-family: inherit;
        }
      }
    }
  }
  .process-feature-cards {
    padding: 0 20px;
    .content-container__grouper {
      .sl {
        margin: 0;
        .sl-list {
          .sl-item {
            margin: 0 0 20px 0;
          }
        }
      }
      .card {
        position: relative;
        background: $brand-white;
        border: 2px solid $brand-white;
        border-bottom: 2px solid $bg-light-cool-grey;
        border-left: 2px solid $bg-light-cool-grey;
        box-sizing: border-box;
        padding: 0;
        text-align: center;
        overflow: hidden;
        .icon-box {
          margin: 0;
          img {
            width: 50px;
            height: 50px;
          }
        }
        h3 {
          margin: 0;
          font-family: $font-circular-medium;
          font-size: 20px;
          color: $font-darkblue;
          letter-spacing: -0.03px;
          line-height: 34px;
          font-weight: 500;
        }
        &:hover {
          border: 2px solid $card-cool-grey;
        }
        .bg {
          padding: 33px 17px;
        }
        .bg-cur {
          left: -2px;
          z-index: 1;
          margin-top: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          background-image: sax(Cornercurve);
          background-repeat: no-repeat;
          background-position: top left;
          background-size: contain;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .process-feature-cards {
      .content-container__grouper {
        .card {
          .bg {
          }
        }
      }
    }
  }
  @media screen and (min-width: 64em) {
    padding: 80px 0;
    .product-media-description {
      width: 50%;
      display: inline-flex;
      padding: 0 5% 0 0;
      .content-container__grouper {
        .product-media-title {
          h2 {
            font-size: 34px;
            font-family: $font-circular-medium;
            font-weight: 500;
            color: $font-darkblue;
            margin: 0 0 40px;
            line-height: 40px;
            letter-spacing: -0.03px;
          }
        }
        .product-media {
          width: 85%;
          height: auto;
          text-align: left;
          margin-bottom: 0;
          img {
            width: auto;
            height: auto;
          }
        }
        .content {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 40px;
        }
      }
    }
    .process-feature-cards {
      vertical-align: top;
      width: 50%;
      display: inline-flex;
      padding: 0;
      .content-container__grouper {
        width: 100%;

        .card {
          text-align: center;
          .bg {
            .icon-box {
              width: 100%;
              margin: 0;
              img {
                margin: 10px 0 0;
                width: 100px;
                height: 100px;
              }
            }
            .title {
              width: 100%;
              margin: 0;
              h3 {
                min-height: 75px;
                line-height: 38px;
                font-size: 28px;
              }
              &.noimage {
                margin: 60px 0 59px;
              }
            }
          }
        }
        .sl {
          margin: 0;
          .sl-list {
            margin: 0 0 20px 0;
            &.has-1-items {
              .sl-item {
                padding: 0;
                margin: 0;
              }
              .card {
                .bg {
                }
              }
            }
            .sl-item {
              padding: 0;
              margin: 0 10px;
              &:nth-child(1) {
                margin-left: 0;
              }
              &:nth-child(2) {
                margin-right: 0;
              }
            }
          }
          &:nth-child(2) {
            .sl-list {
              margin: 0;
            }
          }
        }
      }
    }
    .cta-grouping {
      a.cta-button-link {
        display: inline-block;
        width: auto;
        height: 53px;
        margin-right: 20px;
        &.secondary {
        }
        .cta-button-image {
          width: auto;
          height: 53px;
        }
      }
      .cta-button-ni-link {
        width: auto;
        text-align: left;
        &.secondary {
        }
      }
      &.mb-show {
        display: none;
      }
      &.pc-show {
        display: block;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .TopFeatures {
    .product-media-description {
      .content-container__grouper {
      }
    }
    .process-feature-cards {
      .content-container__grouper {
        .card {
          border-top: 2px solid $brand-white;
          border-right: 2px solid $bg-light-cool-grey;
          border-left: 2px solid $brand-white;
          border-bottom: 2px solid $bg-light-cool-grey;
          &:hover {
            border: 2px solid $bg-light-cool-grey;
          }
          .bg-cur {
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }
    .cta-grouping {
      a.cta-button-link {
        margin-left: 20px;
        margin-right: 0;
      }
      .cta-button-ni-link {
        text-align: center;
        padding: 15px 45px 15px 55px;
        margin: 0 0 10px 2%;
        &::after {
          left: 20px;
          right: inherit;
          transform: rotate(90deg);
        }
        &:hover {
          &::after {
            left: 10px;
          }
        }
        &.secondary {
          &:hover {
            &::after {
              left: 10px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 64em) {
      .product-media-description {
        padding: 0 0 0 5%;
        .content-container__grouper {
          .product-media {
            text-align: right;
          }
        }
      }
      .process-feature-cards {
        .content-container__grouper {
          .sl {
            .sl-list {
              &.has-1-items {
                .sl-item {
                  &:first-child {
                    margin-right: 0;
                    margin-left: 0;
                  }
                }
                .card {
                  background: $brand-white;
                }
              }
              .sl-item {
                &:first-child {
                  margin-right: 0;
                  margin-left: 10px;
                }
                &:nth-child(2) {
                  margin-right: 10px;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
