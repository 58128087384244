.animated {
  animation-fill-mode: none;

  &.toFadeInUp {
    height: 100%;
  }
}

.top-part {
  .animated {
    img {
      transform: scale(1.15);
    }
  }

  .slightZoomOut {
    img {
      transition: all 2s ease-in-out;
      transform: scale(1);
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
