.compatibility-global-footnotes {
  background-color: $bg-footnotes-grey;
  margin-top: 80px;

  @media only screen and (min-width: 1024px) {
    margin-top: 104px;
  }

  .footnotes-content {
    padding-block: 56px;
  }

  h2.footnotes-title {
    display: block;
    font-family: $font-circular-medium;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .footnotes-text {
    font-family: $font-circular-regular;
    font-size: 14px;
    line-height: 20px;
  }
}
