.side-by-side-video {
  width: 100%;
  padding: 40px 0;
  @media screen and (max-width: 64em) {
    .l-padding {
      padding: 0;
    }
  }
  .side-video-cards {
    padding: 0 20px;
    .content-container__grouper {
      .sl {
        .sl-list {
          .sl-item {
            padding: 0 10px;
          }
        }
      }
      .card {
        background: $brand-white;
        border-bottom: 1px solid $mid-grey;
        border-left: 1px solid $mid-grey;
        border-right: 1px solid $mid-grey;
        .product-media-banner {
          margin-bottom: 0;
          background: transparent;
          height: auto;
          .video-banner {
            margin-top: 0;
          }
          .video-modal-cta {
            height: auto;
            position: relative;
          }
        }
        .product-media-title {
          padding: 35px 25px;
          h2 {
            font-family: $font-circular-medium;
            color: $font-darkblue;
            font-size: 28px;
            margin-bottom: 20px;
            font-weight: 500;
            letter-spacing: -0.03px;
            line-height: 40px;
          }
          .content {
            color: $font-darkblue;
            font-family: $font-circular-regular;
            font-size: 18px;
            text-align: left;
            font-weight: normal;
            letter-spacing: -0.03px;
            line-height: 28px;
            p,
            li {
              color: inherit;
              font-family: inherit;
              font-size: inherit;
              letter-spacing: inherit;
              font-weight: inherit;
              line-height: inherit;
            }
            strong,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: inherit;
              font-family: inherit;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 64em) {
    padding: 0;
    .l-padding {
      padding: 40px 0;
      z-index: 9;
    }
    .side-video-cards {
      padding: 0 20px;
      .content-container__grouper {
        .sl {
          margin: 0;
          .sl-list {
            .sl-item {
              padding: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 64em) {
    padding: 80px 0;
    .side-video-cards {
      .content-container__grouper {
        .sl {
          display: table;
          margin: 0;
          .sl-list {
            .sl-item {
              display: table-cell;
              padding: 0 10px;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        .card {
          height: 100%;
          .product-media-banner {
            max-height: 310px;
            .video-modal-cta {
              max-height: 310px;
            }
          }
          .product-media-title {
            padding: 40px;
            h2 {
              font-size: 34px;
              margin-bottom: 40px;
            }
            .content {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .side-by-side-video {
    .side-video-cards {
      .content-container__grouper {
        .card {
          .product-media-title {
            .content {
              text-align: right;
            }
          }
        }
      }
    }
    @media screen and (min-width: 64em) {
      .side-video-cards {
        .content-container__grouper {
          .sl {
            .sl-list {
              .sl-item {
                padding: 0 10px;
                &:first-child {
                  padding-right: 0;
                }
                &:last-child {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
