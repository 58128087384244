.selection-panel {
  border-radius: 0 0 24px;
  font-family: $font-circular-medium;
  padding-block: 24px;
  position: relative;

  [dir="rtl"] & {
    border-radius: 0 0 0 24px;
  }

  @media only screen and (min-width: 768px) {
    border-radius: 0 0 40px;

    [dir="rtl"] & {
      border-radius: 0 0 0 40px;
    }
  }

  &:not(.empty) {
    background-color: $bg-lighter-grey;
  }

  .l-container {
    max-width: 100%;

    @media only screen and (max-width: 1023px) {
      max-width: 720px;
      width: 100%;
    }
  }

  &-start-over-link {
    color: $cochlear-purple;
    display: inline-block;
    text-decoration: none;

    &-icon {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGNSURBVHgBnVM7TsNAEJ1ZR5ECjSXcg0+ASwoULx3HyA3CCfAmHADlBrR05ARZKGjxDXBPkEyDIoXsMGNjyVYSJ+Q1Ozu7781nZwFa0A/uEtgB1UZGIAOHCOxL3ijwH7IA9yNjCkQprtzI5ibbmoECh7ARFHGoAXXUez8Y32vf+NWJV7+WfVsbHmkWQV35nue3GHbjKSH0EDHiwwuOdB129WO2sIu1Hth5YriMUcOXm/TlMxngjwuBIOMAkeuoZK0HdehgZAgwkQwaft+ckafemOkjuCtvm0BVjqwN/8Lmp8e6h1wml/W1NYM26MBoAjXjLLKDBARxMCZZlRjV5hAoSaNKa18SNzIqLUoVOZqK6Wpvvwv8CsPSwFQppCexuatDeaJd5OIOT2XB4dFWdm4sEU1477PyrE3kbwZmBRlpIv+iIxu1IkMdiNkbybzHJ+OHpXKT1w+Tyvkl90eBF3OgGxkgqR2W5afDmrrvPDQ878O2EiSykDl63hCop1kKqfPiF5a3Mmm29EtKrt//BaH3qp6V/J7VAAAAAElFTkSuQmCC");
      background-repeat: no-repeat;
      background-position: center center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: inline-block;
      margin-bottom: -4px;
      margin-inline-end: 4px;
    }
  }

  &-text {
    color: $font-darkblue;
    font-family: $font-circular-regular;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &-list-container {
    margin-top: 24px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      list-style: none;
      padding: 0;

      @media only screen and (min-width: 1024px) {
        flex-wrap: nowrap;
        gap: 24px;
      }
    }

    li {
      display: block;
      flex: 1;
      margin-bottom: 16px;
      min-width: fit-content;
      margin-top: 0;

      @media only screen and (min-width: 768px) {
        flex: 0 1 45%;
      }

      @media only screen and (min-width: 1024px) {
        flex: 0 1 33%;
      }
    }

    .selection-card {
      border: 1px solid $cool-greylight;
      border-radius: 8px;
      background-color: $brand-white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;

      &-image {
        background: $cochlear-lightyellow;
        min-width: 64px;
        height: 64px;
        position: relative;

        img {
          height: auto;
          width: auto;
          max-height: 32px;
          max-width: 48px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-title {
        color: $font-darkblue;
        font-size: 18px;
        line-height: 24px;
        margin-inline-end: auto;
        padding-inline-start: 16px;
      }

      &-link {
        background: none;
        border: none;
        color: $cochlear-purple;
        cursor: pointer;
        font-size: 16px;
        text-decoration: none;
        padding: 0 8px;
      }

      &:hover {
        border: 1px solid $cochlear-purple;

        .selection-card-image {
          background-color: $bg-compatibility-textcolumn-yellow;
        }

        .selection-card-link {
          color: $clr-magenta-blue;
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    left: 0;
    opacity: 0;
    top: -10%;
    transition:
      top ease-in-out 0.3s,
      opacity 0s 0.3s; /* hide after top animation */
    width: 100%;
    z-index: 2;

    &.scrolled {
      opacity: 1;
      top: 0;
      transition:
        top ease-in-out 0.3s,
        opacity 0s;

      /* position under page navigation */
      :has(.page-mobile-header) & {
        top: 75px;

        @media only screen and (min-width: 1024px) {
          top: 65px;
        }
      }
    }

    .l-padding {
      display: flex;
    }

    .selection-panel-start-over-link {
      align-items: center;
      display: flex;
      margin-inline-end: 8px;
    }

    .selection-panel-list-container {
      margin-top: 0;
    }

    .selection-panel-text,
    .selection-card-link {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      .selection-card-title {
        font-size: 16px;
      }
    }

    @media only screen and (max-width: 1023px) {
      .selection-panel-start-over-link-text,
      .selection-card-image {
        display: none;
      }

      .selection-panel-start-over-link {
        margin-inline-end: 8px;
      }

      ul {
        display: block;
      }

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .selection-card {
        background: none;
        border: none;
      }

      .selection-card-title {
        padding-inline: 0;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      ul,
      li,
      .selection-card,
      .selection-card-title {
        display: inline;
      }

      li:not(:last-child) .selection-card-title::after {
        content: "•";
        font-size: 14px;
        margin-inline: 8px;
        vertical-align: text-top;
      }
    }

    @media only screen and (min-width: 1024px) {
      li {
        margin-bottom: 0;
      }

      .selection-card-title {
        padding-inline-end: 16px;
      }
    }
  }
}

/* higher z-index so that sticky selection panel can go underneath */
:has(.selection-panel) .page-mobile-header {
  z-index: 10;
}

:has(.selection-panel) header.page-navigation {
  position: relative;
  z-index: 10;
}
