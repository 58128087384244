.sub-header {
  h1.sub-header-title {
    color: $font-darkblue;
    font-family: $font-circular-medium;
    font-size: 32px;
    line-height: 44px;
    margin-block: 100px 24px;
  }

  &-link {
    color: $cochlear-purple;
    display: block;
    font-family: $font-circular-regular;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    position: relative;
    text-decoration: none;

    &::after {
      background-image: sax(Chevron, $cochlear-purple);
      content: " ";
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: rotate(-90deg);
      vertical-align: middle;

      [dir="rtl"] & {
        transform: rotate(90deg) scaleX(-1);
      }
    }

    &.external {
      &::after {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE7SURBVHgB7VTdTcMwEP7OpO+R2ryHDRgAlKzQDdwRukBxYQE2oEwAG2CUBboBeU8r5R3w4YQW0TZOHCokHvpJfjnf3Xc/nw38MaiP8+VIpUSIDy4Mymytno4iSEMVcyBeXfeGMcnWs8W+XcATulQ5sRkTmcn22PLyrrjgu7qBeHZ6MZYvq9lY/xhDEt3cW3tsT46mse10MEBaOxuEzW60Y98kl0T8QMLM0dXBFgaYZsXhHF3JdXEt00jJNn/vHTQl94nxJnAm569Fn8GUvyZoq1yvlKZ3c64d76CTwJU8Gd4+JqN5rbxKwq74oC15OlSSXTMXfOHzTts7+IAmg6nvQpvQ2sGm9TscgV4y/ZcEwR5bchUpeKH6VgTKXgRWFFKwkPBBJSDmvMutJtCFWlhJlraiEH3whiVOOOETBr2KM5/mSf8AAAAASUVORK5CYII=");
        content: " ";
        width: 24px;
        height: 24px;
        transform: rotate(0);
        position: absolute;
        right: 0px;
        top: 2px;
      }
    }
  }
}
