.callout-card {
  position: relative;
  display: block;

  .img-container {
    position: relative;
    left: 30px;
    overflow: hidden;

    @media only screen and (min-width: 64em) {
      left: 0;
      height: 460px;
    }

    &::before {
      @media only screen and (min-width: 64em) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        background-color: white;
      }
    }

    img {
      width: auto;
      height: 450px;

      @media only screen and (min-width: 64em) {
        width: 100%;
        height: auto;
      }
    }
  }

  .content-container {
    border-radius: 0 0 40px 0;
    position: absolute;
    top: 5%;
    width: 95%;

    @media only screen and (min-width: 48em) {
      width: 55%;
    }

    @media only screen and (min-width: 64em) {
      width: 420px;
    }

    .content-container__grouper {
      width: 80%;
      margin: 40px auto;
    }

    h3,
    .is-h3 {
      font-family: $font-primary;
      font-size: 22px;
      line-height: 28px;
      text-align: left;
      font-weight: normal;
    }

    p {
      font-family: $font-primary;
      font-size: 30px;
      font-weight: 900;
      line-height: 34px;
      text-align: left;
      width: 90%;
      margin: 20px 0;

      @media only screen and (min-width: 64em) {
        font-size: 36px;
        line-height: 40px;
        width: 100%;
      }
    }

    .cta-select {
      appearance: none;
      text-align: left;
      background: sax(Chevron, white) no-repeat right;
      color: white;
      border: 0;
      border-radius: 0;
      padding: 5px;
      margin: 10px auto 20px auto;
      width: 100%;

      option {
        background-color: white !important;
        color: $cochlear-purple !important;
        margin: 10px;
      }
    }
  }

  .cta-button {
    margin: 10px 0 0 0;
    font-weight: 900;
    display: inline-block;

    border: 0;
    background-color: white;
    color: $link-colour;

    &::after {
      background-image: sax(Chevron, $cochlear-purple);
    }

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.85);
      color: $link-colour;

      &::after {
        background-image: sax(Chevron, $cochlear-purple);
      }
    }
  }

  // on hover fade in the hover element (:after), and fade out the hover off element (:before)
  $hover-on-transition: 'cubic-bezier(0.550, 0.880, 0.675, 0.880)';
  $hover-off-transition: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';

  &.yellow-gradient,
  &.purple-gradient {
    .content-container {
      z-index: 1;
      &::after,
      &::before {
        content: '';
        top: 0;
        position: absolute;
        background-image: linear-gradient(
          to right,
          rgba(255, 176, 0, 1),
          rgba(255, 176, 0, 0.9)
        );
        z-index: -1;
        @include transition(0.2s, #{$hover-off-transition});
        opacity: 0;
        border-radius: 0 0 40px 0;
        width: 100%;
        height: 100%;
      }
      &::before {
        background-image: linear-gradient(
          to right,
          rgba(253, 200, 47, 1),
          rgba(253, 200, 47, 0.85)
        );
        @include transition(0.2s, #{$hover-on-transition});
        opacity: 1;
      }
    }

    .cta-select {
      background: sax(Chevron, $cool-grey) no-repeat right;
      color: $cool-grey;
      border-bottom: 1px solid $cool-grey;
    }
  }

  &.purple-gradient {
    .content-container {
      &::after,
      &::before {
        background-image: linear-gradient(
          to right,
          rgba(103, 42, 197, 1),
          rgba(103, 42, 197, 0.8)
        );
      }
      &::before {
        background-image: linear-gradient(
          to right,
          rgba(63, 20, 130, 1),
          rgba(63, 20, 130, 0.8)
        );
      }
      h2,
      p {
        color: $brand-white;
      }
    }

    .cta-select {
      background: sax(Chevron, white) no-repeat right;
      color: white;
      border-bottom: 1px solid white;
    }
  }

  &:hover {
    &.yellow-gradient,
    &.purple-gradient {
      .content-container {
        &::before {
          @include transition(0.2s, #{$hover-off-transition});
          opacity: 0;
        }
        &::after {
          @include transition(0.2s, #{$hover-on-transition});
          opacity: 1;
        }
      }

      .cta-button {
        background-color: rgba(255, 255, 255, 0.85);

        &::after {
          @include transition(0.2s);
          right: 16px;
        }
      }
    }

    // &.purple-gradient {
    // 	.content-container {
    // 		@include transition(0.2s);
    // 		background-image: linear-gradient(
    // 			to right,
    // 			rgba(63, 20, 130, 0.9),
    // 			rgba(63, 20, 130, 0.85)
    // 		);
    // 	}

    // 	.cta-button {
    // 		background-color: rgba(255, 255, 255, 0.85);

    // 		&::after {
    // 			@include transition(0.2s);
    // 			right: 16px;
    // 		}
    // 	}
    // }
  }
}

.sl {
  margin-left: auto;
  margin-right: auto;

  .sl-item {
    .callout-card {
      height: 460px;
      margin-bottom: 0;

      .img-container {
        img {
          @media only screen and (min-width: 64em) {
            width: auto;
            height: 450px;
          }
        }
      }

      .content-container {
        @media only screen and (min-width: 64em) {
          width: 75%;
        }
      }
    }
  }
}

span.callout-card {
  height: auto;
  position: absolute;
}

// rtl styles
div#root > div[dir='rtl'] {
  .callout-card {
    &.purple-gradient,
    &.yellow-gradient {
      .content-container {
        &::before {
          border-radius: 0 0 0 40px;
          background-image: linear-gradient(
            to left,
            rgba(255, 176, 0, 1),
            rgba(255, 176, 0, 0.9)
          );
        }
        &::after {
          border-radius: 0 0 0 40px;
          background-image: linear-gradient(
            to left,
            rgba(253, 200, 47, 1),
            rgba(253, 200, 47, 0.85)
          );
        }
      }
    }

    &.purple-gradient {
      .content-container {
        &::after {
          background-image: linear-gradient(
            to left,
            rgba(103, 42, 197, 1),
            rgba(103, 42, 197, 0.8)
          );
        }
        &::before {
          background-image: linear-gradient(
            to left,
            rgba(63, 20, 130, 1),
            rgba(63, 20, 130, 0.8)
          );
        }
      }
      .cta-select {
        background-position: left;
      }
    }

    &:hover {
      &.purple-gradient,
      &.yellow-gradient {
        .cta-button {
          &::before {
            left: 16px;
          }
        }
      }
    }
    .cta-button {
      border-right: initial;
    }
    .content-container {
      border-radius: 0 0 0 40px;
      h3,
      p,
      .is-h3 {
        text-align: right;
      }
    }
    .img-container {
      left: auto; /*replaced*/
      right: 30px;

      @media only screen and (min-width: 64em) {
        left: auto; /*replaced*/
        right: 0;
      }

      &::before {
        @media only screen and (min-width: 64em) {
          left: auto; /*replaced*/
          right: 0;
        }
      }
    }
  }
}
