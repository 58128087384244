.hearing-quiz {
  background-color: $light-grey;
  padding-bottom: 60px;

  .quiz-header {
    background-color: $cochlear-black;
    border-radius: 0 0 40px 0;
    padding: 45px 0 45px 0;

    @media only screen and (min-width: 64em) {
      padding: 90px 0 45px 0;
    }

    h1 {
      color: $brand-white;
    }
  }

  .quiz-container {
    margin: 40px auto 20px auto;
    padding: 30px;
    max-width: 95%;
    background: $brand-white;

    @media only screen and (min-width: 64em) {
      padding: 60px;
    }

    .cta-button {
      @include cta-button;
      margin-top: 20px;
    }

    .question {
      ol {
        list-style-type: none;
      }

      @media only screen and (min-width: 64em) {
        .secondary {
          margin-left: 10px;
        }
      }
    }

    .question-button {
      background-color: transparent;
      padding: 16px 36px 16px 20px;
      position: relative;
      border: 0;
      text-align: left;
      border-bottom: 1px solid $mid-grey;
      font-family: $font-primary;
      line-height: 28px;
      font-size: 18px;
      color: $cool-grey;
      display: block;
      width: 100%;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color;
      border-left: 5px solid transparent;

      @media only screen and (min-width: 64em) {
        min-width: 522px;
        width: 55%;
      }

      &:hover {
        border-left: 5px solid $cochlear-yellow;
        background-color: $lighter-grey;
      }

      &:after {
        content: '';
        background-image: sax(Chevron, $cochlear-purple);
        position: absolute;
        background-size: 100%;
        width: 20px;
        height: 20px;
        right: 0;
        top: 50%;
        transform: rotate(-90deg) translateX(50%) translateY(-14px);
      }
    }

    .step-count {
      text-align: center;
      background-color: $lighter-grey;
      padding: 0 30px;
      position: relative;
      top: 30px;
      margin: 0 -30px -30px -30px;
      border-top: 1px solid $mid-grey;
      border-bottom: 1px solid $mid-grey;

      @media only screen and (min-width: 64em) {
        margin-left: -60px;
        margin-right: -60px;
        top: 60px;
      }

      p,
      button {
        line-height: 70px;
        padding: 0;
        font-family: $font-primary;
        font-size: 18px;
        margin-top: 0;
      }

      button {
        color: $cochlear-purple;
        float: left;
        border: 0;
        background-color: transparent;

        &:before {
          content: '';
          background-image: sax(Chevron, $cochlear-purple);
          position: relative;
          display: inline-block;
          width: 20px;
          height: 20px;
          left: -6px;
          top: 2px;
          transform: rotate(90deg);
        }
      }
    }
  }

  .disclaimer {
    @extend .l-padding;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0;
  }

  #pdf-canvas {
    display: none;
  }
}

div#root > div[dir='rtl'] {
  .hearing-quiz {
    .quiz-container {
      .step-count {
        button {
          float: right;
          &::before {
            top: -4px;
            transform: rotate(-90deg) translateX(-50%) translateY(14px);
          }
        }
      }
      .question {
        @media only screen and (min-width: 64em) {
          .secondary {
            margin-left: 0px;
            margin-right: 10px;
          }
        }
      }
      .question-button {
        border-right: solid 5px transparent;
        text-align: right;
        padding: 16px 20px 16px 36px;

        &::after {
          left: 0;
          right: auto; /*replaced*/
          transform: rotate(90deg) translateX(-50%) translateY(-14px);
        }

        &:hover {
          border-left: solid 5px transparent;
          border-right: solid 5px $cochlear-yellow;
        }
      }
      .cta-button {
        padding: 15px 27px;
        &:hover {
          &::after {
            left: -8px;
          }
        }
        &::after {
          left: -3px;
        }
      }
    }
  }
}
