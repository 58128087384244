// for some reason, these styles are also needed for other components
// that use .landing-banner e.g. HeaderBanner
.landing-banner {
  margin-bottom: 20px;

  @media only screen and (min-width: 64em) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 63.9375em) {
    .l-padding {
      padding: 0;
    }
  }

  .background {
    height: 100%;
    background-color: $light-grey;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;

    @media only screen and (min-width: 64em) {
      width: 48%;
      border-radius: 0 0 40px 0;
    }
  }

  .image {
    position: relative;
    z-index: 2;
    width: calc(100% - 20px);

    @media only screen and (min-width: 64em) {
      width: 56%;
      max-width: none;
      left: 44%;
      margin-top: 0;
      position: absolute;
      height: 90%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  &.no-image {
    .background {
      border-radius: 0 0 40px 0;
    }

    .image {
      display: none;
    }
  }

  &.full-width {
    margin-bottom: 0;

    .background {
      background-color: white;

      @media only screen and (min-width: 64em) {
        display: none;
      }
    }

    .image {
      width: 100%;
      margin-bottom: -10px;

      @media only screen and (min-width: 64em) {
        max-width: none;
        left: 0;
        z-index: 1;
        margin-top: 0;
        position: absolute;
        height: 100%;
        overflow: hidden;
      }

      img {
        @media only screen and (min-width: 77.75em) {
          margin-top: -250px;
        }
      }
    }
  }
}

.hero-banner {
  .hero-banner-container {
    position: relative;
  }

  .hero-banner-description {
    position: relative;
    margin: 50px 0 20px;
    z-index: 2;
    min-height: inherit;
    width: 100%;
    @media only screen and (min-width: 64em) {
      margin: 120px 0 20px;
      width: 40%;
    }
  }

  .card {
    padding: 0 20px;
    background-color: transparent;
    border: 0;

    @media only screen and (min-width: 77.75em) {
      padding: 0;
    }

    @media only screen and (min-width: 128em) {
      margin-top: 50%;
    }

    h1 {
      color: $brand-white;
      font-size: 34px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 54px;
      margin-bottom: 16px;

      @media only screen and (min-width: 64em) {
        font-size: 46px;
      }
    }

    .description {
      color: $brand-white;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: -0.03px;
      line-height: 28px;
      margin-bottom: 20px;

      p,
      li {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      strong,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: inherit;
        font-family: inherit;
      }

      @media only screen and (min-width: 64em) {
        max-height: 300px;
        overflow-y: auto;
        min-height: 100px;

        &::-webkit-scrollbar {
          width: 8px;
          height: 1px;
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
            background: rgba(150, 150, 150, 0.2);
            border-radius: 10px;
            width: 8px;
            height: 18px;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px transparent;
            border-radius: 10px;
            background: transparent;
          }
        }
      }
    }
  }

  .cta-grouping {
    padding: 10px 20px 60px;
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    @media only screen and (min-width: 64em) {
      padding: 0 20px 100px;
    }
    @media only screen and (min-width: 77.75em) {
      padding: 0 20px 100px 0;
    }
  }

  .get-started-div {
    @media only screen and (min-width: 64em) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
    }
  }

  .cta-button-ni-link {
    @include cta-button-ni-link;
    margin-right: 0;
    margin-bottom: 20px;
    font-family: $font-circular-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    border-radius: 16px;

    &.purple-theme {
      background: $link-colour;
      border-color: $link-colour;
      color: white;
      &::after {
        background-image: sax(Chevron, $brand-white);
      }
    }

    &::after {
      margin-top: -5px;
    }

    @media only screen and (min-width: 64em) {
      width: auto;
      margin: 0 20px 20px 0;
    }

    &.secondary {
      background: transparent;
      color: $brand-white;
      border: 1px solid $brand-white;
      margin: 1px;

      &::after {
        background-image: sax(Chevron, $brand-white);
      }
      &:hover {
        background: transparent;
        border: 2px solid $brand-white;
        color: $brand-white;
        margin: 0;
      }

      &.purple-theme {
        border-color: $link-colour;
        color: $link-colour;

        &::after {
          background-image: sax(Chevron, $link-colour);
        }
      }
    }
  }

  &.full-width {
    @media only screen and (min-width: 128em) {
      min-height: 800px;
    }

    .background {
      background-color: $font-darkblue;

      &.purple-theme {
        background-color: #f1be48;
      }

      &.yellow-theme {
        background-color: $font-darkblue;
      }
    }

    .backImage {
      @media only screen and (max-width: 63.9em) {
        background: none !important;
      }

      @media only screen and (min-width: 64em) {
        .mb-show {
          display: none;
        }

        &.version-2 {
          img {
            border-radius: 0 0 45px 45px;
          }
        }
      }

      @media only screen and (max-width: 47.99em) {
        background: none !important;

        &.version-2 {
          img {
            border-radius: 0 0 45px 45px;
          }
        }
      }
    }
    .curveImage {
      display: block;
      @media only screen and (min-width: 64em) {
        display: block;
      }

      @media only screen and (max-width: 63.9em) {
        &.mobile-curve {
          &.purple-theme,
          &.yellow-theme {
            &::before {
              content: "";
              background: #384967;
              position: absolute !important;
              top: -45px;
              left: -15%;
              width: 125vw;
              height: 45vw;
              z-index: 2;
              border-radius: 0 220px 0 0;
            }
          }

          &.purple-theme {
            &::before {
              background: #f1be48;
            }
          }

          &.version-1 {
            &::before {
              top: -45px;
              left: 0;
              width: 100vw;
              border-radius: 45px 45px 0 0;
            }
          }

          &.version-2,
          &.version-3 {
            &::before {
              content: none;
            }
          }
        }
      }

      @media only screen and (max-width: 47.99em) {
        &.mobile-curve {
          &.purple-theme,
          &.yellow-theme {
            &::before {
              top: -45px;
              left: 0;
              width: 115vw;
              border-radius: 0 200px 0 0;
            }
          }

          &.purple-theme {
            &::before {
              background: #f1be48;
            }
          }

          &.version-1 {
            &::before {
              top: -45px;
              left: 0;
              width: 100vw;
              border-radius: 45px 45px 0 0;
            }
          }

          &.version-2,
          &.version-3 {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 77.75em) {
    .l-padding {
      padding: 0 36px;
    }
  }

  &.no-image {
    .hero-banner-container {
      .hero-banner-description {
        .card {
          h1 {
            color: $font-darkblue;
          }

          .description {
            color: $font-darkblue;
          }

          .cta-button-ni-link {
            &.secondary {
              color: $link-colour;
              border: 1px solid $link-colour;
              margin: 1px;
              &::after {
                background-image: sax(Chevron, $cochlear-purple);
              }
              &:hover {
                border: 2px solid $link-colour;
                color: $link-colour;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

div#root > div[dir="rtl"] {
  .landing-banner {
    &.hero-banner {
      .hero-banner-container {
        .cta-grouping {
          @media only screen and (min-width: 77.75em) {
            padding: 0 0 100px 20px;
          }

          .cta-button-ni-link {
            text-align: center;
            margin: 0 0 20px 0;
            padding: 15px 30px 15px 45px;

            @media only screen and (min-width: 64em) {
              margin: 0 0 20px 20px;

              &.secondary {
                margin: 1px;
                &:hover {
                  margin: 0;
                }
              }
            }

            &::after {
              left: 20px;
              right: inherit;
              transform: rotate(90deg);
            }

            &:hover {
              &::after {
                left: 10px;
              }
            }

            &.secondary {
              margin: 1px;

              &:hover {
                margin: 0;

                &::after {
                  left: 10px;
                }
              }
            }
          }
        }
      }

      &.full-width {
        .backImage {
          display: block;
          -moz-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        .curveImage {
          display: none;
          @media only screen and (min-width: 64em) {
            display: block;
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }

    /* all below styles duplicated from HeaderBanner.scss, retained to reduce indirection */
    &.full-width {
      .background {
        border-radius: 0;
      }

      .content {
        .content-grouper {
          margin: 0;
          padding: 30px 30px 0 30px;
        }
      }
    }

    .background {
      left: inherit;
      right: 0;
      border-radius: 0 0 0 40px;
    }

    .content {
      border-radius: 0 0 0 40px;

      .content-grouper {
        padding: 28px 20px 20px 20px;
        margin: 0 8px;

        .header-highlight {
          left: inherit;
          right: 0;
        }
      }
    }
  }
  @media only screen and (min-width: 64em) {
    .landing-banner {
      .content {
        .content-grouper {
          padding: 40px 36px 0 0;
          margin: 0 36px 0 150px;
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
    .landing-banner {
      &.full-width {
        .content:before {
          left: 100%;
        }
      }

      &:not(.full-width) {
        .image {
          left: auto;
          right: 44%;
        }
      }
    }
  }
  .landing-banner {
    .content-link {
      &::after {
        content: "";
        transform: rotate(90deg);
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}
