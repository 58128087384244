.sidebarHeader {
  h2 {
    color: #fff;
  }
}

/* Position webchat button above nav bar (60px high) for mobile and tablet */
div.embeddedServiceHelpButton div.helpButton,
div.embeddedServiceHelpButton.embeddedServiceBottomTabBar div.helpButton {
  bottom: 72px;

  /* Override safari-specific selector from salesforce */
  @supports not (-ms-high-contrast: none) {
    bottom: 72px;
  }

  @media only screen and (min-width: 48em) {
    bottom: 60px;

    @supports not (-ms-high-contrast: none) {
      bottom: 60px;
    }
  }

  @media only screen and (min-width: 64em) {
    bottom: 0;
  }
}

div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar {
  /* Set height and width for svg, fixes not being computed on mobile */
  header.sidebarHeader button.minimizeButton svg,
  header.sidebarHeader button.closeButton svg {
    height: 20px;
    width: 20px;
  }

  /*
	Restyle mobile fullscreen webchat form window between header (65px high) and nav bar (60px high)
	Includes workaround to reposition window 60px up
	*/
  @media (max-width: 63.9375em) {
    height: calc(100% - 125px);
    top: 125px;

    /* Move side chat window above nav bar if not fullscreen */
    div.dockableContainer.showDockableContainer {
      bottom: 60px;
    }
  }
}

/* Minimised button */
div.modalContainer.sidebarMinimized.layout-docked.embeddedServiceSidebar {
  @media (max-width: 63.9375em) {
    button.sidebarHeader.minimizedContainer.helpButton.embeddedServiceSidebarMinimizedDefaultUI {
      bottom: 60px;

      /* Override safari-specific selector from salesforce */
      @supports not (-ms-high-contrast: none) {
        bottom: 60px;
      }

      &.embeddedServiceBottomTabBar {
        bottom: 72px;

        @supports not (-ms-high-contrast: none) {
          bottom: 72px;
        }
      }
    }
  }
}
