.campaign-container {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg-pale-green;

  &.orange {
    background-color: #ebe7d9;

    .accordion-panel {
      border-top-color: $bg-yellow-white;
    }
  }

  &-accordian {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 104px;
    max-width: 1366px;

    &.left-image {
      flex-direction: row-reverse;

      @media (max-width: 79.9375em) {
        justify-content: center;
      }

      .campaign-container-accordian-faq {
        margin-right: 0;
        margin-left: 123px;

        @media (max-width: 79.9375em) {
          margin-left: 40px;
        }

        @media (max-width: 47.9375em) {
          margin-left: 0;
        }
      }
    }

    @media (max-width: 47.9375em) {
      display: block;
      padding: 48px 20px;
    }

    &-faq {
      max-width: 469px;
      margin-right: 123px;

      @media (max-width: 79.9375em) {
        max-width: calc(50% - 40px);
        margin-right: 40px;
      }

      @media (max-width: 47.9375em) {
        max-width: 100%;
      }

      &-content {
        &-heading {
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          color: $font-darkblue;

          @media (max-width: 47.9375em) {
            font-size: 32px;
            line-height: 44px;
          }
        }

        &-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          margin-top: 16px;
          color: $font-darkblue;

          @media (max-width: 47.9375em) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &-get-started {
          display: flex;
          flex-wrap: wrap;
          margin-top: 32px;

          .cta-button {
            font-family: $font-circular-medium;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: $cochlear-purple;
            border-radius: 16px;
            padding: 12px 28px 12px 32px;
            height: 52px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 192px;
            text-align: center;
            background-color: $cochlear-yellow-card;
            border-color: $cochlear-yellow-card;

            @media (max-width: 47.9375em) {
              max-width: 343px;
              width: 100%;
              font-size: 16px;
              line-height: 24px;
            }

            &::after {
              top: 4px;
            }
          }
        }
      }
    }

    .accordion-item {
      padding-right: 0;
      margin-right: 0;
      width: 575px;

      @media (max-width: 79.9375em) {
        min-width: 400px;
        width: 100%;
      }

      @media (max-width: 47.9375em) {
        width: calc(100vw - 32px);
        min-width: auto;
      }

      &:focus,
      &:hover {
        text-decoration: none;
      }

      &:not(:first-of-type) .accordion-item-title {
        border-top: 1px solid $mid-grey;
      }
    }

    .accordion-item-title {
      cursor: pointer;
    }

    .accordion-trigger {
      font-size: 18px;
      line-height: 28px;
      background-color: $bg-yellow-white;
      color: $cool-grey;
      cursor: pointer;
      padding: 12px 16px;
      width: 100%;
      text-align: left;
      border: 0;
      outline: none;
      display: block;
      margin: 0;
      position: relative;
      font-weight: 400;
      min-height: 52px;
      color: $cochlear-purple;

      @media (max-width: 47.9375em) {
        height: auto;
      }

      &:hover {
        text-decoration: none;
        background-color: $bg-yellow-white;
      }

      &::after {
        background: sax(Chevron, $cochlear-purple);
        white-space: pre;
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        right: 10px;
        background-size: 100%;
        top: calc(40% - 7px);
      }

      &.active {
        box-shadow: inset 0px -1px 0px $bg-deep-honey-yellow;

        &::after {
          transform: rotate(180deg);
        }
      }
    }

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .active,
    .accordion:hover {
      color: $cochlear-purple;
    }

    .accordion:focus {
      outline-width: 5px;
      outline-style: auto;
      outline-color: -webkit-focus-ring-color;
    }

    .expanded {
      display: block;
    }

    .notExpanded {
      display: none;
    }

    /* Style the accordion panel. Note: hidden by default */
    .panel {
      padding: 0 18px;
      background-color: $brand-white;
      display: none;
    }

    .accordion-panel {
      text-decoration: none;
      padding: 12px 16px 16px;
      background-color: $bg-yellow-white;
      border-top: 1px solid $bg-deep-honey-yellow;
      color: $font-darkblue;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }

      p > a,
      a {
        color: $cochlear-purple;
      }
    }

    &.full-width-border {
      border-top: 1px solid $mid-grey;
      border-bottom: 1px solid $mid-grey;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100vw;
      margin-left: calc(50% - 50vw);
      padding-left: calc(50vw - 50%);
      padding-right: calc(50vw - 50%);
    }
  }

  &-accordian-collaps {
    @media (max-width: 47.9375em) {
      margin-top: 32px;
    }
  }

  .break-line {
    border-bottom: 1px solid $bg-dark-green;
    margin: 80px 0;

    @media only screen and (max-width: 47.9375em) {
      margin: 48px 0;
    }
  }
}

//rtl-styles
div#root > div[dir="rtl"] {
  .stacked-accordions {
    &.full-width-border {
      margin-left: 0;
      margin-right: calc(50% - 50vw);
    }

    .accordion-trigger {
      text-align: right;

      &::after {
        right: inherit;
        left: 0;
      }
    }

    tr {
      td {
        border-left: none;
      }
    }
  }
}
