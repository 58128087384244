.contact-us-page {
  .is-50-split {
    margin: 0 -20px 0 -20px;

    @media only screen and (min-width: 64em) {
      margin: 60px auto 40px auto;
    }
  }

  .sl .sl-item .callout-card {
    @media only screen and (min-width: 64em) {
      margin-left: 80px;
    }

    .content-container {
      width: 85%;
    }
  }
}

.contact-us-dropdown {
  margin: 0;
  padding: 30px 40px;

  h4 {
    margin-top: 20px;
  }

  .phone,
  .email {
    font-size: 16px;
    padding-left: 35px;
    margin-bottom: 0;

    .phoneOption {
      font-size: 14px;
      margin-left: 5px;
      line-height: 16px;
    }
  }

  .phone {
    background: sax(Phone, $cochlear-yellow) no-repeat left;
  }

  .email {
    background: sax(Mail, $cochlear-yellow) no-repeat left;
  }

  .cta-button {
    color: $brand-white;
    margin-top: 20px;
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .form-dropdown-box {
    border-right: 5px solid $cochlear-yellow;
    border-left: none;

    .phone,
    .email {
      padding-right: 35px;
      padding-left: initial;

      .phoneOption {
        margin-right: 5px;
        margin-left: initial;
      }
    }

    .phone {
      background: sax(Phone, $cochlear-yellow) no-repeat right;
    }

    .email {
      background: sax(Mail, $cochlear-yellow) no-repeat right;
    }

    a {
      unicode-bidi: plaintext;
    }
  }
  &.contact-us-page {
    .sl {
      .sl-item {
        .callout-card {
          @media only screen and (min-width: 64em) {
            margin-right: 80px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}
