.product-media-banner {
  width: 100%;
  background-color: $cochlear-yellow;
  margin-bottom: 35px;

  @media only screen and (min-width: 64em) {
    height: 480px;
    overflow: hidden;
    margin-bottom: 0;
  }

  .video-modal-cta {
    @include video-play-icon;
    border: 0;
    padding: 0;
    margin: 0;
    display: block;

    @media only screen and (min-width: 64em) {
      height: 480px;
    }
  }

  .l-padding {
    padding: 0;
  }

  .image-banner,
  .video-banner {
    height: 100%;
    overflow: hidden;
    background: {
      repeat: no-repeat;
      size: cover;
      position: center;
    }

    @media only screen and (min-width: 64em) {
      height: auto;
      overflow: hidden;
      margin-top: -100px;
    }

    img {
      width: 100%;
      height: auto;
      display: inherit;
    }
  }

  .modal-link {
    display: block;
  }
}
