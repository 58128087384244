.form-dropdown-box {
  background-color: $light-grey;
  border-left: 5px solid $cochlear-yellow;
  margin: 0 -20px;
  padding: 30px 20px;

  .dropdown {
    display: block;
    background-color: white;
    width: 100%;
    border-radius: 0;
    padding: 10px;
    border-color: #ccc;
    background-image: sax(Chevron, $cochlear-purple);
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 50%;
    font-family: $font-primary;
    -webkit-appearance: none; // stylelint-disable-line
    -moz-appearance: none; // stylelint-disable-line

    &::-ms-expand {
      display: none;
    }
  }

  label {
    font-size: 13px;
    font-weight: bold;
  }

  .details-box {
    h4 {
      padding-top: 20px;
    }

    a {
      display: inline-block;
    }

    span {
      display: block;
      margin-top: 10px;
      padding-left: 40px;
      position: relative;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-size: 100%;
      }

      &.phone {
        color: $cool-grey;
        margin-bottom: 10px;

        &::before {
          background-image: sax(Phone, $cochlear-yellow);
        }
      }

      &.phoneOption {
        padding-left: 5px;
        display: inline;
      }

      &.link {
        &::before {
          background-image: sax(Mail, $cochlear-yellow);
        }
      }

      &.cta-button {
        //float: left;
        margin-top: 0;

        &::hover,
        &::active {
          background-color: #4e03c0;
        }
      }
    }
  }

  a {
    color: $link-colour;
  }
}

// layout
.l-content-column {
  @media screen and (min-width: 64em) {
    &.contact-us {
      max-width: none;
      margin-top: 60px;

      .form-dropdown-box {
        float: left;
        width: 50%;
        margin: 0;
        padding: 30px 40px;

        .dropdown {
          padding: 5px 10px;
        }
      }

      .is-cta {
        width: 40%;
        margin-left: auto;
        margin-right: 0;
        border: 0;
        overflow: hidden;
        margin-top: 50px;
        .image {
          height: 350px;
          position: relative;
          left: 30px;
          display: block;

          img {
            max-width: none;
            height: 100%;
            display: block;
            width: auto;
            border-radius: 0 0 0 50px;
            transform: none;
          }
        }

        .content {
          background-color: $cochlear-yellow;
          opacity: 0.9;
          width: 90%;
          position: absolute;
          top: 50%;
          left: 0;
          height: calc(100% - 60px);
          transform: translateY(-50%);
          border-radius: 0 0 40px 0;

          .cta {
            border: 0;
            width: 170px;
            background-color: #ffffff;
            margin: 29px;
            border-radius: 30px;
            padding: 10px 0 10px 40px;

            &::after {
              left: 123px;
              top: 16px;
            }

            &:hover {
              background-color: $link-colour;
              color: #ffffff;
            }
          }
        }

        .cm-image-block-link {
          min-height: 400px;

          & > a {
            width: 100%;
            padding-right: 0;
            left: 0;
            padding-left: 0;

            & > img {
              position: absolute;
              width: auto;
              border-radius: 0;
              height: 100%;
            }
          }

          img,
          .img {
            padding-left: 20px;
            height: 100%;
            position: absolute;
            width: auto;
          }

          .content {
            left: -2px;
            top: 30px;
            max-width: 308px;
            width: 100%;
            border-radius: 0 0 50px 0;
            height: calc(100% - 60px);
            padding-left: 23px;

            h3 {
              padding: 20px 54px 10px 0;
              font-size: 22px;
            }

            p {
              padding: 0 54px 0 0;
              font-size: 28px;
              min-height: 155px;
            }
          }

          .cta {
            margin: 0;

            &::after {
              display: inline-block;
            }
          }
        }

        &:hover {
          -webkit-transform: perspective(1000px) translate3d(0, 0, 0); // stylelint-disable-line
          transform: perspective(1000px) translate3d(0, 0, 0);

          .cta {
            background-color: $link-colour;
            color: #ffffff;

            &::after {
              background-image: sax(Chevron, white);
            }
          }

          h3 a,
          p {
            color: $link-colour;
          }
        }
      }
    }
  }
}

.contact-form header {
  & + main .form-dropdown-box {
    select#txt-form {
      margin-top: 10px;
    }
  }

  &:not(.is-sticky) + main .form-dropdown-box label[for='txt-form'] {
    height: 14px;
    display: block;
  }

  &.is-sticky {
    // When fixed redirect banner is present, this margin and padding tweak fixes the missing border
    &.has-redirect {
      + main .form-dropdown-box {
        select#txt-form {
          padding: 10px;
          margin-top: 10px;
        }
      }
    }

    + main .form-dropdown-box {
      select#txt-form {
        margin-top: 0;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .form-dropdown-box {
    .dropdown {
      background-position: 5px 50%;
    }
  }
}
