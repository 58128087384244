.product-feature-callout {
  padding: 30px 0;
  background-color: $light-grey;

  @media only screen and (min-width: 64em) {
    padding: 60px 0;
  }

  a {
    text-decoration: none;

    .cta-button {
      @include cta-button-hero-card($cochlear-purple, $brand-white);
      margin: 0;
    }

    &:hover {
      @include cta-button-hero-card-hover($cochlear-purple, $link-colour-hover);
    }
  }

  .l-padding {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 64em) {
      flex-direction: row;
    }
  }

  .img-container {
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 500px;
    }
  }

  .img-container,
  .content-container {
    width: 100%;

    @media only screen and (min-width: 64em) {
      padding-right: 50px;
      width: 50%;
    }
  }

  .content-container {
    margin: 20px 0 40px 0;

    .cta-grouping {
      margin-top: 30px;
    }

    .btn-container {
      margin-top: 30px;

      a {
        margin: 0 10px 10px 0;
        display: inline-block;

        img {
          width: 120px;
          height: auto;
          display: block;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.is-secondary {
    background-color: $brand-white;

    .l-padding {
      @media only screen and (min-width: 64em) {
        flex-direction: row-reverse;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .product-feature-callout {
    a {
      &:hover,
      &:focus,
      &:active {
        .cta-button {
          &::before {
            left: 15px;
          }
        }
      }
      .cta-button {
        border-right: 0;
        text-align: center;
        position: relative;
        display: inline-block;
        margin: -4px 0;
        width: 100%;
        @media only screen and (min-width: 64em) {
          margin: 0 10px 15px 0;
          width: auto;
          min-width: 150px;
          padding: 10px 30px;
        }
        &::before {
          background-image: sax(Chevron, $brand-white);
        }
      }
    }
  }
}
