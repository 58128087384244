$brand-main: #f1be48;
$brand-navy: #384967;
$eggplant: #3f1482;
$yellow-hover: #f4b013;
$radius-rounded: 290486px;
$spacing: 0.5rem;
$spacing3x: 3 * $spacing;
$spacing4x: 4 * $spacing;
$spacing6x: 6 * $spacing;
$spacing8x: 8 * $spacing;

$navbar-height: 3.25rem;
$navbar-border: 5px;

// Authorizer page
// --------------------------------------------

.main--not-authorized {
  min-height: calc(100vh - calc(#{$navbar-height} + 5px));
  // children of parents with min-height can't inherit the height property

  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999 + 1;
  // hide all siblings
  ~ * {
    display: none;
  }
  @media only screen and (min-width: 64em) {
    min-height: 0;
  }
}

header {
  .navbar {
    border-top: $navbar-border solid $brand-main;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    min-height: $navbar-height;
    position: relative;
    z-index: 30;
  }
  .navbar-brand {
    align-items: stretch;
    display: flex;
    flex-shrink: 0;
    min-height: $navbar-height;
    width: 100%;
    > a {
      width: 90px;
      margin: 0 auto;
      padding: 0;
      align-items: center;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      @media only screen and (min-width: 64em) {
        width: 110px;
        min-width: 110px;
        max-width: 110px;
      }
      img {
        height: auto;
        max-width: 100%;
        position: relative;
        right: -10px;
        display: block;
        width: 100%;
        max-height: none;
      }
    }
  }
}

.error-message {
  &--authorizer {
    // buttons
    .sdw__button {
      min-width: 0;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: $spacing6x;
      padding-right: $spacing6x;
      position: relative;
      font-family: $font-circular-medium;
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center;
      border-radius: $radius-rounded;
      display: block;
      border-width: 1px;
      border-style: solid;
      text-decoration: none;
      > span {
        min-width: 9.375rem; /* 150 / 16 */
        display: block;
        margin: 0 auto;
        line-height: 1.555555555555556; /* 28/18 */
      }
      &:hover,
      &:focus {
        outline: none;
      }
      &--has-icon-right {
        padding-left: $spacing6x;
        padding-right: $spacing8x;
        &:before {
          content: none;
        }

        &:after {
          content: '';
          width: $spacing3x;
          height: $spacing3x;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          right: $spacing4x;
          margin: 0;
          transition: right 0.15s ease-in-out;
          background-image: sax(Chevron, $cochlear-purple);
        }
        &:hover,
        &:focus {
          &:after {
            right: $spacing3x;
          }
        }
      }
      &--has-icon-left {
        padding-left: $spacing8x;
        padding-right: $spacing6x;

        &:after {
          content: none;
        }

        &:before {
          content: '';
          width: $spacing3x;
          height: $spacing3x;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(90deg);
          margin: 0;
          transition: left 0.15s ease-in-out;
          left: $spacing4x;
          transition: left 0.15s ease-in-out;
          background-image: sax(Chevron, $cochlear-purple);
        }
        &:hover,
        &:focus {
          &:before {
            left: $spacing3x;
          }
        }
      }

      &--cochlear-yellow {
        background: $brand-main;
        color: $eggplant;
        transition: background-color 0.15s ease-in-out;
        border-color: $brand-main;
        &:hover,
        &:focus {
          background: $yellow-hover;
        }
      }

      &--outline-purple {
        background: #fff;
        color: $eggplant;
        border-color: $eggplant;
        &:hover,
        &:focus {
          padding-top: 11px - 1px;
          padding-bottom: 11px - 1px;
          border: 2px solid $eggplant;
          &.sdw__button--has-icon-right {
            padding-left: $spacing6x - 0.0625rem;
            padding-right: $spacing8x - 0.0625rem;
          }
          &.sdw__button--has-icon-left {
            padding-left: $spacing8x - 0.0625rem;
            padding-right: $spacing6x - 0.0625rem;
          }
        }
      }
      &.has-spinner {
        &.is-submitting {
          padding-right: $spacing8x !important;
        }
        .lds-ring {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: $spacing4x;
          margin: 0 -5px 0 0;
        }
      }
    }

    // padding: 2rem 1rem 2.5rem;
    // display: flex;
    // flex-flow: column nowrap;
    // min-height: 500px;
    width: 100%;
    min-height: 100%; // allows content to grow larger than the screen height if needed
    position: absolute; // add position:absolute
    padding: calc(2rem + #{$navbar-height}) 1rem 2.5rem;
    display: flex;
    flex-flow: column nowrap;
    @media only screen and (min-width: 64em) {
      position: static;
      max-width: 720px;
      min-height: 600px;
      margin: 0 auto;
      padding-top: 5rem;
    }
  }
  &__content {
    padding-bottom: 2rem;
    flex-grow: 1;
  }

  // margins and spacing with multiple
  &__button-control {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 64em) {
      flex-direction: row;
    }
    .sdw__button {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      @media only screen and (min-width: 64em) {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
      }
      &:first-child {
        margin-top: 0;
        @media only screen and (min-width: 64em) {
          margin-left: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
        @media only screen and (min-width: 64em) {
          margin-right: 0;
        }
      }
    }
  }
}

.error-message {
  font-size: 1rem;
  &__image {
    max-width: 120px;
    margin: 0 0 1rem;
    @media only screen and (min-width: 64em) {
      margin-bottom: 2rem;
    }
  }

  &__title {
    color: $brand-navy;
    font: 500 2.125em/1.176470588235294 $font-circular-medium; /* 34/16 ; 40/34 */
    margin: 0 0 0.5rem 0;
    @media only screen and (min-width: 64em) {
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: $brand-navy;
    font: 400 1.125em/1.555555555555556 $font-circular-regular; /* 18/16 */ /* 28/18 */
    a {
      color: $eggplant;
      font-weight: 700;
    }
    &--code {
      margin-top: 2em;
    }
  }
}
