.modal-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: $modal-overlay-background;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 390px;
    height: auto;
    border-bottom-right-radius: 64px;
    margin: auto 20px;
    overflow: hidden;
    background: $brand-white;
    @media only screen and (min-width: 64em) {
      flex-direction: row;
      max-width: 980px;
      margin: 0;
      border-bottom-right-radius: 100px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 588px;
      height: 532px;
      background-image: var(--popUp-bg);
      background-size: cover;
      transform: rotate(90deg);
      transform-origin: 40% 44%;
      z-index: 0;
      @media only screen and (min-width: 64em) {
        width: 100%;
        height: 100%;
        transform: rotate(0deg);
        transform-origin: unset;
      }
    }
    .modal-close-btn {
      position: absolute;
      top: 22px;
      right: 10px;
      width: 30px;
      height: 30px;
      border: 0;
      background: transparent;
      padding-inline: 0;
      padding-block: 0;
      z-index: 2;
      cursor: pointer;
      @media only screen and (min-width: 64em) {
        width: 35px;
        height: 35px;
        top: 26px;
        right: 36px;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 15px;
        top: 45%;
        left: 22%;
        background: $brand-white;
        border-radius: 5px;
        @media only screen and (min-width: 64em) {
          height: 3px;
          width: 26px;
          top: 50%;
          left: 14%;
          background-color: $cochlear-purple;
        }
      }
    }
    .modal-image {
      width: 100%;
      position: relative;
      height: auto;
      z-index: 1;
      padding: 40px 32px 0;
      box-sizing: border-box;
      object-fit: contain;
      @media only screen and (min-width: 64em) {
        padding: 36px 0 80px 56px;
      }
    }
    .modal-text {
      position: relative;
      margin: 40px 0 32px;
      z-index: 0;
      @media only screen and (min-width: 64em) {
        margin: 110px 0 45px;
      }
      .brow {
        position: relative;
        font-family: $font-circular-bold;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        color: $coc-orange;
        padding-left: 22px;
        margin: 0 42px 12px;
        @media only screen and (min-width: 64em) {
          font-size: 22px;
          line-height: 27px;
          margin: 0 76px 60px 62px;
          padding-left: 26px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 188px;
          top: -172px;
          left: 0;
          background: $coc-orange;
          @media only screen and (min-width: 64em) {
            width: 10px;
            top: -164px;
          }
        }
      }
      .title {
        font-family: $font-primary-light;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $cool-grey;
        margin: 0 42px 34px;
        @media only screen and (min-width: 64em) {
          font-size: 32px;
          line-height: 40px;
          margin: 0 76px 42px 62px;
        }
      }
      .next-btn {
        position: relative;
        background: $cochlear-yellow;
        padding: 16px 32px;
        color: $cochlear-purple;
        border-radius: 200px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        font-family: $font-primary-light;
        border: none;
        width: calc(100% - 40px);
        margin: 0 20px;
        cursor: pointer;
        @media only screen and (min-width: 64em) {
          width: unset;
          padding: 16px 60px 16px 32px;
          display: block;
          margin: 0 42px 0 auto;
        }
        &::after {
          content: "";
          position: absolute;
          top: 28%;
          width: 30px;
          height: 25px;
          background-image: sax(Chevron, $cochlear-purple);
          background-size: 100%;
          transform: rotate(-90deg);
          @media only screen and (min-width: 64em) {
            right: 20px;
          }
        }
      }
    }
  }

  .content-validation-popup {
    max-width: 386px;
    @media only screen and (min-width: 64em) {
      max-width: 825px;
    }
    &::before {
      height: 400px;
      transform-origin: 43% 36%;
      @media only screen and (min-width: 64em) {
        width: 270px;
        height: 100%;
        left: 0;
      }
    }
    .modal-close-btn {
      display: none;
    }
    .modal-text {
      margin: 0 auto;
      width: 100%;
      .brow,
      .title {
        margin: 0;
        width: auto;
        margin: 0 20px;
        padding: 0 112px 0 20px;
        @media only screen and (min-width: 64em) {
          margin-left: 260px;
          margin-right: 152px;
          padding-right: 0;
        }
      }
      .brow {
        margin-top: 140px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 14px;
        @media only screen and (min-width: 64em) {
          margin-top: 110px;
          font-size: 22px;
          line-height: 27px;
        }
        &::before {
          top: -82px;
          height: 98px;
          @media only screen and (min-width: 64em) {
            top: -164px;
            height: 188px;
          }
        }
      }
      .title {
        margin-bottom: 40px;
        @media only screen and (min-width: 64em) {
          margin-bottom: 20px;
          padding-left: 0;
        }
      }
      .next-btn {
        margin-bottom: 60px;
        padding: 16px 32px;
        @media only screen and (min-width: 64em) {
          margin-left: auto;
          margin-bottom: 30px;
          width: 100%;
          max-width: 198px;
          text-align: left;
        }
        &::after {
          right: 94px;
          width: 25px;
          @media only screen and (min-width: 26em) {
            right: 118px;
          }
          @media only screen and (min-width: 64em) {
            right: 28px;
          }
        }
      }
    }
  }
}
