// ==========================================================================
// BASE
// ==========================================================================
@mixin transition($speed, $timing: ease-in-out) {
  transition: all $speed $timing;
}

@mixin bp($minWidth, $maxWidth) {
  @if $minWidth == s {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $minWidth == m {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $minWidth == l {
    @media (min-width: 1600px) {
      @content;
    }
  }
}

@mixin bp($min) {
  .cochlear-bp-#{$min} & {
    @content;
  }
}

// ==========================================================================
// ACCESSIBILITY
// ==========================================================================

@mixin mobile-menu-background-visibility() {
  visibility: hidden;
  transition:
    visibility 0.5s linear 0s,
    opacity 0s linear;

  @media only screen and (min-width: 64em) {
    visibility: visible;
  }
}

// Passable mimic of default browser focus styles, to apply to non-anchor elements
// Ref: https://ghinda.net/article/mimic-native-focus-css/
@mixin mimic-default-browser-focus() {
  outline-width: 4px;
  outline-style: solid;
  outline-color: Highlight;

  /* WebKit gets its native focus styles. */
  @media (-webkit-min-device-pixel-ratio: 0) {
    // stylelint-disable-line media-feature-name-no-vendor-prefix
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@mixin chevron($direction, $colour) {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background-image: sax(Chevron, $colour);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate($direction);
  margin-left: 5px;
  margin-top: 6px;
}

@mixin video-play-icon() {
  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: $cochlear-yellow;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &::after {
    top: 50%;
    left: calc(50% + 2px);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid white;
    z-index: 2;
  }
}

@mixin fadeOut($duration, $iteration) {
  animation-name: fadeOutOpacity;
  animation-iteration-count: $iteration;
  animation-timing-function: ease-in;
  animation-duration: $duration;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin fadeIn($duration, $iteration) {
  animation-name: fadeInOpacity;
  animation-iteration-count: $iteration;
  animation-timing-function: ease-in;
  animation-duration: $duration;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin cta-button-hero-card($button-colour, $font-colour) {
  @include transition(0.2s);
  border: 0;
  background-color: $button-colour;
  border-radius: 30px;
  margin: 0 20px 20px 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  color: $font-colour;
  font-size: 18px;
  line-height: 30px;
  display: block;

  @media screen and (min-width: 64em) {
    margin: 0 10px 15px 0;
    width: auto;
    min-width: 150px;
    display: inline-block;
    padding: 10px 30px;
    position: relative;
  }

  &::after {
    margin: -4px 0 0;
    left: 5px;
    top: 5px;
    position: relative;
    display: inline-block;
    background-image: sax(Chevron, $font-colour);
  }

  &:hover {
    &::after {
      left: 10px;
    }
  }
}

@mixin cta-button-hero-card-hover($link, $link-hover) {
  .content-container {
    .cta-button {
      background-color: $link-hover;

      transition: all 0.2s ease-in-out;

      &::after {
        left: 10px;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $link;
  }
}

@mixin cta-button {
  text-align: center;
  width: 100%;
  padding: 15px 27px;
  font-weight: 900;

  @media screen and (min-width: 64em) {
    width: auto;
  }

  &:after {
    margin: -4px 0 0 0;
    left: 5px;
    top: 5px;
    position: relative;
    display: inline-block;
    transition-duration: 0.15s;
  }

  &:hover {
    &:after {
      left: 10px;
    }
  }
}

@mixin cta-button-ni-link {
  width: 100%;
  margin-bottom: 20px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  background: $cochlear-yellow-card;
  color: $link-colour;
  border: 2px solid $cochlear-yellow-card;
  text-decoration: none;
  border-radius: 40px;
  position: relative;
  line-height: initial;
  font-size: 16px;
  font-family: $font-circular-medium;
  padding: 15px 50px 15px 35px;
  transition-duration: 150ms;
  transition-property: color, background-color, border-color;
  &::after {
    right: 20px;
    left: inherit;
    position: absolute;
    top: 25px;
    background-image: sax(Chevron, $cochlear-purple);
    content: " ";
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-size: 100%;
    transform: rotate(-90deg);
    transition-duration: 150ms;
    transition-property: left, right, padding;
  }
  &:hover {
    background: $cochlear-yellow-New;
    border: 2px solid $cochlear-yellow-New;
    &::after {
      right: 10px;
    }
  }
  &.secondary {
    background: $brand-white;
    border: 1px solid $link-colour;
    &:hover {
      background: $brand-white;
      border: 2px solid $link-colour;
      &::after {
        right: 10px;
      }
    }
  }
}

@mixin fullwidth-component {
  width: 100vw;
  position: relative;
  margin-inline: -50vw;
  left: 50%;
  right: 50%;
}

@mixin align-center {
  display: flex;
  align-items: center;
}
