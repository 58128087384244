// ==========================================================================
// COLOURS
// ==========================================================================

$cochlear-lightyellow: #fcefd1;
$cochlear-yellow: #fdc82f;
$cochlear-yellow-New: #ffb000;
$cochlear-yellow-card: #f1be48;
$cochlear-lightorange: #fef1e6;
$cochlear-orange: #fcc89b;
$cochlear-black: #1e1e1e;
$cochlear-purple: #3f1482;
$cochlear-error: #eb0606;
$cochlear-light-green: #e4eae7;
$cochlear-callout-green: #c9d6d0;
$cochlear-lightgrey: #a0a0a1;
$cool-grey: #56565a;
$cool-greylight: #cfcfd0;
$font-darkblue: #384967;
$mid-grey: #cccccc;
$light-grey: #efefef;
$brand-white: #ffffff;
$lighter-grey: #f6f6f6;
$extralighter-grey: #faf9f6;
$link-colour: #3f1482; // Eggplant
$link-colour-hover: #672ac5;
$bg-light-green: #adc1b7;
$bg-dark-green: #92aca0;
$bg-light-grey: #e9e5da;
$bg-light-grey-1: #e1e4e8;
$bg-dark-grey: #d6d6d6;
$bg-light-cool-grey: #efefef;
$honied-white: #fcefd1;
$border-color-grey92: #ebebeb;
$bg-floral-white: #faf9f6;
$card-cool-grey: rgba(214, 214, 214, 0.25);
$lead-magnet: #f5f3ed;
$bg-dark-grey-stakes: rgba(214, 214, 214, 0.5);
$bg-pale-green: #e4eae7;
$bg-yellow-white: #faf9f6;
$bg-deep-honey-yellow: #f4b013;
$bg-question-bubble-grey: #cdcfd9;
$bg-light-white: #e1e4e8;
$bg-light-white-1: #cdcfd9;
$bg-light-yellow: #f8dfa4;
$bg-light-blue: #e9f3f9;
$bg-honey-yellow: #fdf3dc;
$bg-footnotes-grey: #f9f9f9;
$bg-compatibility-textcolumn-yellow: #f8dfa4;
$bg-lighter-grey: #f5f5f5;
$clr-magenta-blue: #682bc6;

// ==========================================================================
// FONTS
// ==========================================================================
$font-header: "BlissPro-Regular", "Trebuchet MS", "Gill Sans", "Helvetica Neue",
  Arial, sans-serif;
$font-primary: "BlissPro-Light", "Trebuchet MS", "Gill Sans", "Helvetica Neue",
  Arial, sans-serif;
$font-primary-bold: "BlissPro-Regular", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-primary-light: "BlissPro-Light", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-special: "Knockout-30", sans-serif;
$font-circular-bold: "Circular-Bold", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-circular-medium: "Circular-Medium", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-circular-regular: "Circular-Regular", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-NotoSans-regular: "NotoSans-Regular", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-NotoSans-bold: "NotoSans-Bold", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-NotoSans-italic: "NotoSans-Italic", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;
$font-NotoSans-boldItalic: "NotoSans-BoldItalic", "Trebuchet MS", "Gill Sans",
  "Helvetica Neue", Arial, sans-serif;

// ==========================================================================
// SCREENS
// ==========================================================================
//TODO: UTILISE THESE SCREEN SIZE VARIABLES
$tablet: "only screen and (min-width: 64em)";

// ==========================================================================
// ANIMATION
// ==========================================================================

//$site-base-transition: 300ms;
//$form-base-transition: 500ms;

// ==========================================================================
// SIZES
// ==========================================================================

// $nav-offscreen-width: 270px;

// ==========================================================================
// FORMS
// ==========================================================================
