.takeways-container {
  @include fullwidth-component;
  font-family: $font-circular-regular;
  color: $font-darkblue;
  justify-content: center;
  padding: 104px 0;

  @media (max-width: 79.9375em) {
    padding: 104px 16px;
  }

  @media (max-width: 47.9375em) {
    padding: 48px 16px;
  }

  &-content {
    display: block;
    margin: 0 auto;
    max-width: 1366px;

    @media screen and (min-width: 48em) {
      align-items: center;
      column-gap: 40px;
      display: flex;
      justify-content: center;
    }

    @media screen and (min-width: 80em) {
      column-gap: 123px;
    }

    &-title-div {
      width: 370px;

      @media (max-width: 79.9375em) {
        width: 100%;
        margin: 0 auto;
      }

      &-title {
        font-size: 36px;
        line-height: 48px;
        color: $font-darkblue;
        font-family: $font-circular-medium;
        font-weight: 500;
        margin-bottom: 24px;

        @media (max-width: 47.9375em) {
          font-size: 32px;
          line-height: 44px;
        }
      }

      &-subtitle {
        font-family: $font-circular-medium;
        color: $font-darkblue;
        font-size: 21px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 8px;

        @media (max-width: 47.9375em) {
          line-height: 32px;
        }
      }

      &-copytext {
        color: $font-darkblue;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;

        @media (max-width: 47.9375em) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &-image-div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 32px;
      max-width: fit-content;

      @media screen and (min-width: 48em) {
        flex-direction: row;
        margin-top: 0;
        width: auto;

        &.left-image {
          flex-direction: row-reverse;
        }
      }

      @media screen and (min-width: 80em) {
        width: 665px;
      }

      img {
        border-radius: 16px 16px 0 0;
        width: 279px;
        height: auto;
        position: relative;
        z-index: -1;

        @media screen and (min-width: 48em) {
          border-radius: 16px 0 0 16px;
          width: 158px;

          [dir="rtl"] & {
            border-radius: 0 16px 16px 0;
          }
        }

        @media screen and (min-width: 80em) {
          width: 295px;
        }
      }

      &-textBlock-container {
        display: flex;
        flex-direction: column;
        background-color: $honied-white;
        border-radius: 16px;
        width: 370px;
        padding: 48px;

        @media (max-width: 79.9375em) {
          width: fit-content;
          padding: 20px;
        }

        @media (max-width: 47.9375em) {
          max-width: 343px;
          width: 100%;
          margin: -10px auto 0;
          text-align: left;
          padding: 24px 32px;
        }

        &-copytext {
          border-bottom: 1px solid $cochlear-orange;
          font-size: 21px;
          line-height: 28px;
          font-weight: 500;
          font-family: $font-circular-medium;
          color: $font-darkblue;
          padding: 32px 0;

          @media (max-width: 47.9375em) {
            padding: 16px 0;
            font-size: 16px;
            line-height: 24px;
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.campaign-break-line {
  border-bottom: 1px solid #92aca0;
  max-width: 1366px;
  margin: 0 auto;
}
