.help-block-container {
  padding: 0;
  font-family: $font-circular-regular;
  width: 100%;
  max-width: 764px;
  margin: 0 auto;

  .model-help-block {
    border-top: 1px solid #a0a0a1;
    border-bottom: 1px solid #a0a0a1;

    p {
      padding-left: 40px;
      position: relative;
      color: #384967;
      font-size: 18px;
      line-height: 28px;
      font-family: $font-circular-regular;
      margin: 16px 0;

      &:first-child {
        &::before {
          background-image: sax(Error, $font-darkblue);
          content: " ";
          width: 24px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }

    a {
      color: $link-colour;

      &:hover {
        color: $link-colour-hover;
      }
    }
  }
}
