.content-anchor-container {
  background-color: $brand-white;

  .content-links-container {
    margin: 60px 0px 0px 0px;
    overflow: hidden;

    .link-container {
      width: 50%;
      float: left;

      @media only screen and (max-width: 64em) {
        width: 100%;
      }

      a {
        width: 290px;
        margin: 3px auto;
        background-color: $cochlear-yellow;
        min-width: 290px;
        max-width: 100%;
        white-space: normal;
        border-radius: 14px;
        font-size: 12px;
        font-weight: bold;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 3px 0;
        color: $cochlear-black;
      }
    }
  }

  .content-item-container {
    background-color: $brand-white;
    margin-top: 40px;

    &.sticky {
      ::before {
        content: '';
        display: block;
        height: 75px;
        margin-top: -75px;
        visibility: hidden;
      }
    }

    ::before {
      content: '';
      display: block;
      height: 125px;
      margin-top: -125px;
      visibility: hidden;
    }

    @media only screen and (max-width: 64em) {
      ::before {
        content: '';
        display: block;
        height: 75px;
        margin-top: -75px;
        visibility: hidden;
      }
    }
  }
}

//rtl-styles
div#root > div[dir='rtl'] {
  .content-anchor-container {
    .content-links-container {
      .link-container {
        float: right;
      }
    }
  }
}
