.ifu-table {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

div.ifu-table-banner {
  font-size: 14px;
  padding: 5px 20px;
  text-align: center;
  border: 4px solid #00b050;
  font-weight: 700;
  a {
    font-weight: 700;
    color: #0563c1;
  }
}

h1.ifu-table-title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.1;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.datatable_wrapper {
  font-size: 14px;
  color: #333;
  .dataTables_length {
    float: left;
    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }
    select {
      width: 48px;
      appearance: auto;
    }
  }
  .datatable_filter {
    float: right;
    text-align: right;
    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 20px;
      font-weight: 700;
    }
    input {
      margin-left: 7px;
      border-width: 2px;
      border-color: rgb(118, 118, 118);
      line-height: 20px;
    }
  }

  table.dataTable {
    table-layout: fixed;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    line-height: 1.4286;

    thead {
      display: table-header-group;
      vertical-align: middle;
      // border-top: 1px solid #111;
      th {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 10px 10px 10px 5px;
        border-bottom: 1px solid #111;
        font-weight: bold;
        font-size: 14px;
        color: #333;
        background-color: white;
        position: relative;
        vertical-align: middle !important;
        cursor: pointer;
        border-left: none;
        border-right: none;
      }
    }

    tbody {
      tr {
        .odd {
          background-color: #f9f9f9;
        }
      }
      td {
        border: none;
        padding: 10px;
        vertical-align: middle !important;
        border-top: 1px solid #ddd;
      }
    }

    tfoot {
      background-color: white;
      th {
        padding: 10px;
        border: none;
        font-weight: bold;
        text-align: left;
        border-top: 1px solid #111;
        .column-search-input {
          width: 100%;
          padding: 3px;
          box-sizing: border-box;
        }
      }
    }
  }

  .summary {
    float: left;
    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }
  }

  .pagation {
    float: right;
    button {
      margin-right: 5px;
      padding: 0px 15px;
    }
  }
  .no-data {
    text-align: center;
    padding: 8px 10px;
    font-size: 20px;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .show {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .loading {
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid #fdc82f;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      -webkit-transform: translate(-50%, -50%);
    }
  }

  .dismiss {
    display: none;
  }
}
