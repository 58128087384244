.top-nav {
  .l-padding {
    display: none;
  }

  @media only screen and (min-width: 64em) {
    width: 100%;
    z-index: 801;
    background-color: $light-grey;
    border-top: 5px solid $cochlear-yellow;

    .l-padding {
      display: block;
    }

    .tab-home {
      background-color: $brand-white;
    }

    .tab-professionals {
      background-color: transparent;
    }

    .nav-selector,
    .type-selector {
      a {
        padding: 14px 28px;
        display: inline-block;
        text-decoration: none;
        line-height: 16px;
        font-size: 15px;
        position: relative;

        &:not(.login) {
          &:hover,
          &:focus {
            background-color: rgba(204, 204, 204, 0.2);
          }
        }
      }
    }

    .type-selector {
      float: left;

      a {
        font-family: $font-primary-bold;
      }
    }

    .nav-selector {
      float: right;

      a {
        padding: 14px;
      }
    }

    a.login {
      background-color: $link-colour;
      color: $brand-white;
      padding: 14px 25px;

      &.hide {
        display: none;
      }

      &:hover {
        color: $brand-white;
        background-color: #4e03c0;
      }
    }
  }
}

.professionals-page {
  .top-nav {
    border-top: 5px solid $link-colour;

    .tab-home {
      background-color: transparent;
    }

    .tab-professionals {
      background-color: $brand-white;
    }
  }
}

.nav-region-dropdown {
  position: relative;
  display: inline-block;
  padding: 0;
  margin-bottom: 80px;
  width: 100%;

  @media only screen and (min-width: 64em) {
    width: auto;
  }

  &.is-open {
    .nav-dropdown-toggle {
      &:after {
        background-image: sax(Chevron, $cochlear-purple);
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    border: 0;
    border-top: 2px solid $cochlear-yellow;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: $brand-white;
    background-clip: padding-box;
    right: 0;
    left: auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .mobile-overlay {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.7);
      left: 0;
      z-index: -2;
      transition:
        visibility 0s 0.3s,
        opacity 0.3s 0.3s linear;
      position: fixed;
      opacity: 1;
      visibility: visible;
      top: 0;
      float: none;
      min-width: auto;
      border: 0;
      right: auto;

      @media only screen and (min-width: 64em) {
        display: none;
      }
    }

    &.open {
      display: block;

      /* position langauage options to the bottom mobile layout */
      @media (max-width: 1023px) {
        display: block;
        top: auto;
        bottom: 60px;
        z-index: 5;
        position: fixed;
        width: 100%;
        border: 0;
        background: transparent;
      }
    }

    .dropdown-items {
      // border-radius: 10px;
      overflow: hidden;
      // margin: 10px;

      @media (max-width: 1023px) {
        background-color: $brand-white;
      }
    }

    .dropdown-item {
      display: flex;
      padding: 13px 18px;
      clear: both;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid $light-grey;
      position: relative;
      text-decoration: none;

      img {
        width: 20px;
        height: 20px;
      }

      .dropdown-item-title {
        padding-left: 10px;
        line-height: 20px;
        display: inline-block;
      }

      &.item-country {
        font-size: 15px;
        font-family: $font-primary-light;
        color: #666;
        width: 100%;

        &.close {
          @media only screen and (min-width: 64em) {
            display: none;
          }

          .dropdown-item-title {
            padding-left: 31px;
          }

          &:after {
            content: ' ';
            left: 20px;
            top: 50%;
            width: 15px;
            height: 15px;
            margin-top: -8px;
            position: absolute;
            background-image: sax(Close, $cool-grey);
            background-size: 100%;
            transform: rotate(0deg);

            @include transition(0.2s);
          }
        }

        &:hover {
          color: $cochlear-purple;
        }
      }
    }
  }

  > .nav-dropdown-toggle {
    padding: 11px 48px 11px 28px;
    color: #666;
    line-height: 20px;
    border: 0;
    position: relative;
    background: transparent;

    @media (max-width: 64em) {
      padding: 11px 48px 11px 20px;
    }

    &:after {
      content: ' ';
      right: 20px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      position: absolute;
      background-image: sax(Chevron, $cool-grey);
      background-size: 100%;
      transform: rotate(0deg);

      @include transition(0.2s);
    }

    &.open {
      /* show mobile black backdrop */
      @media (max-width: 64em) {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        left: 0;
        transition:
          visibility 0s 0.3s,
          opacity 0.3s 0.3s linear;
        position: fixed;
        opacity: 1;
        visibility: visible;
        top: 0;
        float: none;
        min-width: auto;
        border: 0;
        right: auto;
        z-index: 4;

        /* hide button content */
        &:after {
          content: none;
        }

        span {
          display: none;
        }
      }
    }
  }

  // non selectable country / language
  > div.nav-dropdown-toggle {
    padding: 11px 28px 11px 28px;
    @media (max-width: 64em) {
      padding: 11px 20px 11px 20px;
    }
    &:after {
      content: none;
    }
    .nav-region-dropdown__cta {
      cursor: default;
      &:hover {
        color: inherit;
      }
    }
  }

  .nav-region-dropdown__cta {
    font-family: $font-primary-bold;
    cursor: pointer;
    font-size: 15px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 16px;
    text-decoration: none;
    text-align: center;
    margin: 0;
    margin-top: -1px;
    position: relative;
    border: initial;
    background-color: initial;
    padding: 0;

    &:hover {
      color: $link-colour;
    }

    img.region-flag {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-top: -5.5px;
    }
  }

  .nav-region-dropdown__form {
    display: none;
    position: absolute;
    background-color: $brand-white;
    z-index: 901;
  }

  .nav-region-dropdown__label {
    display: block;

    span {
      width: 150px;
      display: inline-block;
    }
  }

  .nav-region-dropdown__select {
    width: 200px;
    padding: 5px;
    border: 1px solid $light-grey;
  }

  .nav-region-dropdown__submit {
    display: block;
    width: 100px;
    text-align: center;
    background-color: #000000;
    color: $brand-white;
    margin-top: 10px;
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .nav-region-dropdown {
    button.nav-dropdown-toggle {
      padding: 11px 28px 11px 48px;

      @media (max-width: 64em) {
        padding: 11px 20px 11px 48px;
      }

      &:after {
        content: ' ';
        right: auto; /*replaced*/
        left: 20px;
      }
    }
    .nav-region-dropdown__cta {
      img.region-flag {
        margin-left: 5px;
        margin-right: 0px;
      }
    }
  }

  // .top-nav {
  //   .l-padding {
  //     @media only screen and (min-width: 64em) {
  //       .type-selector {
  //         float: right;
  //       }

  //       .nav-selector {
  //         float: left;
  //       }

  //     }
  //   }
  // }

  .dropdown-item {
    &.item-country {
      &.close {
        .dropdown-item-title {
          padding-left: 0px;
          padding-right: 31px;
        }

        &:after {
          content: ' ';
          right: 20px;
          left: auto; /*replaced*/
        }
      }
    }
  }

  .dropdown-item-title {
    @media (max-width: 64em) {
      padding-right: 10px;
      padding-left: 0px;
    }
  }
}
