.volunteer-buttons {
  .btn-panel {
    .cta-button {
      margin-top: 20px;

      @include cta-button;
    }

    .cta-button + .cta-button {
      @extend .secondary;

      @media screen and (min-width: 64em) {
        margin-left: 20px;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .volunteer-header {
    .image-container {
      img {
        margin-right: -50%;
        margin-left: 0;
      }
    }
  }
  &.volunteer-buttons {
    .btn-panel {
      .cta-button + .cta-button {
        margin-left: 0px;
        margin-right: 20px;
      }
      .cta-button {
        &:hover {
          &::after {
            left: -10px;
          }
        }
        &::after {
          left: -5px;
        }
      }
    }
  }
}
