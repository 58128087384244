$modal-z: 2147483650 !default;
$modal-background-background-color: rgba(255, 255, 255, 0.95) !default;

$modal-content-width: 700px !default;
$modal-content-margin-mobile: auto !default;
$modal-content-spacing-mobile: 160px !default;
$modal-content-spacing-tablet: 40px !default;

$modal-close-dimensions: 40px !default;
$modal-close-right: 20px !default;
$modal-close-top: 20px !default;

html {
  &.is-clipped {
    overflow: hidden !important;
  }
}

$offset: 0;
.overlay {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

/* -----------------------------
Basic setup for modal
----------------------------- */
.nf-modal {
  @extend .overlay;
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $modal-z;
  // Modifiers
  &.is-active {
    display: flex;
  }
  &__background {
    @extend .overlay;
    background-color: $modal-background-background-color;
  }
  &__content {
    margin: 0 $modal-content-margin-mobile;
    max-height: calc(100vh - #{$modal-content-spacing-mobile});
    // overflow: auto;
    position: relative;
    width: 96%;
    box-shadow: 0px 2px 77px 0px rgba(0, 0, 0, 0.13);
    @media only screen and (min-width: 48em) {
      width: $modal-content-width;
      max-height: calc(100vh - #{$modal-content-spacing-tablet});
      margin: 0 auto;
    }
  }
}

/* -----------------------------
Modal customisations
----------------------------- */
.nf-modal {
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-slide {
    padding-bottom: 50px;
    height: auto;
    > div {
      height: 100%;
    }
  }

  &__content {
    text-align: center;
    border-radius: 10px;
    background: #fff;
  }
  &__close-btn--text {
    border: 0 none;
    background: transparent;
    color: rgb(73, 26, 139);
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 500;
    margin: 0 auto 25px;
    position: relative;
    @media only screen and (min-width: 48em) {
      margin: 0 auto 35px;
    }
    .icon--delete {
      display: inline-block;
      height: 12px;
      width: 12px;
      background-image: sax(Close, $cochlear-purple);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
  }
  &__close-btn {
    background: none;
    border: none;
    border-radius: 290486px;
    cursor: pointer;
    pointer-events: auto;
    display: block;
    font-size: 0;
    position: absolute;
    top: -45px;
    right: 0;
    z-index: 1;
    padding: 0;
    height: 40px;
    width: 40px;
    &:before,
    &:after {
      background-color: #8b8d8e;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
    }
    &:before {
      width: 60%;
      height: 3px;
    }
    &:after {
      height: 60%;
      width: 3px;
    }
    &:hover,
    &:focus {
      background-color: rgba(10, 10, 10, 0.3);
      &:before,
      &:after {
        background-color: #fff;
      }
    }
    @media only screen and (min-width: 64em) {
      right: -43px;
    }
  }
}

/* -----------------------------
Slideshow
----------------------------- */

.slick-slider {
  padding: 2em 1em;
  @media only screen and (min-width: 48em) {
    padding: 2em 1em 1em;
  }
  @media only screen and (max-height: 42em) {
    .slick-list {
      max-height: 24em;
      max-height: calc(100vh - 282px);
      overflow-y: scroll;
    }
  }
  .slick-slide {
    pointer-events: none;
    &.slick-current {
      pointer-events: auto;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  ul {
    &.slick-dots {
      margin: 0;
      padding: 0;
      width: 100%;
      position: absolute;
      bottom: -100px;
      left: 0;
      li {
        margin: 0;
        display: inline-block;
        padding: 0;
        width: 20px;
        height: 8px;
        cursor: pointer;
        position: relative;
        &.slick-active {
          button {
            &:before {
              background-color: #ffcb06;
            }
          }
        }
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 4px;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
          &:before {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -4px;
            width: 8px;
            height: 8px;
            content: '';
            display: block;
            border-radius: 4px;
            background-color: #cdc9c5;
          }
        }
      }
    }
  }
}

/* --------------------------
New feature slides
-----------------------------  */
.slide {
  height: 100%;
  &__figure {
    display: block;
    position: relative;
    width: 140px;
    height: 140px;
    margin: 0 auto;
    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
  &__title {
    color: $cochlear-black;
    font-size: 32px;
    font-family: $font-header;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0px;
    line-height: 40px;
    margin: 0 auto 12px;
    max-width: 480px;
  }
  &__text {
    color: #55565a;
    font-family: $font-primary;
    font-size: 18px;
    letter-spacing: -0.23px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto 1.5em;
    @media only screen and (min-width: 48em) {
      max-width: 432px;
    }
  }
  &__button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 0 none;
    border-radius: 290486px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-family: $font-header;
    font-size: 1.125rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    line-height: 1.166666666666667;
    padding: 14px 25px 15px 30px;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    min-width: 246px;
    outline: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    &.no-icon {
      padding: 14px 25px 15px 25px;
      &:after {
        content: none;
      }
    }
    @media only screen and (max-width: 47.9375em) {
      display: block;
      width: 100%;
      min-width: none;
      left: 0;
      transform: none;
    }
    &:after {
      transition: left 0.15s ease-in-out;
      content: '';
      transform: rotate(-90deg);
      width: 24px;
      height: 24px;
      position: relative;
      display: inline-block;
      margin: -7px 0 0 0;
      top: 6px;
      left: 0;
      background-image: sax(Chevron, #ffffff);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &:hover,
    &:focus {
      &:after {
        left: 6px;
      }
    }
    &--primary {
      background-color: $cochlear-yellow;
      border-color: $cochlear-yellow;
      color: $cochlear-black;
      &:hover,
      &:focus {
        background-color: $cochlear-yellow-New;
      }
      &:after {
        background-image: sax(Chevron, $cochlear-purple);
      }
    }
  }
}
