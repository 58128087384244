.cardSlider-container {
  padding: 104px 20px 190px;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  width: 100vw;
  justify-content: center;
  @media only screen and (max-width: 47.9375em) {
    padding: 48px 20px 200px;
  }

  &-title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;
    max-width: 1158px;
    margin: 0 auto;
    @media only screen and (max-width: 47.9375em) {
      display: block;
      padding-bottom: 32px;
    }

    p {
      margin: 0;
    }

    &-title {
      color: $font-darkblue;
      font-size: 36px;
      line-height: 48px;
      max-width: 600px;
      font-family: $font-circular-medium;
      font-weight: 500;
      @media only screen and (max-width: 47.9375em) {
        font-size: 32px;
        line-height: 44px;
      }
    }

    &-link-text {
      text-decoration: none;
      color: $link-colour;
      font-size: 18px;
      line-height: 28px;
      font-family: $font-circular-medium;
      @media only screen and (max-width: 47.9375em) {
        position: absolute;
        bottom: 48px;
      }
    }
  }

  &-card-slider {
    position: relative;
    max-width: 1158px;
    margin: 0 auto;
    @media only screen and (max-width: 47.9375em) {
      max-width: 310px;
      margin: 0;
    }

    .react-multi-carousel-list {
      overflow: visible;
    }

    &-scroll-container {
      display: flex;
      scroll-snap-type: x mandatory;
      list-style-type: none;
      padding: 0;

      .react-multi-carousel-track {
        li {
          max-width: 370px !important;
          height: 586px;
          border: 1px solid $cool-greylight;
          margin-right: 24px;
          position: relative;
          margin-top: 0;
          @media only screen and (max-width: 47.9375em) {
            max-width: 285px !important;
            height: 484px;
          }
        }
      }

      &-card-item {
        img {
          transition: all ease-in-out 0.5s;
        }

        &-image-box {
          max-width: 370px;
          height: 272px;
          left: 0;
          top: 0;
          overflow: hidden;
          position: relative;
          @media only screen and (max-width: 47.9375em) {
            max-width: 284px !important;
            height: 209px;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
          }

          .video-modal-cta {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include video-play-icon;

            border: 0;
            padding: 0;
            margin: 0;
            display: block;

            &::after {
              left: calc(50% + 5px);
              border-top: 15px solid transparent;
              border-bottom: 15px solid transparent;
              border-left: 25px solid $brand-white;
            }
          }
        }

        &-info-box {
          padding: 32px;
          font-family: $font-circular-medium;
          @media only screen and (max-width: 47.9375em) {
            padding: 24px;
          }
          &-title-text,
          &-user-text {
            font-size: 18px;
            line-height: 28px;
            font-family: inherit;
            color: $font-darkblue;
            margin-bottom: 12px;
            @media only screen and (max-width: 47.9375em) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          &-info-text {
            height: 130px;
            color: $font-darkblue;
            @media only screen and (max-width: 47.9375em) {
              max-width: 230px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              white-space: normal;
              height: 112px;
            }
          }

          &-user-text {
            margin-top: 54px;
            display: block;
            text-decoration: none;
            color: $link-colour;
          }
        }

        &:hover {
          img {
            transform: scale(1.1);
            transition: transform ease-in-out 0.5s;
          }
        }
      }
    }

    .react-multiple-carousel__arrow {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $cochlear-yellow-card;
      border: none;
      bottom: -90px;

      &:hover {
        background: $cochlear-yellow-card;
      }

      &.disabled {
        background: $bg-dark-grey;
        z-index: -1;

        &::before {
          opacity: 0.5;
        }
      }

      &.react-multiple-carousel__arrow--right,
      &.react-multiple-carousel__arrow--left {
        left: calc(40% + 1px);
        @media only screen and (max-width: 79.9375em) {
          left: calc(35% + 1px);
        }
        @media only screen and (max-width: 47.9375em) {
          left: calc(10% + 1px);
        }

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgBnZM9DsIwDIXtpAtMILUzcJG2nIRyArhBfzgA3ADYGDlCjsGYnQUWmFoTVQKpookaPymSFft9kmVbgkNxuMsXo2Sq3+pmqxG2RBJVRwQqCOUGHApsZiDIzNNY12sXAN3mZqkehR4M8DV3ABzzD8A1t4A4rPaGsgWGCKg0Y6QnMCUQJm0LaVSeiHDFauEbcCGdMXIgf4vkC8G+Tx9I7zGpe54h0tng502AB3BI2hL6pa6zcYoC6GJibav7ACejhNME9NiHAAAAAElFTkSuQmCC');
          width: 16px;
          height: 16px;
        }

        &.react-multiple-carousel__arrow--right {
          right: calc(40% + 1px);
          left: unset;

          @media only screen and (max-width: 79.9375em) {
            right: calc(35% + 1px);
          }

          @media only screen and (max-width: 47.9375em) {
            right: calc(10% + 1px);
          }

          &::before {
            transform: rotate(180deg);
            top: 33%;
            left: 37%;
          }
        }
      }
    }

    .react-multi-carousel-dot-list {
      bottom: -75px;

      li {
        margin-top: 0;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &.react-multi-carousel-dot {
          button {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            opacity: 1;
            padding: 5px;
            box-shadow: none;
            background: $bg-dark-grey;
            border: none;
            margin: 0;
            margin-right: 6px;
            outline: 0;
            cursor: pointer;
          }

          &.react-multi-carousel-dot--active {
            button {
              background: $cochlear-purple;
              border: none;
            }
          }
        }
      }
    }

    .slider-controls {
      display: flex;
      justify-content: center;
      align-items: center;

      .slider-dots {
        display: flex;
        list-style: none;
        padding: 0 32px;

        li {
          margin-top: 0;

          &:not(:last-child) {
            margin-right: 8px;
          }
        }

        span {
          position: relative;
          margin-top: 0;

          &::after {
            content: '';
            display: flex;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $bg-dark-grey;
          }

          &.active {
            &::after {
              background-color: $cochlear-purple;
            }
          }
        }
      }

      &-button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $cochlear-yellow-card;
        border: none;

        &.prev,
        &.next {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgBnZM9DsIwDIXtpAtMILUzcJG2nIRyArhBfzgA3ADYGDlCjsGYnQUWmFoTVQKpookaPymSFft9kmVbgkNxuMsXo2Sq3+pmqxG2RBJVRwQqCOUGHApsZiDIzNNY12sXAN3mZqkehR4M8DV3ABzzD8A1t4A4rPaGsgWGCKg0Y6QnMCUQJm0LaVSeiHDFauEbcCGdMXIgf4vkC8G+Tx9I7zGpe54h0tng502AB3BI2hL6pa6zcYoC6GJibav7ACejhNME9NiHAAAAAElFTkSuQmCC');
            width: 16px;
            height: 16px;
          }
        }

        &.next {
          &::before {
            transform: rotate(180deg);
            top: 34%;
            left: 35%;
          }
        }
      }
    }
  }
}
