@import "../../../../styles/ifu-shared.scss";

.ccl__ifu-search {
  margin-bottom: 40px;
  width: 100%;
  font-family: $font-circular-regular;

  &--tips {
    font-size: 18px;
    line-height: 28px;
    color: $color-base-brandxtra-brandnavy;
    margin-bottom: 24px;
    margin-top: 49px;
    font-family: $font-circular-regular;

    p,
    span,
    strong,
    a {
      font-family: $font-circular-regular;
    }
  }

  &--search-box {
    .ccl__form-input__label,
    .ccl__form-typeahead__label {
      font-size: 16px;
      line-height: 24px;
    }

    .ccl__select-input-country {
      .ccl__select-input__select__menu {
        min-width: 100%;
      }
    }

    .ccl__select-input {
      .ccl__select-input__select__control,
      .ccl__select-input__dropdown {
        width: 100%;
        padding: 7px 8px;
        height: 44px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .ccl__form-typeahead--container {
      .ccl__form-typeahead--is-input {
        padding-top: 7px;
        padding-bottom: 7px;
        height: 44px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &--noResultTips {
    font-size: 16px;
    line-height: 24px;
    color: $color-base-brandxtra-brandnavy;
    font-weight: 400;
    font-family: $font-circular-regular;
    margin-top: 12px;
  }
}
