.question-wrapper {
  .question-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: $screen-width-small;
    background-color: $cochlear-yellow;
    padding: 20px 7px 34px;
    @media only screen and (min-width: 64em) {
      padding: 54px 0 36px;
      border-radius: 0 0 48px 0;
    }
    .question-number {
      display: flex;
      justify-content: space-between;
      .btns {
        border: none;
        background: transparent;
      }
      .previous,
      .next {
        position: relative;
        padding: 0;
        &::before {
          content: "";
          position: absolute;
          top: -5px;
          width: 40px;
          height: 40px;
          background-image: sax(Chevron, $cochlear-purple);
          background-size: 100%;
        }
      }
      .previous {
        &::before {
          left: -2px;
          transform: rotate(-270deg);
          @media only screen and (min-width: 64em) {
            left: -12px;
          }
        }
      }
      .next {
        &::before {
          right: -2px;
          transform: rotate(-90deg);
          @media only screen and (min-width: 64em) {
            right: -12px;
          }
        }
      }
      .question-title {
        color: $cool-grey;
        text-align: center;
        font-family: $font-header;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        margin: 0;
        @media only screen and (min-width: 64em) {
          font-size: 32px;
          line-height: 38.21px;
        }
      }
    }
    .progress-bar {
      display: flex;
      padding: 0 13px;
      .bar {
        width: 100%;
        background-color: $bg-light-cool-grey;
        border-radius: 24px;
        height: 12px;
        overflow: hidden;
        border: 1px solid $cochlear-purple;
        @media only screen and (min-width: 64em) {
          border-radius: 50px;
          height: 16px;
        }
        .progress {
          background-color: $cochlear-purple;
          height: 100%;
          border-radius: 0;
          transition: width 0.3s ease;
          @media only screen and (min-width: 64em) {
            height: 16px;
          }
        }
      }
    }
    .question-number,
    .progress-bar {
      @media only screen and (min-width: 64em) {
        width: $screen-width-medium;
        margin: auto;
        padding: 0 36px;
      }
      @media only screen and (min-width: 77.75em) {
        width: $screen-width-large;
      }
    }
  }
  .quiz-question {
    margin: auto;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 64em) {
      width: $screen-width-medium;
      padding: 66px 36px;
      flex-direction: row;
      gap: 70px;
    }
    @media only screen and (min-width: 77.75em) {
      width: $screen-width-large;
    }
    .qna-section {
      flex: 1;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      order: 2;
      padding: 32px 20px;
      gap: 32px;
      @media only screen and (min-width: 64em) {
        max-width: 50%;
        order: 1;
        padding: 0;
      }
      .question {
        color: $color-charcoal;
        font-family: $font-header;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 0;
        @media only screen and (min-width: 64em) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .cta-answers {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media only screen and (min-width: 64em) {
          gap: 20px;
        }
        .answer {
          display: flex;
          border: 1px solid $bg-dark-grey;
          border-left: 5px solid $cochlear-yellow;
          color: $cochlear-purple;
          font-family: $font-header;
          font-size: 16px;
          font-weight: 700;
          padding: 18.5px 57px 18.5px 20px;
          line-height: 19px;
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            right: 20px;
            top: 35%;
            width: 20px;
            height: 20px;
            background-image: sax(Chevron, $cochlear-purple);
            transform: rotate(-90deg);
          }
          @media only screen and (min-width: 64em) {
            display: block;
            font-size: 18px;
            padding: 20px 154px 20px 34px;
            line-height: 21px;
          }
        }
        .selected, .answer:active {
          background: #3f1482;
          color: white;
          &::after {
            background-image: sax(Chevron, $brand-white);
          }
        }
      }
    }
    .media-section {
      flex: 1;
      max-width: 100%;
      order: 1;
      .main-image {
        width: 100%;
        display: block;
        border-radius: 0 0 64px 0;
        @media only screen and (min-width: 64em) {
          border-radius: 2px 2px 100px 2px;
        }
      }
    }
  }
}