.form-box {
  label {
    &.invalid {
      color: $cochlear-error;
    }
  }

  span {
    &.error {
      font-weight: bold;
    }
  }

  .is-error {
    textarea,
    input,
    span.radiopoint,
    select,
    span.checkmark {
      border-color: $cochlear-error;
    }

    label,
    .radio-question {
      margin-bottom: 0;
      color: $cochlear-error;

      span {
        color: $cochlear-error;
      }

      &:hover {
        color: $cochlear-error;
      }
    }

    .date-input {
      input {
        background-image: none;
      }
    }

    .sl-list.date {
      .date-input.year {
        input {
          background-image: sax(Error);
          background-repeat: no-repeat;
          background-position-x: calc(100% - 6px);
          background-position-y: center;
          background-size: 20px;
        }
      }
    }

    input {
      background-image: sax(Error);
      background-repeat: no-repeat;
      background-position-x: calc(100% - 6px);
      background-position-y: center;
      background-size: 20px;
    }

    label.radio-container.label-normal {
      font-weight: normal;
    }

    label.check-container,
    label.radio-container {
      & + .error {
        padding-left: 30px;
        display: block;
        position: relative;

        &::before {
          content: '';
          width: 20px;
          height: 20px;
          background-image: sax(Error);
          background-size: 100%;
          position: absolute;
          top: 5px;
          left: 0;
        }
      }
    }
  }

  .error,
  .status-msg__message {
    color: #eb0606;
    position: relative;
    display: block;
    margin-top: 10px;
  }

  .checkmark {
    top: -1px;
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  // Datepicker
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-top: 0;
    border-bottom-color: #f0f0f0;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: 0;
    border-top-color: #fff;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
  }

  .react-datepicker {
    font-family: $font-primary;
    font-size: 14px;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 10px;
  }

  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 10px;
  }

  .react-datepicker-popper[data-placement^='right'] {
    margin-left: 8px;
  }

  .react-datepicker-popper[data-placement^='right']
    .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }

  .react-datepicker-popper[data-placement^='left'] {
    margin-right: 8px;
  }

  .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
  }

  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 16px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
  }

  .react-datepicker__navigation--previous {
    left: 5px;
    border-right-color: #ccc;
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__navigation--next {
    right: 5px;
    border-left-color: #ccc;
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 80px;
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
  }

  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }

  .react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }

  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
  }

  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 30px;
    width: 100%;
    box-sizing: content-box;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 5px 10px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: $link-colour;
    color: white;
    font-weight: bold;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: $link-colour;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__day {
    cursor: pointer;
    font-family: $font-primary;
  }

  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
  }

  .react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
  }

  .react-datepicker__day--highlighted-custom-1 {
    color: magenta;
  }

  .react-datepicker__day--highlighted-custom-2 {
    color: green;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: $link-colour;
    color: #fff;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: $link-colour;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: #1d5d90;
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range
    ) {
    background-color: rgba(33, 107, 165, 0.5);
  }

  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range
    ) {
    background-color: #f0f0f0;
    color: #000;
  }

  .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
  }

  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
  }

  .react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 12px;
    position: relative;
    border-width: 5px;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
  }

  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
  }

  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc;
  }

  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
  }

  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
  }

  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
  }

  .react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
  }

  .react-datepicker__close-icon::after {
    background-color: $link-colour;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }

  .react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
  }

  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
  }

  .react-datepicker__portal .react-datepicker__navigation--previous--disabled,
  .react-datepicker__portal
    .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }

  .react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
  }

  .react-datepicker__portal .react-datepicker__navigation--next--disabled,
  .react-datepicker__portal
    .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__day.react-datepicker__day--outside-month {
    color: $light-grey;
  }

  .date-input-lineup {
    // useful when lining up the datepicker input size with select dropdowns which are larger
    @media screen and (min-width: 64em) {
      padding: 15px 10px 16px;
    }
  }

  select.react-datepicker__month-select {
    font-family: $font-primary;
  }
}

/* Create a custom radio button */
.radiopoint {
  position: absolute;
  top: 2px;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px $mid-grey;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &::after {
    content: '';
    position: absolute;
    display: none;
  }
}

/* Customize the label (the container) */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;

    &:checked {
      & ~ .checkmark {
        border: 1px solid $link-colour;

        &::after {
          /* Show the indicator (dot/circle) when checked */
          display: block;
        }
      }
    }

    &:focus {
      & ~ .checkmark {
        @include mimic-default-browser-focus;
        border: 1px solid $link-colour;
      }
    }
  }

  .checkmark {
    /* Style the indicator (dot/circle) */
    &::after {
      left: 8px;
      top: 1.5px;
      width: 6px;
      height: 16px;
      border: solid $link-colour;
      border-width: 0 2px 2px 0;
      transform: rotate(35deg);
    }
  }

  &:focus {
    color: $link-colour !important;
  }

  &:hover {
    color: $link-colour !important;

    input {
      & ~ .checkmark {
        border: 1px solid $link-colour;
      }
    }
  }
}

.radio-container {
  input:focus ~ .radiopoint {
    @include mimic-default-browser-focus;
  }
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #fff;
  border: solid 1px $mid-grey;

  &:focus {
    border: solid 2px $link-colour !important;
  }

  &::after {
    /* Create the indicator (the dot/circle - hidden when not checked) */
    content: '';
    position: absolute;
    display: none;
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .radiopoint {
    right: 0;
    left: auto; /*replaced*/
  }

  .is-error {
    .sl-list.date {
      .date-input.year {
        input {
          background-position-x: 6px;
        }
      }
    }

    input {
      background-position-x: 6px;
    }
  }

  .check-container {
    padding-left: 0;
    padding-right: 35px;

    .checkmark {
      left: auto; /*replaced*/
      right: 0;

      &::after {
        left: auto; /*replaced*/
        right: 8px;
      }
    }
  }

  label.check-container,
  label.radio-container {
    & + .error {
      padding-right: 30px;
      padding-left: 0;

      &::before {
        right: 0;
        left: auto; /*replaced*/
      }
    }
  }
}
