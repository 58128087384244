.global-header {
  .search-box {
    background-color: $cochlear-yellow;
    z-index: 3;
    padding-top: 35px;
    padding-bottom: 35px;
    position: fixed;
    top: 0;
    width: 100%;

    @media only screen and (min-width: 64em) {
      transition: 0.3s;
      position: relative;
    }

    .l-padding {
      height: auto;
      padding: 0 20px;

      @media only screen and (min-width: 64em) {
        padding: 0 36px;
      }
    }

    form {
      display: flex;
    }

    .underline {
      font-size: 30px;
      height: 3px;
      overflow: hidden;
      position: absolute;
      bottom: -1px;
      border-bottom: 3px solid $cochlear-purple;
      left: 20px;

      @media only screen and (min-width: 64em) {
        font-size: 50px;
        left: 35px;
      }
    }

    .search-input {
      line-height: 30px;
      font-size: 30px;
      width: 100%;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid rgba(158, 158, 158, 0.23);
      color: $cool-grey;
      font-family: $font-primary;
      outline: 0;
      -webkit-appearance: none;
      border-radius: 0;
      padding-left: 0;

      @media only screen and (min-width: 64em) {
        line-height: 50px;
        font-size: 50px;
      }
    }

    .search-trigger {
      width: 40px;
      height: 40px;
      border: 0;
      display: block;
      background: sax(Search, $cool-grey) no-repeat center center;
      background-size: 30px;

      &:focus,
      &:hover {
        background: sax(Search, $cochlear-purple) no-repeat center center;
        background-size: 30px;
      }
    }
  }
}
