.child-listing {
  padding-bottom: 20px;

  .child-listing__wrap {
    @media only screen and (min-width: 64em) {
      width: 100%;
      max-width: 1180px;
      margin: 0 auto;
    }
  }

  @media only screen and (min-width: 64em) {
    // padding: 40px 0;
    width: 100%;
  }

  .image {
    @media only screen and (min-width: 64em) {
      float: right;
      width: 55%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    margin-top: 0;
    width: 95%;

    @media only screen and (min-width: 64em) {
      float: left;
      padding: 0 80px 0 0;
      width: 45%;
      margin-top: 0;
    }

    .title {
      border-left: 10px solid $cochlear-yellow;
      padding-left: 30px;
      padding-right: 14px;
      position: relative;
      margin-top: -50px;
      margin-bottom: 20px;

      @media only screen and (min-width: 64em) {
        padding-right: 0;
        margin-top: 0;
      }

      a {
        text-decoration: none;

        h2 {
          padding-top: 15px;
          padding-right: 30px;
          position: relative;
          font-size: 28px;

          @media only screen and (min-width: 64em) {
            padding-top: 5px;
          }

          &::after {
            @include transition(0.2s);
            content: '';
            background-image: sax(Chevron, $cochlear-purple);
            position: absolute;
            background-size: 100%;
            width: 30px;
            height: 30px;
            right: 10px;
            top: 50%;
            transform: rotate(-90deg) translateX(50%) translateY(50%);

            @media only screen and (min-width: 64em) {
              right: 23px;
            }
          }
        }
      }

      p {
        padding-right: 30px;
      }

      &:hover {
        h2 {
          color: $link-colour;

          &::after {
            right: 15px;
          }
        }
      }
    }

    .cta {
      @media only screen and (min-width: 64em) {
        margin-left: 50px;
      }
    }
  }

  ul {
    border: none;
    padding: 0 0 0 40px;
    list-style: none;
    margin: 0;

    li {
      border-bottom: 1px solid $mid-grey;
      margin: 0;
      position: relative;

      &::after {
        content: '';
        background-image: sax(Chevron, $cochlear-purple);
        position: absolute;
        background-size: 100%;
        width: 20px;
        height: 20px;
        right: 0;
        top: 50%;
        transform: rotate(-90deg) translateX(50%) translateY(-14px);

        // @media only screen and (min-width: 64em) {
        // 	right: 23px;
        // }
      }

      a {
        padding: 16px 36px 16px 0;
        text-decoration: none;
        line-height: 28px;
        font-size: 18px;
        border-color: transparent;
        display: block;

        &:hover,
        &:focus {
          background-color: rgba(204, 204, 204, 0.2);
          border-left: 5px solid $cochlear-yellow;
          margin-left: -30px;
          padding-left: 25px;

          &::after {
            transform: rotate(-90deg) translateX(50%) translateY(-5px);
          }
        }
      }
    }
  }
}

/* ALTERNATE COLOURS - ONLY THE FIRST IS GREY */
.article > .child-listing {
  background-color: $light-grey;

  @media only screen and (min-width: 64em) {
    padding: 40px 0;
  }

  .content {
    background-color: $light-grey;

    .title {
      background-color: $light-grey;
    }
  }
}
.article > .child-listing ~ .child-listing {
  background-color: $brand-white;

  @media only screen and (min-width: 64em) {
    padding: 60px 0 20px 0;
  }

  .content {
    background-color: $brand-white;

    .title {
      background-color: $brand-white;
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .child-listing {
    .image {
      @media only screen and (min-width: 64em) {
        float: left;
      }
    }
    .content {
      @media only screen and (min-width: 64em) {
        padding: 0 0 0 80px;
        float: right;
      }
      .title {
        border-left: initial;
        border-right: 10px solid $cochlear-yellow;
        padding-right: 30px;
        @media only screen and (min-width: 64em) {
          padding-left: 0;
        }

        &:hover {
          h2 {
            &::after {
              left: 0px;
              right: auto; /*replaced*/
              @media screen and (min-width: 64em) {
                left: 15px;
              }
            }
          }
        }
        a {
          h2 {
            padding-right: initial;
            padding-left: 30px;

            &::after {
              left: 5px;
              right: auto; /*replaced*/
              transform: rotate(90deg) translateX(-50%) translateY(50%);

              @media only screen and (min-width: 64em) {
                right: auto; /*replaced*/
                left: 23px;
              }
            }
          }
        }
      }
    }
    ul {
      padding: 0 40px 0 0;
      li {
        a {
          padding: 16px 0 16px 36px;

          &:hover,
          &:focus {
            background-color: rgba(204, 204, 204, 0.2);
            border-right: 5px solid $cochlear-yellow;
            border-left: none;
            margin-left: initial;
            padding-left: 36px;
            margin-right: -30px;
            padding-right: 25px;
          }
        }

        &::after {
          right: auto; /*replaced*/
          left: 0;
          top: 50%;
          transform: rotate(90deg) translateX(-50%) translateY(-14px);

          //   @media only screen and (min-width: 64em) {
          //     left: 23px;
          //   }
        }
      }
    }
  }
}
