.message-banner {
  padding: 15px;
  background-color: $cochlear-black;
  display: block;

  @media screen and (min-width: 64em) {
    padding: 10px 0;
  }

  div {
    color: $brand-white;
    text-align: left;

    @media screen and (min-width: 64em) {
      text-align: center;
    }

    a {
      color: $cochlear-yellow;
      display: inline;
    }
  }
}
