.compatibility-text-columns {
  background-color: $bg-compatibility-textcolumn-yellow;
  border-radius: 0 0 40px;
  padding: 32px 8px;

  @media only screen and (min-width: 48em) {
    padding: 40px 0 48px;
  }

  .compatibility-text-columns-title {
    font-family: $font-circular-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 16px;
    color: $font-darkblue;

    @media only screen and (max-width: 47.99em) {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 44px;
    }
  }

  .compatibility-text-columns-content {
    display: flex;
    flex-direction: row;

    .compatibility-text-columns-subtitle {
      font-family: $font-circular-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      max-width: 370px;
      color: $font-darkblue;
      @media only screen and (max-width: 48em) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .compatibility-text-columns-details {
      div {
        ul {
          padding-left: 0;
          padding-inline-start: 0;
          padding-top: 8px;

          @media only screen and (min-width: 48em) {
            margin-inline-start: 24px;
            padding-top: 0;
          }

          li {
            font-family: $font-circular-regular;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            max-width: 370px;
            color: $font-darkblue;
            list-style-type: none;
            position: relative;
            margin-top: 0;

            &:not(:last-child) {
              margin-bottom: 4px;
              margin-top: 0;
            }

            &::before {
              content: "";
              width: 7px;
              height: 7px;
              background-color: $cochlear-black;
              border-radius: 100%;
              display: inline-block;
              margin-inline-end: 12px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 48em) {
      flex-direction: column;
    }
  }
}
