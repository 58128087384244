.breakout {
  margin: 30px 0 20px 0;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  background-color: $light-grey;

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  &::before {
    content: '';
    position: absolute;
    width: 999%;
    height: 100%;
    left: -999%;
    background-color: $light-grey;
    z-index: -1;
    margin-top: -40px;
  }

  h2 {
    border-bottom: 5px solid $cochlear-yellow;
    padding: 0 20px 20px 0;
    margin-bottom: 20px;

    @media only screen and (min-width: 64em) {
      padding-right: 50px;
    }
  }

  p {
    padding-right: 20px;

    @media only screen and (min-width: 64em) {
      padding-right: 50px;
    }
  }

  li {
    padding-right: 30px;

    @media only screen and (min-width: 64em) {
      margin-right: 60px;
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .breakout {
    &::before {
      left: auto; /*replaced*/
      right: -999%;
    }
    h2 {
      padding: 0 0 20px 20px;

      @media screen and (min-width: 64em) {
        padding-right: 0px;
        padding-left: 50px;
      }
    }
    p {
      padding-right: 0;
      padding-left: 20px;

      @media screen and (min-width: 64em) {
        padding-right: 0px;
        padding-left: 50px;
      }
    }
  }
}
