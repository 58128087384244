.breadcrumbs {
  display: none;
  font-size: 16px;
  margin: 20px auto 60px auto;

  @media only screen and (min-width: 64em) {
    display: block;
  }

  ul {
    li {
      .is-active {
        font-size: 16px;
      }

      &::before {
        content: '/';
        left: 5px;
        top: 0;
        margin-top: 0;
      }
    }
  }

  a {
    color: $link-colour;
    font-size: 16px;

    &:hover,
    &:focus {
      color: $link-colour;
    }
  }
}

div#root > div[dir='rtl'] {
  nav.breadcrumbs {
    ul {
      li {
        padding-left: 0;
        padding-right: 25px;
        margin: 0 0 0 5px;

        &:first-child {
          margin-left: 5px;
          margin-right: 0;
        }

        &:before {
          left: 0;
          right: 5px;
        }
      }
    }
  }
}
