.testimonial {
  @media only screen and (max-width: 64em) {
    background-image: none !important;
  }
  padding: 80px 0;
  .testimonial-content {
    background: $brand-white;
    &.no-quotes {
      .title {
        h2 {
          &::before {
            content: '';
          }
          &::after {
            content: '';
          }
        }
      }
    }
    .title {
      padding: 140px 0 40px;
      h2 {
        font-size: 28px;
        color: $font-darkblue;
        text-align: center;
        font-style: oblique;
        position: inherit;
        margin-bottom: 0;
        padding: 0 18px;
        font-weight: 500;
        letter-spacing: -0.03px;
        line-height: 40px;
        &::before {
          content: '“';
          position: inherit;
          left: inherit;
        }
        &::after {
          content: '”';
        }
      }
    }

    .people-intro {
      text-align: center;
      &.mb-show {
        display: block;
        top: -31px;
        position: absolute;
        width: calc(100% - 40px);
      }
      &.pc-show {
        display: none;
      }
      img {
        border-radius: 31px;
        width: 62px;
        height: 62px;
        display: block;
        margin: 0 auto 20px;
      }
      .details {
        display: block;
        p {
          font-size: 18px;
          color: $font-darkblue;
          text-align: center;
          letter-spacing: -0.03px;
          font-weight: normal;
          line-height: 28px;
          &.name {
            margin-bottom: 0;
          }
          &.intro {
            font-size: 16px;
            margin-top: 0;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 64em) {
    padding: 120px 0;
    background-position: 100% center;
    background-size: cover;
    .testimonial-content {
      background: $brand-white;
      .title {
        padding: 60px 0 55px;
        margin: 0 120px;
        h2 {
          font-size: 34px;
          line-height: 45px;
          position: relative;
          padding: 0;
          &::before {
            content: '“';
          }
        }
      }
      .people-intro {
        padding: 0 0 40px 0;
        &.mb-show {
          display: none;
        }
        &.pc-show {
          display: block;
        }
        img {
          display: inline-block;
          margin: 0 20px 0 0;
        }
        .details {
          display: inline-block;
          vertical-align: top;
          p {
            text-align: left;
            &.name {
              margin-bottom: 5px;
            }
            &.intro {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .testimonial {
    .testimonial-content {
      &.no-quotes {
        .title {
          h2 {
            &::before {
              content: '';
            }

            &::after {
              content: '';
            }
          }
        }
      }
      .title {
        h2 {
          &::before {
            content: '”';
          }
          &::after {
            content: '“';
          }
        }
      }
    }
    @media only screen and (min-width: 64em) {
      .testimonial-content {
        .title {
          h2 {
            &::before {
              position: inherit;
              left: inherit;
            }
          }
        }
        .people-intro {
          img {
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }
}
