$tablet: 'only screen and (min-width: 64em)';
$quiz-page-margin: 20px;

// Colors
$color-purple: #3f1482;
$color-grey-light: #cccccc;
$color-grey-lighter: #efefef;
$color-black: rgba(30, 30, 30, 0.95);

// Mixins
@mixin icon-chevron {
  content: '';
  position: absolute;
  height: 24px;
  width: 24px;
  background-image: sax(Chevron, $cochlear-purple);
  background-repeat: no-repeat;
  background-position: 0;
  top: 0;
}

// Theme
// $bgcolor-panel-light:       $color-grey-lighter;
$bgcolor-quiz-container: $color-grey-lighter;
$bgcolor-header: $color-black;
$border-color-quiz-options: $color-purple;
$font-color-quiz-options: $color-purple;
$hover-color-quiz-options: $color-purple;
$color-quiz-nav: $color-purple;

#page-online-hearing-test {
  .sl__related-articles {
    margin-top: 0;
  }

  hr {
    position: relative;
    left: -$quiz-page-margin;
    width: calc(100% + #{$quiz-page-margin * 2});

    @media #{$tablet} {
      position: static;
      left: auto;
      width: auto;
    }
  }

  .mobile-visible {
    display: block;

    @media #{$tablet} {
      display: none;
    }
  }

  @media #{$tablet} {
    background-color: $bgcolor-quiz-container;

    .l-padding {
      border-bottom: 0;
    }
  }

  .cta-button {
    font-weight: bold;
    cursor: pointer;
  }

  header.landing-banner {
    background-color: $bgcolor-header;

    .background {
      display: none;
    }

    .l-padding {
      padding-left: 0;
      padding-right: 0;

      @media #{$tablet} {
        padding-left: 36px;
        padding-right: 36px;
      }
    }

    .content {
      background-color: transparent;
      padding: 0;

      .content-grouper {
        width: 100%;
        margin-left: 0;

        .header-highlight {
          display: none;
        }
      }

      h1,
      p {
        color: white;
      }

      .content-grouper {
        border-left: 0;
      }
    }
  }

  .l-content-column {
    margin-bottom: 40px;
    min-height: auto;
  }

  .l-content-column.content {
    max-width: 100%;
  }

  .inner {
    background-color: white;
    padding: 0;
    overflow: hidden;
    position: relative;

    @media #{$tablet} {
      padding: 60px;
    }
  }

  aside {
    margin-top: 0;
  }

  .cta-button {
    width: 100%;
    text-align: center;
    padding: 13px 23px 14px 29px;

    &::after {
      position: relative;
      top: 6px;
      width: 24px;
      height: 24px;
      margin: -7px 0 0 5px;
      left: 0;
      display: inline-block;
    }

    @media #{$tablet} {
      width: auto;
      text-align: left;
      padding: 15px 45px 15px 27px;

      &::after {
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        margin: -10px 0 0;
        left: auto;
        display: block;
      }
    }
  }

  .spinner-container {
    padding: 0;

    .lds-dual-ring {
      margin: 0;
      position: static;
    }
  }

  .page-intro {
    padding-bottom: 50px;

    @media #{$tablet} {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      margin: 25px auto;
    }
  }

  .page-quiz {
    fieldset {
      margin: 0;
    }
    legend.h4 {
      margin-bottom: 30px;
    }

    &.prev {
      .question {
        &.slide-prev {
          position: absolute;
        }

        &.slide-curr {
          animation-name: anim-prev-slide-in;
        }

        &.slide-next {
          animation-name: anim-prev-slide-out;
          position: absolute;
          top: 0;
        }
      }
    }

    &.next {
      .question {
        &.slide-prev {
          animation-name: anim-next-slide-out;
          position: absolute;
          top: 0;
        }

        &.slide-curr {
          animation-name: anim-next-slide-in;
        }

        &.slide-next {
          position: absolute;
        }
      }
    }

    .inner {
      padding: 0;
    }

    .question {
      visibility: hidden;
      display: none;
      width: 100%;
      position: relative;
      top: 0;
      padding: 0;

      @media #{$tablet} {
        padding: 60px;
      }

      &.slide-curr,
      &.slide-prev,
      &.slide-next {
        display: block;
      }
    }

    ul.rating-panel {
      margin: 0;
      padding-bottom: 87px;
      padding-left: 0;
      clear: both;
      display: flex;
      justify-content: space-between;

      @media #{$tablet} {
        padding: 0;
      }

      li.rating {
        list-style: none;
        display: inline-block;
        text-align: center;
        position: relative;
        margin-top: 0;
        vertical-align: top;
        width: 50px;

        @media #{$tablet} {
          width: 20%;
        }

        div.rNumber {
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 50px;
          line-height: 50px;
          border-radius: 25px;
          color: $font-color-quiz-options;
          border: 1px solid $border-color-quiz-options;
          pointer-events: none;
        }

        label {
          width: 100%;
          display: block;

          padding-top: 66px;
          cursor: pointer;

          &:hover {
            & ~ .rNumber {
              background-color: $hover-color-quiz-options;
              color: white;
            }
          }

          span {
            // display: none;
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            position: absolute;
            width: 1px;

            @media #{$tablet} {
              display: block;
              left: auto;
              right: auto;
              margin: auto;
              text-align: center;

              // undo mobile style
              clip: auto;
              clip-path: none;
              height: auto;
              overflow: visible;
              position: static;
              width: auto;
            }
          }
        }

        input[type='checkbox'] {
          position: absolute;
          z-index: -1;
          opacity: 0;
          &:focus ~ .rNumber {
            border-width: 3px;
            line-height: 46px;
          }
          &:checked ~ .rNumber {
            background-color: #3f1482;
            color: white;
          }
          &:checked:focus ~ .rNumber {
            border-width: 3px;
            border-color: white;
            line-height: 46px;
            box-shadow: 0 0 0 1px#3f1482;
          }
        }

        &:first-child,
        &:last-child {
          label {
            span {
              display: block;
              position: absolute;
              pointer-events: none;
              width: 150px;

              // undo mobile style
              clip: auto;
              clip-path: none;
              height: auto;
              overflow: visible;
              margin: auto;

              @media #{$tablet} {
                position: static;
                pointer-events: all;
              }
            }
          }
        }

        &:first-child {
          label {
            span {
              left: 0;
              right: auto;
              text-align: left;

              @media #{$tablet} {
                left: auto;
                text-align: center;
              }
            }
          }
        }

        &:last-child {
          label {
            span {
              left: auto;
              right: 0;
              text-align: right;

              @media #{$tablet} {
                right: auto;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .quiz-nav-panel {
      background-color: #f5f5f5;
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      height: 71px;
      position: relative;
      left: -$quiz-page-margin;
      width: calc(100% + #{$quiz-page-margin * 2});

      @media #{$tablet} {
        width: 100%;
        position: static;
        left: auto;
      }

      .nav-grid {
        width: 33.3333%;
        min-height: 71px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;

        &:first-child {
          text-align: left;

          .content-link {
            margin-left: 49px;
          }
        }

        &:last-child {
          text-align: right;

          .content-link {
            margin-right: 49px;
          }
        }
      }

      .content-link,
      .quiz-progress {
        line-height: 71px;
        margin: 0;

        @media #{$tablet} {
          margin: 0 52px;
        }
      }

      .next {
        margin: 0;
        padding-right: 52px;
      }

      .quiz-progress {
        margin: 0;
      }

      .content-link {
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        color: $color-quiz-nav;
        position: relative;
        cursor: pointer;

        &.prev::before {
          @include icon-chevron;
          transform: rotate(90deg);
          left: -35px;

          @media #{$tablet} {
            left: -24px;
          }
        }

        &.next::after {
          @include icon-chevron;
          transform: rotate(-90deg);
          right: -35px;

          @media #{$tablet} {
            right: auto;
          }
        }
      }
    }
  }

  .page-results {
    h4 {
      margin-bottom: 30px;
    }

    p.result {
      font-size: 36px;
      font-weight: 600;
      color: #1e1e1e;
      margin-bottom: 30px;
    }

    p.suggestion {
      margin-bottom: 30px;
    }

    .primary {
      margin-right: 20px;
    }

    .inner {
      overflow: visible;
    }

    .row-cta {
      a.cta-button {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        @media #{$tablet} {
          display: inline;
          margin: 0 10px;
          width: auto;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  aside {
    margin-top: 0;
  }

  .cm-rich-text {
    padding-bottom: 30px;

    @media #{$tablet} {
      margin-top: 60px;
    }
  }

  .reference {
    padding-top: 10px;
    padding-bottom: 60px;
  }

  .hide {
    display: none;
  }

  .slide-animation-enabled {
    animation-duration: 300ms;
    animation-fill-mode: both;
    left: -100%;
    position: relative;
  }
}

@keyframes anim-next-slide-in {
  0% {
    left: 100%;
    visibility: visible;
  }

  100% {
    left: 0;
    visibility: visible;
  }
}

@keyframes anim-next-slide-out {
  0% {
    left: 0;
    visibility: visible;
  }

  99% {
    visibility: visible;
    left: -100%;
  }

  100% {
    visibility: hidden;
    left: -100%;
  }
}

@keyframes anim-prev-slide-in {
  0% {
    left: -100%;
    visibility: visible;
  }

  100% {
    left: 0;
    visibility: visible;
  }
}

@keyframes anim-prev-slide-out {
  0% {
    left: 0;
    visibility: visible;
  }

  99% {
    left: 100%;
    visibility: visible;
  }

  100% {
    left: 100%;
    visibility: hidden;
  }
}
