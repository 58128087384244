.cta-button {
  text-align: left;
  padding: 15px 45px 15px 27px;
  border: 1px solid $link-colour;
  background-color: $link-colour;
  color: $brand-white;
  text-decoration: none;
  display: inline-block;
  border-radius: 40px;
  position: relative;
  line-height: initial;
  font-size: 16px;
  font-family: $font-primary-bold;

  &.borderless {
    border: 0;
  }

  &.m-full-width {
    width: 100%;
    text-align: center;

    &:active,
    &:hover,
    &:focus {
      &::after {
        left: 10px;
      }
    }

    &::after {
      left: 5px;
      top: 5px;
      position: relative;
      margin: -4px 0 0 0;
      display: inline-block;
    }

    @media only screen and (min-width: 64em) {
      width: auto;
    }
  }

  &::after {
    content: ' ';
    right: 20px;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    position: absolute;
    background-image: sax(Chevron, white);
    background-size: 100%;
    transform: rotate(-90deg);
  }

  &:hover,
  &:active,
  &:focus {
    @include transition(0.2s);
    background-color: #672ac5;
    color: white;

    &::after {
      @include transition(0.2s);
      right: 16px;
    }
  }

  &.secondary {
    background-color: $brand-white;
    color: $link-colour;

    &::after {
      @include transition(0.2s);
      background-image: sax(Chevron, $cochlear-purple);
    }

    &:hover,
    &:active {
      background-color: $link-colour;
      color: $brand-white;
      border-color: $link-colour;

      &::after {
        background-image: sax(Chevron, white);
        right: 16px;
      }
    }
  }

  &.tertiary {
    background-color: $cochlear-yellow;
    border-color: $cochlear-yellow;
    color: $cool-grey;
    padding: 15px 25px 15px 27px;

    &::after {
      background-image: sax(Chevron, $cool-grey);
      transition: all 0.2s ease-in-out;
      left: 5px;
      top: 5px;
      position: relative;
      margin: -4px 0 0 0;
      display: inline-block;
    }

    &:hover,
    &:active {
      background-color: #ffb000;

      &::after {
        right: 16px;
        left: 10px;
      }
    }
  }
}

.link-icon {
  &:hover {
    path {
      fill: $link-colour;
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .cta-button {
    text-align: center;
    padding: 15px 27px 15px 45px;
    &:hover,
    &:focus &:active {
      &::after {
        right: auto; /*replaced*/
        left: 16px;
      }
    }
    &::after {
      right: auto; /*replaced*/
      left: 20px;
    }
    &.tertiary {
      padding: 15px 27px 15px 25px;

      &:hover {
        &::after {
          left: -10px;
        }
      }
      &::after {
        left: -5px;
      }
    }
  }
}
