@media only screen and (max-width: 63.9375em) {
  body.profile-active {
    height: 100%;
    height: 100vh;
    overflow: hidden;
  }
}

div.profile-dropdown {
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
  text-align: left;

  @media only screen and (min-width: 64em) {
    display: inline-block;
    vertical-align: bottom;
  }

  // background backdrop for mobile when the menu is open (visible on large touch devices)
  &::before {
    content: none;
    background: #efeeed;
    height: 100%;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: -1;
    left: 0;
    opacity: 0;
    transition: all 0.75s ease;
  }

  @media only screen and (min-width: 64em) {
    // dont show backdrop on desktop
    &::before {
      content: none !important;
    }
  }

  &.show {
    // backdrop visible
    &::before {
      content: "";
      opacity: 1;
    }

    .profile-dropdown__toggle {
      background: #fff;

      &:after {
        background-image: sax(Chevron, $cool-grey);
        transform: rotate(-180deg);
      }
    }

    // show the dropdown menu when it's active
    .profile-dropdown__menu {
      height: 100%;
      height: calc(100vh - 115px);
      overflow-y: scroll;
      opacity: 1;
      transform: translateY(0);

      @media only screen and (min-width: 64em) {
        height: auto;
        overflow: hidden;
      }
    }
  }

  .profile-dropdown__toggle {
    background: transparent;
    outline: 0;
    border: 0;
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    font-family: $font-circular-regular;
    font-size: 0.875em;
    /* 14px */
    line-height: 1.929;
    position: relative;
    padding: 11px 2.286em 12px 1em;
    margin: -13px 0 -14px 0;

    @media only screen and (min-width: 64em) {
      padding: 8px 2.286em 8px 1em;
      margin: 0;
    }

    &__circle {
      border-radius: 290486px;
      height: 27px;
      width: 27px;
      line-height: 27px;
      font-size: 11px;
      background: $link-colour;
      color: #fff;
      text-align: center;
      vertical-align: middle;
      padding: 0;
      display: inline-block;

      img {
        max-width: 100%;
        display: block;
        margin: 6px auto 0;
      }
    }

    &:after {
      content: " ";
      position: absolute;
      right: 6px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-image: sax(Chevron, $cool-grey);
      background-size: 100%;
      transform: rotate(0deg);
      transition: all 0.2s ease-in-out;
    }
  }

  div.profile-dropdown__menu {
    padding: 0;
    background-color: #fff;
    top: 50px;
    left: 0;
    width: 100%;
    height: 0;
    position: absolute;
    overflow: hidden;
    transition: height 0.5s ease;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 64em) {
      top: 100%;
      z-index: 999; // sit below country dropdown toggle if open as well
      border-top: 0;
      right: 35px;
      width: 285px;
      left: auto;
      box-shadow: 0 8px 8px rgba(30, 30, 30, 0.1);
    }

    @media only screen and (min-width: 77.75em) {
      right: 36px;
    }

    .profile-dropdown__menu__item {
      font-family: $font-circular-regular;
      font-size: 18px;
      /* 18px */
      font-weight: 400;
      line-height: 28px;
      text-decoration: none;
      letter-spacing: 0;
      color: $font-darkblue;
      padding: 16px;
      border-top: 1px solid $bg-dark-grey;
      background: #fff;
      align-self: center;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      margin: 0 auto;
      position: relative;

      &:hover,
      &:focus {
        background-color: #ffffff;
        color: $cochlear-black;
      }

      &:last-child {
        margin-bottom: 100px;
      }

      @media only screen and (min-width: 64em) {
        font-size: 18px;
        line-height: 28px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:after {
        content: " ";
        position: absolute;
        right: 19px;
        top: 50%;
        width: 24px;
        height: 24px;
        // margin-top: -10px;
        transform: translateY(-50%) rotate(0deg);
        background-repeat: no-repeat;
        background-position: center;
      }

      &--chevron {
        &:after {
          transform: rotate(-90deg);
          background-image: sax(Chevron, $cochlear-purple);
          background-size: 100%;
        }
      }

      &--external {
        &:after {
          background-image: sax(External, $cochlear-purple);
          background-size: 21px auto;
        }
      }

      &--logout {
        text-align: left;
        border: 0 none;
        border-top: 1px solid $bg-dark-grey;
        background: #fff;
        &[disabled] {
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      &--header {
        border-top: 0 none;

        &:after {
          content: none;
        }

        .subtitle {
          color: $font-darkblue;
          font-family: $font-circular-medium;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          padding: 3px 0;
        }

        .account {
          color: $font-darkblue;
          font-family: $font-circular-regular;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          padding: 3px 0;
        }
      }

      &--new-feature {
        border: 0 none;
        border-top: 1px solid $bg-dark-grey;
        background: rgba(78, 3, 192, 0.05);
        text-align: left;
        color: $link-colour;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;

        &:hover,
        &:focus {
          color: $link-colour;
          background: rgba(78, 3, 192, 0.05);
        }
      }
    }
  }

  .popover {
    padding: 0;
    background-color: #4e03c0;
    top: 70px;
    right: 13px;
    position: absolute;
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    max-width: calc(100% - 26px);

    @media only screen and (min-width: 64em) {
      top: calc(100% + 15px);
      z-index: 998; // sit below profile dropdown toggle if open as well
      right: 49px;
      left: auto;
    }

    .popover__container {
      position: relative;
      padding: 14px 16px 16px 13px;
      line-height: 24px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      @media only screen and (min-width: 64em) {
        padding: 12px 11px 14px 12px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #4e03c0;
        transform: rotate(45deg);
        top: -7px;
        right: 23px;
      }
    }

    .button {
      font-family: $font-circular-regular;
      background: transparent;
      border: 0 none;
      color: #fff;
      padding: 0;
      vertical-align: middle;

      &--popover {
        font-size: 18px;
        line-height: 24px;

        @media only screen and (min-width: 64em) {
          font-size: 14px;
          line-height: 20px;
        }

        &:after {
          content: "";
          transform: rotate(-90deg);
          width: 20px;
          height: 20px;
          position: relative;
          display: inline-block;
          margin: -5px 0 0 0;
          top: 5px;
          left: 0;
          background-image: sax(Chevron, #fff);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      &--dismiss {
        font-size: 12px;
        line-height: 22px;
        padding-left: 12px;
        margin: 2px 0 0 5px;
        position: relative;

        @media only screen and (min-width: 64em) {
          line-height: 18px;
          padding-left: 9px;
          margin: 2px 0 0 8px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 1px;
          height: 32px;
          background-color: rgba(255, 255, 255, 0.3);
          top: 50%;
          transform: translateY(-50%);
          @media only screen and (min-width: 64em) {
            height: 22px;
          }
        }
      }

      // style hover/focus on both buttons
      &:hover,
      &:focus {
        outline: 0;
        text-decoration: underline;
      }
    }
  }
}

// Logout Button
// -------------------------------------------
.profile-dropdown__menu__item--logout.has-spinner {
  &.is-submitting {
    .lds-ring {
      display: inline-block;
      position: absolute;
      height: 28px;
      width: 28px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      right: 15px;
    }

    &::after {
      content: none !important;
    }
  }

  .lds-ring {
    display: none;
    position: relative;
    width: 29px;
    height: 29px;
  }

  .lds-ring span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid $link-colour;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $link-colour transparent transparent transparent;
  }

  &:active {
    .lds-ring span {
      border-color: $link-colour transparent transparent transparent;
    }
  }

  @media screen and (min-width: 64em) {
    &:hover {
      .lds-ring span {
        border-color: $link-colour transparent transparent transparent;
      }
    }
  }

  .lds-ring span:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring span:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring span:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
