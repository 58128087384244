@import '@cochlear-design-system/foundation/dist/_shared/cds.scss';

.thankyou-container {
  margin-top: $spacing-5x !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .ccl__columns {
    flex-direction: column;

    h2 {
      padding: $spacing-1nhalf 0;
    }
  }

  @include tablet {
    margin-top: $spacing-10x !important;

    .ccl__columns {
      flex-direction: row;
    }

    .pictogram-column {
      margin-left: -156px; /* same width as pictogram */
    }
  }
}
