.two-up-cta-diagram-container {
  padding: 104px;

  @media only screen and (max-width: 79.9375em) {
    padding: 48px 16px;
  }

  &.version-1 {
    background: #fcefd1;
  }

  &.version-2 {
    background: $brand-white;
  }

  &.version-4 {
    background: #e4eae7;
  }

  &-width {
    display: flex;
    align-items: flex-start;
    max-width: 1158px;
    margin: 0 auto;
    width: 100%;

    @media only screen and (max-width: 47.9375em) {
      display: block;
    }

    &.image-right {
      flex-direction: row;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      width: 370px;
      margin-right: 123px;

      @media only screen and (max-width: 85em) {
        margin-right: 50px;
      }

      @media only screen and (max-width: 79.9375em) {
        width: 40%;
        margin-right: 30px;
      }

      @media only screen and (max-width: 47.9375em) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
      }

      &-heading {
        font-family: $font-circular-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 16px;
        color: #384967;

        @media only screen and (max-width: 79.9375em) {
          font-size: 28px;
          line-height: 36px;
        }

        @media only screen and (max-width: 47.9375em) {
          font-size: 32px;
          line-height: 44px;
          margin-bottom: 8px;
        }
      }

      &-description {
        font-family: $font-circular-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #384967;

        @media only screen and (max-width: 47.9375em) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }

      &-link {
        margin-top: 32px;

        @media only screen and (max-width: 47.9375em) {
          margin-top: 16px;
        }

        .cta-button {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 28px 12px 32px;
          border: 1px solid $link-colour;
          border-radius: 16px;
          background: none;
          font-family: $font-circular-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: $link-colour;
          min-width: 261px;
          height: 52px;

          &::after {
            background-image: sax(Chevron, $cochlear-purple);
          }

          &:hover,
          &:active {
            background: transparent;
            border: 2px solid $link-colour;
            margin: 0;
            color: $link-colour;

            &::after {
              background-image: sax(Chevron, $cochlear-purple);
              right: 16px;
            }
          }

          @media only screen and (max-width: 47.9375em) {
            max-width: 343px;
            width: 100%;
            font-size: 16px;
            line-height: 24px;
            height: 48px;
          }
        }
      }
    }

    &.image-left {
      flex-direction: row-reverse;

      .two-up-cta-diagram-container-width-image-section-textblocks-block {
        border-bottom-color: #6a708d;

        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      .two-up-cta-diagram-container-width-content {
        margin-left: 123px;
        margin-right: 0;

        @media only screen and (max-width: 85em) {
          margin-left: 50px;
        }

        @media only screen and (max-width: 79.9375em) {
          width: 40%;
          margin-left: 30px;
        }

        @media only screen and (max-width: 47.9375em) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 32px;
        }
      }
    }

    &-image-section {
      width: 665px;
      position: relative;

      @media only screen and (max-width: 79.9375em) {
        width: 50%;
      }

      @media only screen and (max-width: 47.9375em) {
        width: 100%;
        padding-top: 164px;
      }

      &-image {
        width: 303px;
        height: 344px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        margin-left: -6px;

        @media only screen and (max-width: 47.9375em) {
          text-align: center;
          width: 100%;
          margin-top: 0;
          align-items: flex-start;
          justify-content: center;
          margin-left: 0;
        }

        img {
          border-radius: 16px;
          width: 303px;
          height: 344px;

          @media only screen and (max-width: 79.9375em) {
            width: 50%;
            height: auto;
          }

          @media only screen and (max-width: 47.9375em) {
            width: 287px;
            height: 326px;
          }
        }
      }

      &-textblocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 518px;
        padding: 22px 32px 22px 180px;
        border-radius: 16px;
        min-height: 392px;
        margin: 0 0 0 auto;

        @media only screen and (max-width: 79.9375em) {
          width: 85%;
          padding: 22px 32px 22px 95px;
        }

        @media only screen and (max-width: 47.9375em) {
          width: 100%;
          padding: 186px 24px 24px;
        }

        &-block {
          font-family: $font-circular-regular;
          display: flex;
          flex-direction: row;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #384967;

          @media only screen and (max-width: 47.9375em) {
            font-size: 16px;
            line-height: 24px;
          }

          &:not(:first-child) {
            margin-top: 32px;

            @media only screen and (max-width: 47.9375em) {
              margin-top: 8px;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #c86c00;
            padding-bottom: 16px;

            @media only screen and (max-width: 47.9375em) {
              border-bottom-color: #6a708d;
            }
          }

          p {
            margin-right: 16px;
            font-family: $font-circular-regular;
            font-weight: 500;
            color: #384967;
            font-size: 18px;
            line-height: 28px;

            @media only screen and (max-width: 47.9375em) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        &.image-top {
          flex-direction: row;
        }

        &.version-1 {
          background: #f8dfa4;
        }

        &.version-2 {
          background: #ebe7d9;
        }

        &.version-4 {
          background: #c9d6d0;
        }
      }
    }

    &.image-top {
      @media only screen and (max-width: 79.9375em) {
        max-width: 100%;
        width: 100%;

        .two-up-cta-diagram-container-width-content {
          width: 33%;

          @media only screen and (max-width: 47.9375em) {
            width: 100%;
          }
        }

        .two-up-cta-diagram-container-width-image-section {
          width: 67%;
        }

        .two-up-cta-diagram-container-width-content-link {
          .cta-button {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }

      .two-up-cta-diagram-container-width-image-section {
        @media only screen and (max-width: 47.9375em) {
          padding-top: 30px;
          width: 100%;
        }
      }
    }

    .image-top {
      .two-up-cta-diagram-container-width-image-section-image {
        position: relative;
        height: 120px;
        margin: 0;
        width: 100%;

        img {
          width: auto;
          height: 240px;
          max-width: 80%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: -120px;
        }

        @media only screen and (max-width: 47.9375em) {
          text-align: center;

          img {
            max-width: 85%;
          }
        }
      }

      .two-up-cta-diagram-container-width-image-section-textblocks {
        width: 665px;
        padding: 152px 32px 32px;
        min-height: auto;
        justify-content: center;
        align-items: flex-start;

        @media only screen and (max-width: 79.9375em) {
          width: 100%;
        }

        @media only screen and (max-width: 47.9375em) {
          margin-top: 0;
        }

        &-block {
          border-bottom: 0;
          display: flex;
          justify-content: flex-start;
          flex-flow: row nowrap;
          margin: 0;
          padding: 0 16px;
          max-width: 33%;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #384967;
          margin-top: 0 !important;

          &:last-child {
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
          }

          &:not(:last-child) {
            border-right: 1px solid #6a708d;

            @media only screen and (max-width: 47.9375em) {
              border-right: 0;
              border-bottom: 1px solid #6a708d;
              padding-bottom: 16px;
            }
          }

          &-index {
            font-size: 16px;
            line-height: 24px;
            height: 100%;
            margin: 0 10px 0 0 !important;

            @media only screen and (max-width: 47.9375em) {
              margin-right: 16px;
              overflow: visible;
            }
          }

          &-text {
            height: 144px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @media only screen and (max-width: 47.9375em) {
            max-width: 100%;
            height: auto;
            padding: 0;
            display: flex;

            &-text {
              height: auto;
            }
          }
        }
      }

      @media only screen and (max-width: 47.9375em) {
        display: block;
      }
    }
  }

  .image-video-container {
    img {
      width: 100%;
      height: auto;
    }

    .video-modal-cta {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include video-play-icon;

      border: 0;
      padding: 0;
      margin: 0;
      display: block;

      &::after {
        left: calc(50% + 5px);
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 25px solid $brand-white;
      }
    }
  }

  .break-line {
    border-bottom: 1px solid $bg-dark-green;
    margin: 80px 0;

    @media only screen and (max-width: 47.9375em) {
      margin: 48px 0;
    }
  }
}
