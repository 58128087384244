@mixin bp-large-min {
  @media only screen and (min-width: 64em) {
    @content;
  }
}

.global-footer-2 {
  background-color: $brand-navy;
  color: $brand-white;
  padding: 0;
  font-size: 14px;

  .l-padding {
    padding-top: 56px;
    padding-bottom: 56px;
    display: flex;
    flex-direction: column;
  }

  &.is-hidden {
    @include mobile-menu-background-visibility;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include bp-large-min {
      flex-direction: row;
      align-items: flex-start;
    }

    .footerLogo {
      width: 122px;

      img {
        width: 122px;
        height: auto;
      }
    }

    .navLinks {
      max-width: 343px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0 24px;
      padding: 57px 24px 32px;
      font-size: 20px;
      line-height: 34px;

      .navLinksColumn {
        display: flex;
        flex-direction: column;

        &:nth-child(3) {
          padding-top: 25px;
        }
      }

      a {
        text-decoration: none;
        color: $brand-white;
        padding: 6px 25px 6px 0;
        font-family: $font-circular-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        width: 100%;
      }

      @include bp-large-min {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        max-width: 576px;
        padding: 0 24px;

        .navLinksColumn:nth-child(3) {
          padding-top: 0;
        }
      }
    }

    .socialIcons {
      display: flex;
      flex-flow: row nowrap;
      padding-top: 10px;

      img {
        margin: 6px 10px;
        width: 30px;
        height: 30px;
      }

      @include bp-large-min {
        a:last-child {
          img {
            margin: 6px 0 6px 10px;
          }
        }
      }
    }
  }

  .subfooter {
    &-container {
      display: flex;
      background-color: $brand-navy;
      padding: 70px 0 20px;
    }

    &-copyright {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: $font-circular-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      @include bp-large-min {
        justify-content: flex-end;
      }

      > div {
        margin: 0;
        color: $brand-white;
        padding: 0;
        font-style: normal;
        width: fit-content;
      }
    }
  }
}
