.mobile-header {
  display: flex;
  padding: 15px 0;
  border-top: 5px solid $cochlear-yellow;
  position: relative;
  max-width: 768px;
  margin: 0 auto;
  &.is-hidden {
    @include mobile-menu-background-visibility;
  }

  @media only screen and (min-width: 64em) {
    display: none;
  }

  &.is-active {
    // added when profile dropdown is open
    background: #efefed;
    .logo {
      opacity: 0.5;
    }
  }
  &__item {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    &:first-child,
    &:last-child {
      flex: 1 1;
    }
    &:last-child {
      text-align: right;
    }
  }

  .mobile-logo {
    float: none;
    width: 130px;
    margin: 0 auto;
    padding: 0;
    display: block;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .login-btn {
    width: 66px;
    height: 27px;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(205, 201, 197, 0.5);
    border-radius: 14px;
    padding: 3px 15px 5px 16px;
    color: rgb(63, 20, 130);
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    margin-right: 15px;
  }
}

.professionals-page {
  .mobile-header {
    border-top: 5px solid $link-colour;
  }
}
