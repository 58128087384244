.your-cochlear-stories {
  background-color: $light-grey;
  padding: 30px 0;

  @media screen and (min-width: 64em) {
    padding: 50px 0;
  }

  .l-padding {
    @media screen and (min-width: 64em) {
      display: flex;
    }
  }

  .description {
    @media screen and (min-width: 64em) {
      width: 25%;
      padding: 120px 50px 0 0;
    }

    h2 {
      font-size: 36px;
    }

    .cta-button {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      color: $cochlear-purple;
      font-size: 18px;
      font-weight: 900;

      &:after {
        background-image: sax(Chevron, $cochlear-purple);
      }
    }
  }

  .is-33-split {
    @media screen and (min-width: 64em) {
      width: 75%;
      margin-top: 0;
      margin-bottom: 0;
    }

    .sl-item {
      @media screen and (min-width: 64em) {
        padding: 0 10px;
      }
    }
  }

  div.content-card,
  a.content-card {
    border: 0;

    @media screen and (min-width: 64em) {
      height: 515px;
      margin: 0;
    }

    .img-container {
      height: 205px;

      @media screen and (min-width: 64em) {
        height: 515px;
        margin-bottom: 0;

        img {
          width: auto;
          height: 515px;
          margin-left: -340px;
          position: relative;
          top: 0;
          left: 0;
          transform: translateY(0);
        }
      }
    }

    .content-container {
      p {
        display: none;
      }

      @media screen and (min-width: 64em) {
        position: absolute;
        bottom: 0;
        margin: 0;
        background-color: transparent;
        width: 100%;

        h2 {
          background-color: $brand-white;
          opacity: 0.85;
          font-size: 22px;
          line-height: 29px;
          padding: 10px 20px;
          height: 110px;
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .your-cochlear-stories {
    .description {
      @media screen and (min-width: 64em) {
        padding: 120px 0 0 50px;
      }
      .cta-button {
        &::after {
          right: 16px;
        }
        &:hover,
        &:active,
        &:focus {
          &::after {
            right: 20px;
          }
        }
      }
    }
  }
}
