$color_1: #fff;
$font-family_1: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans',
  'Helvetica Neue', Arial, sans-serif;
$background-color_1: transparent;

.checkboxComponent {
  .numbered-list {
    margin: 20px 0 40px;

    ol {
      padding-left: 25px;
      margin-left: 3px;
      counter-reset: list;

      li {
        padding: 0;
        border-bottom: none;
        list-style: none;
        position: relative;
        line-height: 25px;

        &::before {
          display: none;
          list-style: none;
        }

        p {
          padding-left: 40px;
          display: inline-block;
          border-bottom: none;
          line-height: 32px;
          width: 100%;
        }
      }
    }
  }

  .checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    clear: both;

    .checkbox-custom {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-color: $background-color_1;
      border-radius: 5px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid #3f1482;
      margin-top: 6px;

      &::after {
        position: absolute;
        content: '';
        left: 12px;
        top: 12px;
        width: 0;
        height: 0;
        border-radius: 5px;
        border: solid #3f1482;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
        opacity: 1;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkbox-custom {
        background-color: $background-color_1;
        border-radius: 5px;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 2px solid #3f1482;

        &::after {
          -webkit-transform: rotate(45deg) scale(1);
          -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 8px;
          top: 3px;
          width: 6px;
          height: 12px;
          border: solid #3f1482;
          border-width: 0 2px 2px 0;
          background-color: $background-color_1;
          border-radius: 0;
        }

        &::before {
          left: -3px;
          top: -3px;
          width: 24px;
          height: 24px;
          border-radius: 5px;
          -webkit-transform: scale(3);
          -ms-transform: scale(3);
          transform: scale(3);
          opacity: 0;
          z-index: 999;
          transition: all 0.3s ease-out;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
        }
      }
    }
  }

  .cta-button-breakdown {
    color: $color_1 !important;
  }

  .lto4checkbox {
    margin-top: -3px;
    display: inline-block;
    position: absolute;
  }

  .key-points-withoutborder {
    border-bottom: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .lto4download .breakdowndescription {
    padding-right: 20px;
    font-family: $font-family_1;
    font-size: 18px;
    line-height: 28px;
    color: #56565a;
    margin: 14px 0;

    @media only screen and (min-width: 64em) {
      padding-right: 50px;
    }
  }
}
