#sc-form {
  form {
    display: flex;
    flex-direction: column;
  }
  .form-box {
    .is-error {
      label.check-container {
        + .error {
          float: left;
        }
      }
    }
  }
}

.schematik-form,
#sc-form {
  margin-top: 30px;
  hr {
    &:last-of-type {
      &.show {
        &.horizontal-rule {
          display: none;
        }
      }
    }
  }

  // fieldset.form-box:nth-last-child(2) {
  //     padding-bottom: 0;
  // }
  .error-summary-container {
    padding-top: 0;
    padding-bottom: 0;
    order: 10;

    .details-box {
      margin-top: 20px;

      .error-summary {
        margin-top: 0;
      }
    }
  }

  .render-buttons {
    padding-top: 0;
  }

  .form-box .sl-list .multi-container .date-input:last-child::after {
    content: '';
  }

  .field-container {
    fieldset {
      legend {
        font-size: 16px;
        margin-top: 30px;
        color: $cool-grey;
        font-family: 'BlissPro-Light', 'Trebuchet MS', 'Gill Sans',
          'Helvetica Neue', Arial, sans-serif;
        font-weight: bold;
        width: 100%;
        margin-bottom: 0;

        select,
        input {
          line-height: 20px;
        }

        .split-group {
          .split-ratio-1,
          .split-ratio-2,
          .split-ratio-3,
          .split-ratio-4,
          .split-ratio-5,
          .split-ratio-6 {
            &:first-child {
              .select-input,
              .form-input {
                label {
                  margin-top: 5px;
                }
              }
            }
          }

          .sl-item {
            .select-input {
              select {
                padding: 10px;
              }
            }
          }

          .select-input,
          .form-input {
            line-height: 30px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
    .split-group {
      display: flex;

      label {
        line-height: 30px;
      }

      @mixin split-ratio-x {
        @for $i from 1 through 6 {
          .split-ratio-#{$i} {
            flex: 0 $i 100%;

            &:not(:last-child) {
              margin-right: 30px;
            }
          }
        }
      }

      @include split-ratio-x();

      .sl-item {
        select {
          padding: 5px 10px;
        }
      }
    }

    .field-container {
      fieldset {
        .select-input,
        .form-input {
          label {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .schematik-form,
  #sc-form {
    @media only screen and (min-width: 64em) {
      .split-group {
        display: flex;

        label {
          line-height: 30px;
        }

        @mixin rtl-split-ratio-x {
          @for $i from 1 through 6 {
            .split-ratio-#{$i} {
              flex: 0 $i 100%;

              &:not(:last-child) {
                margin-left: 30px;
                margin-right: 0;
              }
            }
          }
        }

        @include rtl-split-ratio-x();
      }
    }
  }
}
