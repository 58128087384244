.marketing-consent-container {
  background-color: $cochlear-lightyellow;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.marketing-consent {
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 64em) {
    display: block;

    &.l-container {
      width: unset;
    }
  }

  .text-block-container,
  .image-block-container {
    width: 50%;

    @media screen and (max-width: 64em) {
      width: 100%;
    }
  }

  .text-block-container {
    padding: 72px;

    @media screen and (max-width: 64em) {
      padding: 48px 16px;
    }
  }

  .description-text {
    font-weight: 500;
    font-size: 48px;
    line-height: 64px;
    color: $font-darkblue;
    margin-bottom: 32px;

    @media screen and (max-width: 64em) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .cta-button {
    padding: 12px 32px;
    font-family: $font-circular-medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $cochlear-purple;
    border-radius: 16px;

    @media screen and (max-width: 64em) {
      width: 100%;
      text-align: center;
    }

    &::after {
      background-image: sax(Chevron, $cochlear-purple);
    }
  }

  .image-block-container {
    img {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;

      @media screen and (max-width: 64em) {
        display: block;
      }
    }
  }
}
