div.compatibility-container {
  h1 {
    margin: 50px 0 40px 0;
    font-size: 48px;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    color: #1e1e1e;
    font-weight: 500;
  }

  h2 {
    margin: 50px 0 20px 0;
    font-size: 30px;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    color: #1e1e1e;
    font-weight: 500;
  }

  p {
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 20px;
    margin: 0 0 10px;
    color: #1e1e1e;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  a {
    text-decoration: underline;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    color: #1e1e1e;
  }

  div.compatibility-table-container {
    border-left: 1px solid #f0efee;
    border-right: 1px solid #f0efee;
    border-bottom: 1px solid #f0efee;
    position: relative;
    overflow: auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &.with-bottom-radius {
      border-radius: 0px 0px 6px 6px;
    }
    &.with-top-radius {
      border-radius: 6px 6px 0 0;
    }
    &.with-top-margin {
      margin-top: 30px;
    }

    a {
      text-decoration: underline;
      background-color: transparent;
      &.anchor {
        position: absolute;
        top: -120px;
      }
    }

    table.compatibility-table {
      display: table;
      width: 100%;
      background-color: transparent;
      border: none;
      margin-top: 0px;

      thead {
        @media only screen and (max-width: 64em) {
          display: block;
          width: 100%;
        }
        vertical-align: middle;

        tr {
          @media only screen and (max-width: 64em) {
            display: block;
            width: 100%;
          }

          th {
            background: #f0efee;
            padding: 15px 30px;
            text-align: left;
            border: none;

            @media only screen and (max-width: 64em) {
              display: block;
              width: 100%;
            }

            img[name='Baha 5'],
            img[name='Smart hearing alliance'] {
              width: auto;
              border-radius: 0;
            }

            img {
              width: 40px;
              height: 40px;
              display: inline-block;
              margin-right: 15px;
              border-radius: 10px;
              vertical-align: middle;
              border: 0;

              + h3 {
                display: inline-block;
                position: relative;
                top: 4px;
                font-family: 'Trebuchet MS', Arial, sans-serif;
                margin: 0;
                font-weight: 500;
                font-size: 26px;
                @media only screen and (max-width: 64em) {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      tbody {
        @media only screen and (max-width: 64em) {
          display: block;
          width: 100%;
        }

        td {
          vertical-align: top;
          padding: 10px 15px 25px 15px;
          width: 20%;
          min-width: 180px;
          color: #8a8b8e;
          border: none;
          background-color: transparent;

          &:nth-child(1) {
            padding-left: 30px;
          }

          @media only screen and (max-width: 64em) {
            display: block;
            width: 100%;
            padding: 0 40px 0 30px;
          }

          h4 {
            font-size: 17px;
            margin: 35px 0 15px 0;
            font-family: 'Trebuchet MS', Arial, sans-serif;
            color: #000;
          }
          ul {
            margin: 0;
            padding-left: 15px;

            li {
              padding: 0;
              color: #8a8b8e;
              margin: 0 0 7px 0;
              font-size: 14px;
              line-height: 20px;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
          }
        }
      }
    }
  }
}

div#root > div[dir='rtl'] {
  td:first-child,
  th:first-child {
    border-left: none;
    border-right: none;
  }

  .cm-rich-text {
    ul {
      margin-right: 0;
    }
  }
}
