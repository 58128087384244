.product-color-switcher {
  overflow: hidden;

  .l-padding {
    min-height: 500px;
    display: flex;
    flex-direction: column-reverse;

    @media only screen and (min-width: 64em) {
      flex-direction: row;
    }
  }

  .content-container {
    margin: 30px 0 0;

    @media only screen and (min-width: 64em) {
      margin: 60px 0 0 0;
      padding-right: 60px;
      width: 50%;
    }
  }

  .product-tabs {
    margin-top: 30px;

    p {
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }

    .product-info {
      align-items: center;
      border-top: 2px solid lightgrey;
      display: flex;
      justify-content: space-between;
      min-height: 80px;

      p {
        width: 30%;
      }

      .product-options {
        width: 70%;
        display: flex;

        .product-option {
          text-align: center;
          padding: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          position: relative;
          width: 100%;
          font-size: 16px;
          font-family: $font-header;
          color: $cool-grey;
          background-color: transparent;
          border: 0;

          &:before {
            content: '';
            transition: height 0.3s;
            height: 0;
            width: 100%;
            position: absolute;
            background-color: $cochlear-purple;
            bottom: 0;
            top: auto;
          }

          &:focus,
          &:hover {
            background-color: $lighter-grey;
          }

          &.is-selected {
            color: $cochlear-purple;
            &:before {
              height: 5px;
            }
          }
        }
      }
    }
  }

  .product-config {
    min-height: 80px;
    align-items: center;
    display: flex;
    border-left: 0;
    border-right: 0;
    justify-content: space-between;

    @media only screen and (min-width: 64em) {
      border-top: 2px solid #d3d3d3;
      border-bottom: 2px solid #d3d3d3;
    }

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-end;
      flex-wrap: wrap;

      li {
        margin-top: 0;

        .colour-option {
          background: none;
          border: 0;
          width: 50px;
          height: 80px;
          position: relative;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;

          &:after {
            content: '';
            transition: height 0.3s;
            height: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $cochlear-purple;
          }

          &:focus,
          &:hover {
            background-color: $lighter-grey;
          }

          .color-circle-container {
            pointer-events: none;
            z-index: 1;
            width: 31px;
            height: 31px;
            padding: 1px;
            background-color: grey;
            border-radius: 50%;

            .circle-outline {
              position: relative;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              overflow: hidden;

              .circle-color {
                position: absolute;
                height: 100%;
                left: 0;
                top: 0;
                width: 100%;

                &:first-child {
                  z-index: 1;
                }

                &:nth-child(2) {
                  left: 50%;
                  z-index: 2;
                }
              }
            }
          }
        }

        .is-selected {
          &:after {
            height: 5px;
          }
        }
      }
    }
  }

  .image-container {
    padding: 50px 0;
    position: relative;
    min-height: 320px;

    @media only screen and (min-width: 64em) {
      padding-left: 50px;
      min-height: 450px;
      width: 50%;
    }

    img {
      z-index: 1;
      max-width: 320px;
      max-height: 320px;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      @media only screen and (min-width: 64em) {
        max-width: 450px;
        max-height: 450px;
        width: calc(100% - 100px);
      }

      &.is-active {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background-color: $lighter-grey;
      z-index: -1;

      @media only screen and (min-width: 64em) {
        left: 0;
        transform: none;
      }
    }
  }
}

div#root > div[dir='rtl'] {
  .product-color-switcher {
    @media only screen and (min-width: 64em) {
      .content-container {
        padding-left: 60px;
        padding-right: 0px;
      }
    }
    .image-container {
      &::after {
        right: 0;
        left: auto; /*replaced*/
      }
    }
  }
}
