//https://www.cochlear.com/phoenixResources/shared/images/countries_flag_sprite.png
.contact-customer-service-container {
  background-color: white;
  h1 {
    font-size: 48px;
    color: #1e1e1e;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.1;
    font-family: 'Trebuchet MS', Arial, sans-serif;
  }

  .countries {
    display: flex;
    flex-direction: row;

    .country-column {
      flex: 1;
      p {
        margin-bottom: 10px;
        line-height: 22px;
        font-size: 14px;
        margin: 0 0 10px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      ul {
        padding: 0;
        margin-top: 0;
        margin-bottom: 10px;
        display: block;
        color: #1e1e1e;

        li {
          list-style: none;
          position: relative;
          padding: 3px 0 0 25px;
          margin-bottom: 5px;
          font-size: 14px;

          a {
            text-decoration: underline;
            font-family: 'Trebuchet MS', Arial, sans-serif;
            background-color: transparent;
            color: #1e1e1e;
            text-align: match-parent;

            .countries_flag {
              background: url(https://mss-p-007-delivery.stylelabs.cloud/api/public/content/22efa0ff3a614456956089c3330766c5?v=21d21248)
                no-repeat 0 0;
              width: 16px;
              height: 14px;
              position: absolute;
              top: 8px;
              left: 0;
              border-bottom: 0 none !important;

              &.canada {
                background-position: -27px -88px;
              }
              &.usa {
                background-position: 0 0;
              }
              &.brazil {
                background-position: 0 -176px;
              }
              &.globe {
                background-position: 0 -110px;
              }
              &.australia {
                background-position: -81px -88px;
              }

              &.china {
                background-position: 0 -88px;
              }
              &.hongkong {
                background-position: -81px 0;
              }
              &.india {
                background-position: -54px -66px;
              }
              &.indonesia {
                background-position: -27px -132px;
              }
              &.japan {
                background-position: 0 -66px;
              }
              &.korea {
                background-position: -54px -44px;
              }
              &.malaysia {
                background-position: 0 -132px;
              }
              &.nz {
                background-position: -27px -198px;
              }
              &.philippines {
                background-position: -54px -110px;
              }
              &.singapore {
                background-position: -54px -154px;
              }
              &.taiwan {
                background-position: -81px -154px;
              }
              &.thailand {
                background-position: -81px -110px;
              }
              &.vietnam {
                background-position: -27px -110px;
              }
              &.austria {
                background-position: -54px -132px;
              }
              &.belgium {
                background-position: -54px -88px;
              }
              &.cz {
                background-position: -54px -176px;
              }
              &.denmark {
                background-position: -81px -132px;
              }
              &.finland {
                background-position: -81px -66px;
              }
              &.france {
                background-position: -81px -44px;
              }
              &.germany {
                background-position: -81px -22px;
              }
              &.hungary {
                background-position: 0 -154px;
              }
              &.ireland {
                background-position: 0 -198px;
              }
              &.israel {
                background-position: -27px -154px;
              }
              &.italy {
                background-position: -27px -66px;
              }
              &.netherlands {
                background-position: -27px -44px;
              }
              &.norway {
                background-position: -81px -176px;
              }
              &.poland {
                background-position: -81px -198px;
              }
              &.romania {
                background-position: 0 -44px;
              }
              &.spain {
                background-position: -54px -22px;
              }
              &.sweden {
                background-position: -27px -22px;
              }
              &.switzerland {
                background-position: 0 -22px;
              }
              &.turkey {
                background-position: -54px 0;
              }
              &.uk {
                background-position: -27px 0;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 64em) {
      flex-direction: column;
    }
  }
}
