.faq {
  .l-content-column {
    min-height: auto;
    margin-top: 30px;
    margin-bottom: 60px;

    @media only screen and (min-width: 64em) {
      padding: 0;
      margin-top: 0;
    }
  }

  .spinner {
    display: block;
    height: 250px;

    &:before {
      animation: spinner 1.2s linear infinite;
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -23px 0 0 -23px;
      display: block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 5px solid $mid-grey;
      border-color: $mid-grey transparent $mid-grey transparent;
    }
  }

  .faq-search {
    background-color: $cochlear-black;
    border-radius: 0 0 40px 0;
    padding: 45px 0 45px 0;
    text-align: center;
    width: 100%;
    z-index: 2;

    @media only screen and (min-width: 64em) {
      padding: 80px 0 45px 0;
    }
  }

  .faq-search__submit {
    background: $cochlear-black sax(Search, $brand-white) no-repeat;
    background-size: 100%;
    border: 0;
    text-indent: -999em;
    width: 25px;
    height: 25px;
    padding: 0;
    position: relative;
    top: 5px;
    left: -5px;

    @media only screen and (min-width: 48em) {
      width: 30px;
      height: 30px;
      top: 1px;
      left: -10px;
    }
  }

  .faq-search__form {
    position: relative;
  }

  .faq-search__text,
  .underline {
    font-family: $font-header;
    font-size: 16px;
    background-color: $cochlear-black;
    color: $brand-white;
    border: 0;
    text-align: left;
    padding: 1px 30px 1px 0;
    border-bottom: 1px solid;
    outline: none;
    width: calc(100% - 30px);
    box-sizing: border-box;
    line-height: 1.5;

    @media only screen and (min-width: 48em) {
      font-size: 48px;
      width: calc(100% - 50px);
    }
  }

  .underline {
    position: absolute;
    bottom: -3px;
    color: transparent;
    background-color: $cochlear-yellow;
    height: 7px;
    overflow: hidden;
    width: auto;
    background-clip: content-box;
    max-width: calc(100% - 30px);

    @media only screen and (min-width: 48em) {
      max-width: calc(100% - 50px);
    }
  }

  .faq-search__close {
    background: $cochlear-black sax(Close, $brand-white) no-repeat;
    background-size: 100%;
    border: 0;
    text-indent: -999em;
    width: 20px;
    height: 20px;
    margin-left: -20px;
  }

  .faq-article__header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin: 40px 0 20px 0;

    @media only screen and (min-width: 48em) {
      flex-direction: row;
    }

    @media only screen and (min-width: 64em) {
      margin-top: 0;
    }

    button {
      color: $cochlear-purple;
      background-color: transparent;
      border: 0;
      padding: 0;
      text-decoration: none;
      text-align: left;
      font-family: $font-primary;
      font-size: 16px;
      line-height: 24px;

      &:before {
        content: ' ';
        background: sax(Chevron, $cochlear-purple) no-repeat;
        transform: rotate(90deg);
        height: 24px;
        width: 24px;
        display: inline-block;
        position: relative;
        top: 6px;
        right: 6px;
      }
    }

    p {
      margin-top: 0;
      font-size: 16px;
    }
  }

  .faq-article__content {
    border-top: 2px solid $lighter-grey;
    border-bottom: 2px solid $lighter-grey;
    padding: 50px 0 20px 0;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    li {
      margin-top: 20px;

      h5 {
        margin: 0;
      }

      button {
        background: transparent;
        border: 0;
        font-size: 15px;
        padding: 0;
        text-decoration: underline;

        &:hover {
          color: $cochlear-purple;
        }
      }
    }
  }

  .faq-article__feedback {
    margin: 20px 0 80px 0;

    h4 {
      font-size: 28px;
      line-height: 32px;
    }

    p {
      margin: 20px 0 40px 0;
    }

    button {
      margin-right: 20px;
    }
  }

  .faq-search__quick-links {
    h3 {
      border-bottom: 5px solid $cochlear-yellow;
      padding-bottom: 10px;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 20px;
    }

    button {
      color: $cochlear-purple;
      background-color: transparent;
      outline: 10px solid $brand-white;
      border: 0;
      border-spacing: auto; /*replaced*/
      padding: 0;
      text-decoration: none;
      text-align: left;
      font-family: $font-primary;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      width: 100%;
      transition-property: none;

      .date {
        display: block;
        color: $cool-grey;
        font-size: 16px;
        margin-top: 5px;
      }

      &:hover,
      &:focus {
        background-color: $lighter-grey;
        outline-color: $lighter-grey;

        .title {
          text-decoration: underline;
        }

        .date {
          text-decoration: none;
        }
      }
    }
  }

  .faq-search__predictive {
    margin-top: 20px;
    position: absolute;
    width: 100%;
    background-color: $brand-white;
    z-index: 2;
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.15);

    li {
      margin-bottom: 0;
      position: relative;

      &:first-child {
        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $brand-white;
          border-left: 10px solid transparent;
          top: -19px;
          left: 10px;
        }
      }
    }

    button {
      padding: 15px;
      outline: 0;
      border-bottom: 1px solid $lighter-grey;
      color: $cool-grey;
      font-weight: 300;

      &:hover,
      &:focus {
        color: $cochlear-purple;
        text-decoration: none;
      }
    }
  }
}

// rtl-styles
div#root > div[dir='rtl'] {
  .faq {
    .faq-article__header {
      button {
        &::after {
          content: ' ';
          background: sax(Chevron, $cochlear-purple) no-repeat;
          transform: rotate(90deg);
          height: 24px;
          width: 24px;
          display: inline-block;
          position: relative;
          top: 6px;
          right: 6px;
        }
        &::before {
          content: none;
        }
      }
    }
    .faq-search__text {
      text-align: right;
    }
    .faq-search__quick-links {
      button {
        text-align: right;
      }
    }
  }
}
