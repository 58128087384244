.campaign-multi-callout-container {
  @include fullwidth-component;

  p,
  li {
    font-family: $font-circular-medium;
  }

  &-banner {
    max-width: 1366px;
    margin: 0 auto;
    padding: 48px 16px;
    text-align: center;

    @media screen and (min-width: 48em) {
      padding: 60px;
    }

    @media screen and (min-width: 80em) {
      padding: 104px;
    }

    &-heading {
      color: $font-darkblue;
      font-family: $font-circular-medium;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 20px;
      text-align: center;

      @media screen and (min-width: 80em) {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .callout-block {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      position: relative;
      margin-top: 12px;

      @media screen and (min-width: 48em) {
        flex: 1 0 auto;
        flex-flow: row wrap;
        margin-top: 24px;

        &.two-block {
          margin-inline: auto;
          max-width: 962px;

          .callout-card {
            width: calc((100% - 24px) / 2); /* gap between columns */
          }
        }

        &.three-block .callout-card {
          width: calc((100% - 48px) / 3); /* gap between columns */
        }
      }

      .callout-card {
        border-radius: 16px;
        padding: 20px;
        text-align: start;

        @media screen and (min-width: 80em) {
          padding: 40px 48px;
        }

        .callout-heading {
          color: $font-darkblue;
          font-family: $font-circular-medium;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 8px;

          @media screen and (min-width: 48em) {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .callout-details {
          color: $font-darkblue;
          font-size: 16px;
          line-height: 24px;
          overflow: hidden;

          @media screen and (min-width: 48em) {
            font-size: 18px;
            line-height: 28px;
          }
        }

        .callout-background-image img {
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          height: 100%;
        }
      }
    }

    .multi-callout-description {
      color: $font-darkblue;
      font-size: 16px;
      line-height: 24px;
      margin-top: 32px;

      @media screen and (min-width: 48em) {
        font-size: 18px;
        line-height: 28px;
        margin-top: 40px;
      }
    }
  }

  &.variant-1 {
    background-color: $cochlear-light-green;

    .callout-card {
      background-color: $cochlear-callout-green;
    }
  }

  &.variant-2 {
    background-color: $brand-white;

    .callout-card {
      background-color: $bg-light-yellow;
    }
  }

  &.variant-3 {
    background-color: $bg-light-white;

    .callout-card {
      background-color: $bg-light-white-1;
    }
  }

  .cta-button {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: $cochlear-purple;
    font-family: $font-circular-medium;
    margin: 24px auto 0;
    border-radius: 16px;
    min-width: 190px;
    padding: 12px 28px 12px 32px;
    width: fit-content;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 47.9375em) {
      margin-top: 24px;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      max-width: 343px;
    }

    &::after {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACpSURBVHgBfY+xDcIwEEXv7FTQUGSBbAA9AtMxAtvkEBMwDhXegIzgMhKNaRBF8HE2QgpWki/5dPJ/Ov2vQbQpT3U13z7c07aQCc2Cllyom+weMezsnZo+oN3LttXMoLB7eQe5dOlf0nHIhx2D9I8cgzAPZcojMWCdMnVhpXIggOK0fCf8AbEuApOYHt/SyJPDCTPVxSkzAeuSjAZ1HTJThqKDhpnPQ2bUB+gUZBvXj9BLAAAAAElFTkSuQmCC");
      left: 12px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: auto;
      transform: none;
      top: 8px;
    }
  }

  .break-line {
    border-bottom: 1px solid $bg-dark-green;
    margin: 0 auto;
    max-width: 1366px;

    @media only screen and (max-width: 47.9375em) {
      margin: 48px 0;
    }
  }
}
