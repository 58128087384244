.results {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media only screen and (min-width: 64em) {
    gap: unset;
  }

  .header {
    background: $cochlear-yellow;
    padding: 24px 20px 20px 20px;
    border-radius: 0px 0px 32px 0px;
    color: $cool-grey;
    @media only screen and (min-width: 64em) {
      padding: 40px 0 36px;
      border-radius: 0px 0px 48px 0px;
    }
    .heading {
      text-align: center;
      font-family: $font-header;
      font-size: 24px;
      font-weight: $font-weight-extra-bold;
      line-height: 28px;
      @media only screen and (min-width: 64em) {
        font-family: $font-header;
        font-size: 38px;
        line-height: 45px;
      }
    }
    .heading,
    .score-copy {
      margin: 0;
      @media only screen and (min-width: 64em) {
        width: $screen-width-medium;
        margin: auto;
        padding: 0 36px;
      }
      @media only screen and (min-width: 77.75em) {
        width: $screen-width-large;
      }
    }
    .score-copy,
    .score-copy span {
      font-family: $font-header;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      @media only screen and (min-width: 64em) {
        font-family: $font-header;
        font-size: 28px;
        line-height: 40px;
      }
    }
    .score-copy {
      font-weight: 400;
    }
    span {
      font-weight: $font-weight-extra-bold;
    }
  }
  .result-media {
    display: flex;
    flex-direction: column;
    padding: 0 35px;
    max-width: 690px;
    margin: auto;
    @media only screen and (min-width: 64em) {
      flex-direction: row;
      max-width: unset;
      justify-content: center;
      width: $screen-width-medium;
      margin: auto;
      padding: 60px 36px;
      align-items: flex-start;
    }

    .main-image-bg {
      position: relative;
      width: 100%;
      padding: 74px 0 60px;
      max-width: 500px;
      margin: 0 auto 18px;
      @media only screen and (min-width: 64em) {
        max-width: 540px;
        width: 60%;
        margin: unset;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        background-image: var(--resultPage-bg);
        aspect-ratio: 1 / 1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .main-image {
        width: 63%;
        position: relative;
        height: auto;
        left: 23%;
        z-index: 2;
        @media only screen and (min-width: 64em) {
          width: 66%;
        }
      }
    }
    .title {
      font-family: $font-header;
      font-size: 32px;
      font-weight: $font-weight-extra-bold;
      line-height: 38px;
      text-align: center;
      color: $font-darkblue;
      margin: 0;
      @media only screen and (min-width: 64em) {
        font-size: 56px;
        line-height: 66px;
        text-align: left;
        margin-top: 74px;
        width: 40%;
      }
    }
  }
  .description {
    font-family: $font-header;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: $cool-grey;
    margin: 0 20px;
    padding-left: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media only screen and (min-width: 64em) {
      margin: 0 auto 92px;
      font-size: 22px;
      line-height: 32px;
      padding: 15px 0 15px 66px;
      max-width: 900px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background: $cochlear-yellow;
      @media only screen and (min-width: 64em) {
        width: 8px;
      }
    }
  }
  .cta-section {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px 30px;
    background: $result-cta-background;
    align-items: center;
    @media only screen and (min-width: 64em) {
      padding: 64px 0;
    }
    .advice,
    .cta-btn {
      font-family: $font-header;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 21px;
      @media only screen and (min-width: 64em) {
        width: $screen-width-medium;
        margin: auto;
      }
      @media only screen and (min-width: 77.75em) {
        width: $screen-width-large;
      }
    }
    .advice {
      margin: 0;
      text-align: center;
      color: $font-darkblue;
      @media only screen and (min-width: 64em) {
        font-size: 28px;
        line-height: 33px;
        margin: auto;
      }
    }
    .cta-btn {
      background: $cochlear-yellow;
      padding: 16px 60px 16px 32px;
      border: 0;
      border-radius: 200px;
      color: $cochlear-purple;
      text-align: left;
      position: relative;
      width: auto;
      margin: auto;
      text-decoration: none;
      cursor: pointer;
      @media only screen and (min-width: 64em) {
        font-size: 18px;
        line-height: 21px;
      }
      &::after {
        content: "";
        position: absolute;
        right: 30px;
        top: 36%;
        width: 20px;
        height: 20px;
        background-image: sax(Chevron, $cochlear-purple);
        transform: rotate(-90deg);
      }
    }
  }
}
