.ProcessFeature {
  width: 100%;
  padding: 40px 0;
  &.Green {
    background: linear-gradient(
      to right bottom,
      $bg-light-green calc(50% - 1px),
      $bg-dark-green calc(50% + 1px)
    );
  }
  &.Grey {
    background: linear-gradient(
      to right bottom,
      $bg-light-grey calc(50% - 1px),
      $bg-dark-grey calc(50% + 1px)
    );
  }
  &.White {
    background: $brand-white;
  }
  @media screen and (max-width: 64em) {
    .l-padding {
      padding: 0;
    }
  }
  .process-feature-title {
    padding: 0 20px;
    h2 {
      color: $font-darkblue;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 40px;
      @media only screen and (min-width: 64em) {
        font-size: 34px;
      }
    }
    .content {
      font-size: 18px;
      margin-bottom: 20px;
      color: $font-darkblue;
      font-weight: normal;
      letter-spacing: -0.03px;
      line-height: 28px;
      p,
      li {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
      strong,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: inherit;
        font-family: inherit;
      }
    }
  }
  .process-feature-cards {
    padding: 0 20px;
    margin-bottom: 40px;
    .content-container__grouper {
      .sl {
        .sl-list {
          .sl-item {
            padding: 0 10px;
            margin-top: 20px;
          }
        }
      }
      @media only screen and (min-width: 64em) {
        .sl {
          .sl-list {
            margin: 0 -20px 0;
            .sl-item {
              padding: 0 10px;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
      .card {
        padding: 30px 30px 20px;
        background: $cochlear-yellow-card;
        height: 100%;
        .icon-box {
          width: 68px;
          height: 68px;
          margin: 10px auto 0px;
          background: $font-darkblue;
          border-radius: 34px;
          h2 {
            text-align: center;
            line-height: 68px;
            color: $brand-white;
            font-size: 28px;
            @media only screen and (min-width: 64em) {
              font-size: 34px;
            }
          }
          .icon-image {
            width: 68px;
            height: 68px;
            padding: 12px;
          }
        }
        h3 {
          text-align: center;
          color: $font-darkblue;
          font-size: 20px;
          margin: 10px 0;
          font-weight: 500;
          letter-spacing: -0.03px;
          line-height: 34px;
          @media only screen and (min-width: 64em) {
            font-size: 28px;
            margin: 15px 0 10px;
          }
          span {
            color: transparent;
            font-size: 0px;
          }
        }
        .content {
          text-align: center;
          margin-bottom: 20px;
          color: $font-darkblue;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: -0.03px;
          line-height: 28px;
          p,
          li {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            letter-spacing: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
          strong,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: inherit;
            font-family: inherit;
          }
        }
      }
    }
  }
  .cta-grouping {
    padding: 0 20px;
    margin: 0;
    .cta-button-ni-link {
      width: 100%;
      margin: 0 20px 20px 0;
      font-weight: 400;
      display: inline-block;
      text-align: center;
      background: $link-colour;
      color: $brand-white;
      border: 2px solid $link-colour;
      box-sizing: border-box;
      text-decoration: none;
      border-radius: 40px;
      position: relative;
      line-height: initial;
      font-size: 16px;
      font-family: $font-circular-regular;
      padding: 15px 50px 15px 35px;
      transition-duration: 150ms;
      transition-property: color, background-color, border-color;
      &::after {
        right: 20px;
        left: inherit;
        position: absolute;
        top: 25px;
        background-image: sax(Chevron, $light-grey);
        content: " ";
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-size: 100%;
        transform: rotate(-90deg);
        transition-duration: 150ms;
        transition-property: left, right, padding, border;
      }
      &:hover {
        background: $link-colour-hover;
        border: 2px solid $link-colour-hover;
        box-sizing: border-box;
        &::after {
          right: 10px;
        }
      }
      &.light {
        background: $brand-white;
        color: $link-colour;
        border: 2px solid $brand-white;
        &::after {
          background-image: sax(Chevron, $cochlear-purple);
        }
      }
      &.secondary {
        box-sizing: border-box;
        background: transparent;
        color: $link-colour;
        border: 1px solid $link-colour;
        box-sizing: border-box;
        margin: 1px;
        &::after {
          font-weight: 400;
          background-image: sax(Chevron, $cochlear-purple);
          right: 20px;
          transition-duration: 0ms;
        }
        &:hover {
          background: transparent;
          color: $link-colour;
          border: 2px solid $link-colour;
          box-sizing: border-box;
          margin: 0;
          &::after {
            right: 10px;
          }
        }
        &.light {
          background: transparent;
          color: $link-colour;
          border: 2px solid $link-colour;
        }
      }
    }
  }
  @media screen and (max-width: 64em) {
    &.Green {
      background: $bg-light-green;
    }
    &.Grey {
      background: $bg-light-grey;
    }
    padding: 0;
    .l-padding {
      padding: 40px 0;
      z-index: 9;
    }
    .process-feature-cards {
      .content-container__grouper {
        .sl {
          margin: 0;
          .sl-list {
            .sl-item {
              padding: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 64em) {
    padding: 80px 0 60px;
    &.is-secondary {
      &.Green {
        background: linear-gradient(
          to left bottom,
          $bg-light-green calc(50% - 1px),
          $bg-dark-green calc(50% + 1px)
        );
      }
      &.Grey {
        background: linear-gradient(
          to left bottom,
          $bg-light-grey calc(50% - 1px),
          $bg-dark-grey calc(50% + 1px)
        );
      }
      .process-feature-title {
        text-align: right;
      }
      .cta-grouping {
        text-align: right;
        .cta-button-ni-link {
          &.secondary {
            margin: 0 calc(20px + 1px) 10px 1px;
            &:hover {
              margin: 0 20px 20px 0;
            }
          }
        }
      }
    }
    .process-feature-title {
      padding: 0;
      .content {
        margin-bottom: 50px;
      }
    }
    .cta-grouping {
      padding: 0;
      margin: 0;
      .cta-button-ni-link {
        margin: 0 20px 20px 0;
        width: inherit;
        text-align: left;
      }
    }
  }
}

// rtl styles
div#root > div[dir="rtl"] {
  .ProcessFeature {
    &.Green {
      background: linear-gradient(
        to left bottom,
        $bg-light-green calc(50% - 1px),
        $bg-dark-green calc(50% + 1px)
      );
    }
    &.Grey {
      background: linear-gradient(
        to left bottom,
        $bg-light-grey calc(50% - 1px),
        $bg-dark-grey calc(50% + 1px)
      );
    }
    &.is-secondary {
      .process-feature-title {
        text-align: left;
      }
      .cta-grouping {
        text-align: left;
      }
    }
    .cta-grouping {
      margin: 10px 0;
      .cta-button-ni-link {
        text-align: center;
        padding: 15px 35px 15px 50px;
        margin: 0 10px 10px 10px;
        &::after {
          left: 20px;
          right: inherit;
          transform: rotate(90deg);
        }
        &:hover {
          &::after {
            left: 10px;
          }
        }
        &.secondary {
          &:hover {
            &::after {
              left: 10px;
            }
          }
        }
      }
    }

    @media screen and (min-width: 64em) {
      &.is-secondary {
        &.Green {
          background: linear-gradient(
            to right bottom,
            $bg-light-green calc(50% - 1px),
            $bg-dark-green calc(50% + 1px)
          );
        }
        &.Grey {
          background: linear-gradient(
            to right bottom,
            $bg-light-grey calc(50% - 1px),
            $bg-dark-grey calc(50% + 1px)
          );
        }
      }
      .sl {
        .sl-list {
          .sl-item {
            padding: 0 10px;
            &:first-child {
              padding-right: 0;
            }
            &:last-child {
              padding-left: 0;
            }
          }
        }
      }
      .cta-grouping {
        .cta-button-ni-link {
          &.secondary {
            margin: 0 11px 10px 11px;
            &:hover {
              margin: 0 10px 10px 10px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 64em) {
      &.Green {
        background: $bg-light-green;
      }
      &.Grey {
        background: $bg-light-grey;
      }
      .cta-grouping {
        .cta-button-ni-link {
          margin: 0 0 10px 0;
        }
      }
    }
  }
}
