.social-share {
  margin-bottom: 30px;
  text-align: center;

  @media screen and (min-width: 64em) {
    margin: 0;
  }

  ul {
    // max-width: 200px;
    // margin: 0 auto;
    list-style: none;
    padding: 0;

    @media screen and (min-width: 64em) {
      max-width: none;
      margin: 0;
      position: absolute;
      left: -130px;
      top: -10px;
    }

    li {
      margin: 0 15px;
      display: inline-block;

      @media screen and (min-width: 64em) {
        margin: 16px 0 0 0;
        float: none;
        display: block;
      }

      &:not(:first-child) {
        border: 0;
        padding-left: 0;
        margin-left: 0;
      }

      span {
        height: 45px;
        width: 45px;
        border-radius: 30px;
        display: block;
        border: solid 1px $mid-grey;
        cursor: pointer;
        text-align: center;
        line-height: 40px;

        &:hover,
        &:focus {
          background-color: $link-colour;

          img {
            filter: brightness(100);
          }
        }

        img {
          width: 25px;
          height: auto;
          vertical-align: middle;
        }
      }

      *::-ms-backdrop,
      span:hover {
        color: $brand-white;
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  .social-share {
    ul {
      @media screen and (min-width: 64em) {
        max-width: none;
        margin: 0;
        position: absolute;
        left: auto; /*replaced*/
        right: -130px;
      }

      li {
        &:not(:first-child) {
          border: 0;
          padding-right: 0;
          margin-right: 0;
          margin-left: 15px;
        }
      }
    }
  }
}
