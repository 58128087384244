.select-input {
  &.is-error {
    select {
      color: $cochlear-error;
    }

    label {
      margin-bottom: 0;
      color: $cochlear-error;

      span {
        color: $cochlear-error;
      }

      &:hover {
        color: $cochlear-error;
      }
    }

    input {
      background-image: sax(Error);
      background-repeat: no-repeat;
      background-position-x: calc(100% - 6px);
      background-position-y: center;
      background-size: 20px;
    }
  }

  .error {
    color: $cochlear-error;
    position: relative;
    display: block;
    margin-top: 10px;
  }
}
