// html[lang="vi"] * {
// 	font-family: $font-NotoSans-regular;
// 	.callout-card .content-container p {
// 		font-family: $font-NotoSans-regular;
// 	}
// }

@mixin fontOverride($countryCode, $font-size-desktop-menu-item: 16px) {
  html {
    &[lang="#{$countryCode}"] {
      * {
        blockquote,
        button,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        input,
        li,
        p,
        select,
        .callout-card .content-container .is-h3,
        .callout-card .content-container p,
        .clinicFinder a.btn,
        .clinicFinder button.btn,
        .clinicFinder .clinic-list .clinic-list-title-bar,
        .clinicFinder .clinic-list .clinic-list-title-bar .filter-label,
        .clinicListing .clinicListing__clinic-address p,
        .clinicListing .clinicListing__clinic-name,
        .clinicListing .clinicListing__clinic-product-offers,
        .clinicFinder p.results-text,
        .country-select-nav .h1,
        .cta-button,
        .cta-button-ni-link,
        .disclaimer,
        .disclaimer h2,
        .disclaimer h6,
        .disclaimer li,
        .disclaimer p,
        .form-box .details-box button.cta,
        .form-box .details-box span,
        .form-dropdown-box .dropdown,
        .global-header .search-box .search-input,
        .landing-banner .content-link,
        .location-list .location-list-item .location-list-item-title,
        .nav-onscreen .nav .nav-item .nav-item-title,
        .nav-region-dropdown .dropdown-menu .dropdown-item.item-country,
        .nav-region-dropdown .nav-region-dropdown__cta,
        .product-color-switcher
          .product-tabs
          .product-info
          .product-options
          .product-option,
        .product-testimonial table td h4,
        .schematik-form .field-container fieldset legend,
        .stacked-accordions .accordion-trigger,
        .tabbed-content .tab-items .tab {
          font-family: $font-NotoSans-regular;
        }
      }

      body {
        font-family: $font-NotoSans-regular;
      }
    }
  }

  @media only screen and (min-width: 64em) {
    html[lang="#{$countryCode}"] * {
      .top-nav .type-selector a,
      .nav-onscreen .nav .nav-item .nav-item-title.nav-item-title-top {
        font-family: $font-NotoSans-regular;
      }

      .nav-onscreen .nav .nav-item .nav-item-title.nav-item-title-top {
        font-size: $font-size-desktop-menu-item;
      }
    }
  }
}

// add country here
// eg
// @include fontOverride("hk");

@include fontOverride("vi", 15px);
