/* Tablet */

/* Mobile */

.popup-RFI {
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 500;
}

.open-cta {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fdc82f;
  border-radius: 25px 0 0 25px;
  width: 275px;
  height: auto;
  padding: 10px 20px;
  z-index: 9995;

  .cta-button {
    font-weight: 900;
    display: inline-block;
    border: 0;
    background-color: #fff;
    color: #3f1482;
    margin: 10px 0 15px;
    padding-bottom: 10px;
    padding-top: 10px;

    &::after {
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%233F1482' d='M17 10l-5 5-5-5' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
}

.closed-cta {
  position: absolute;
  top: 132px;
  right: -145px;
  background-color: #fdc82f;
  border-radius: 25px 25px 0 0;
  width: 335px;
  padding: 10px 10px 25px;
  transform: rotate(-90deg);
  z-index: 9990;

  p {
    color: #3f1482;
    font-size: 20px;
    margin: 5px;
  }

  &:hover {
    -moz-box-shadow: 0 0 15px #56565a;
    -webkit-box-shadow: 0 0 15px #56565a;
    box-shadow: 0 0 15px #ccc;
  }
}

.popup-content {
  text-align: center;
  color: #56565a;
  font-size: 16px;
  margin-top: -20px;

  h5 {
    margin-top: 10px;
  }

  p {
    font-size: 16px;
  }
}

.closed-cta-title {
  margin-left: 65px;
  padding-right: 5px;

  &:hover {
    cursor: pointer;
    margin-right: 10px;
  }
}

.popup-no-title {
  .open-cta {
    height: auto;
    top: 55px;

    .cta-button {
      margin: 15px 0;
    }
  }

  .closed-cta {
    width: 281px;
    right: -120px;
    top: 159px;

    .closed-cta-toptitle {
      text-align: center;
    }

    .closed-cta-title {
      margin-left: 0;
    }
  }
}

#close-icon {
  color: #3f1482;
  font-size: 24px;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
}

.cta-arrow {
  width: 12px;
  height: 12px;
  transform: rotate(-90deg);
  margin-left: 5px;
}

#get-started-mobile {
  display: none;
}

@media only screen and (min-width: 761px) and (max-width: 1023px) {
  .popup-RFI {
    display: none;
  }

  .closed-cta {
    display: none;
  }

  .open-cta {
    display: none;
  }

  #get-started-mobile {
    display: block;
    position: fixed;
    right: 30px;
    bottom: 90px;
    z-index: 9999;
    transition: bottom 0.5s;
  }
}
@media only screen and (max-width: 760px) {
  .popup-RFI {
    display: none;
  }

  .closed-cta {
    display: none;
  }

  .open-cta {
    display: none;
  }

  #get-started-mobile {
    display: block;
    position: fixed;
    right: 30px;
    bottom: 90px;
    z-index: 9999;
    transition: bottom 0.5s;
  }
}
