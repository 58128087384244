@import '../../../../styles/ifu-shared.scss';

.ccl__dpx-error {
  margin-top: $spacing-10x;

  .ccl-c-error-message {
    width: fit-content;
    margin: auto;

    &__content {
      min-height: unset;
      padding-bottom: $spacing-5x;
    }
  }
}

.ccl__dpx-error.horizontal {
  .ccl-c-error-message {
    &__content {
      display: flex;
      justify-content: center;
    }

    &__text {
      margin: 1rem 0 0 1rem;
    }
  }
}
