.help-me-find {
  background-color: $light-grey;
  padding-top: 85px;
  padding-bottom: 40px;
  margin-top: -38px;

  &.tab-dropdown-container__open {
    .animated {
      position: relative;
      z-index: 5;
    }
  }

  .tab-item-description {
    background-color: white;

    @media only screen and (min-width: 64em) {
      padding: 5px;
    }
  }

  .tab-items {
    display: flex;

    .tab {
      border: none;
      background-color: $lighter-grey;
      padding: 10px 15px;

      &:first-child.active,
      &:last-child.active {
        border-left: 0;
        border-right: 0;
      }

      @media only screen and (min-width: 64em) {
        padding: 20px 30px;
      }
    }
  }

  .tabbed-content {
    .tab-panel {
      border: 0;
    }

    margin-top: 20px;

    table {
      border: 0;
    }

    td {
      width: 100%;
      padding: 0;
      background-color: transparent;
      border: 0;
      display: block;

      @media only screen and (min-width: 64em) {
        display: table-cell;
      }

      ul {
        padding: 0;

        li {
          list-style: none;

          a {
            display: block;
            width: 100%;
            padding: 16px 60px 16px 20px;
            background-color: $light-grey;
            text-decoration: none;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              background-image: sax(Arrow, $cochlear-purple);
              width: 16px;
              height: 16px;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              background-size: 100%;
            }
          }
        }
      }

      @media only screen and (min-width: 64em) {
        width: 50%;

        &:nth-child(2) {
          padding: 11px 20px;
        }
      }
    }
  }

  .tab-dropdown {
    position: relative;

    .dropdown-header {
      background-color: $cochlear-yellow;
      padding: 6px 19px;
      position: relative;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        font-size: 18px;
        line-height: 26px;
      }

      &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 6px solid $cochlear-yellow;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
        top: 100%;
        left: 20px;
      }
    }

    .dropdown-body {
      .placeholder {
        width: calc(100%);
        height: 60px;
        background-color: white;
        padding: 0;
        padding-left: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $cool-grey;
        text-align: left;
        border: 0;
        font-family: $font-primary;

        @media only screen and (min-width: 64em) {
          float: left;
        }

        & span {
          position: relative;
          // top: 15px;
        }

        & div {
          width: calc(100% - 60px);
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.list-show {
          &::after {
            content: ' ';
            width: 60px;
            height: 60px;
            background-color: $link-colour;
            background-image: sax(Chevron, white);
            background-position: center;
            background-repeat: no-repeat;
            transform: rotate(180deg);
            border: 0;
            position: absolute;
            top: 38px;
            right: 0;
          }
        }

        &::after {
          content: ' ';
          width: 60px;
          height: 60px;
          background-color: $link-colour;
          background-image: sax(Chevron, white);
          background-position: center;
          background-repeat: no-repeat;
          border: 0;
          position: absolute;
          top: 38px;
          right: 0;
        }
      }

      .dropdown-button {
        width: 60px;
        height: 60px;
        background-color: $link-colour;
        position: relative;
        border: 0;
        float: right;

        &::before {
          content: '';
          position: absolute;
          width: 24px;
          height: 20px;
          background-image: sax(Chevron, white);
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &.list-shown {
          background-color: $link-colour-hover;

          &::before {
            transform: rotate(180deg);
            height: 24px;
          }
        }
      }

      &.dropdown-body__open {
        .placeholder {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .dropdown-list {
      margin-top: 20px;
      position: absolute;
      right: 0;
      width: 100%;
      background-color: white;
      z-index: 5;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.15);

      @media only screen and (min-width: 64em) {
        margin-top: 80px;
      }

      &.is-shown {
        opacity: 1;
        visibility: visible;
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          border: 1px solid rgba(204, 204, 204, 0.5);
          margin: 0;
          border-top-width: 0;
          cursor: pointer;

          button {
            width: 100%;
            padding: 15px;
            border: 0;
            background-color: transparent;
            font-family: $font-primary;
            text-align: left;
          }

          &:first-child {
            border-top-width: 1px;

            &::before {
              position: absolute;
              content: '';
              width: 0;
              height: 0;
              border-top: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid rgba(204, 204, 204, 0.5);
              border-left: 7px solid transparent;
              top: -13px;
              right: 19px;
            }

            &::after {
              position: absolute;
              content: '';
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid white;
              border-left: 6px solid transparent;
              top: -11px;
              right: 20px;
            }

            &:hover {
              &::after {
                border-bottom: 6px solid $light-grey;
              }
            }
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $light-grey;
            color: $link-colour;
          }
        }
      }
    }

    .tab-links {
      margin-top: 20px;

      ul {
        list-style: none;
        padding: 0;

        li {
          a {
            display: block;
            width: 100%;
            padding: 16px 60px 16px 20px;
            background-color: $light-grey;
            text-decoration: none;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              background-image: sax(Chevron, $cochlear-purple);
              width: 16px;
              height: 16px;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
              background-size: 100%;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
    .tab-dropdown {
      .dropdown-header {
        width: 210px;
        display: inline-block;
        padding: 17px 30px 17px 40px;
        vertical-align: top;

        &::after {
          border-top: 6px solid transparent;
          border-left: 6px solid $cochlear-yellow;
          right: -12px;
          left: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .dropdown-body {
        display: inline-block;
        width: calc(100% - 210px);

        .placeholder {
          &.list-show {
            &::after {
              top: 0;
            }
          }

          &::after {
            top: 0;
          }
        }
      }

      .dropdown-list {
        margin-top: 3px;
        width: calc(100% - 209px);
        position: absolute;
        top: 76px;

        ul {
          li {
            &:first-child {
              border-top-width: 1px;

              &::before {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid rgba(204, 204, 204, 0.5);
                border-left: 10px solid transparent;
                top: -19px;
                right: 19px;
              }

              &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-top: 9px solid transparent;
                border-right: 9px solid transparent;
                border-bottom: 9px solid white;
                border-left: 9px solid transparent;
                top: -17px;
                right: 20px;
              }

              &:hover {
                &::after {
                  border-bottom: 10px solid $light-grey;
                }
              }
            }
          }
        }
      }

      .tab-body-content {
        flex: 1 1 100%;
      }

      .tab-links {
        margin-top: 0;
        flex: 1 1 100%;

        ul {
          padding: 0 40px;
        }
      }
    }
  }
}

// rtl styles
div#root > div[dir='rtl'] {
  // reverse direction of ordered lists in nav
  .help-me-find {
    .tabbed-content {
      td {
        &:first-child {
          border: none;
        }
        ul {
          li {
            a {
              padding: 16px 20px 16px 60px;
              &::after {
                transform: rotate(180deg) translateY(50%);
                right: auto; /*replaced*/
                left: 15px;
              }
            }
          }
        }
      }
    }
    .tab-dropdown {
      .dropdown-header {
        &::after {
          @media screen and (min-width: 64em) {
            left: -12px;
            right: auto;
            top: 50%;
            border-top-color: transparent;
            border-right-color: $cochlear-yellow;
          }
          right: 20px;
          top: 100%;
          border: 6px solid transparent;
          border-right-color: transparent;
          border-top-color: $cochlear-yellow;
        }
      }
      .dropdown-body {
        .placeholder {
          padding-left: 0px;
          padding-right: 16px;
          text-align: right;
          &.list-show {
            &::after {
              display: none;
            }
          }
          &::after {
            display: none;
          }
          &.list-show {
            &::before {
              content: ' ';
              width: 60px;
              height: 60px;
              background-color: $link-colour;
              background-image: sax(Chevron, white);
              background-position: center;
              background-repeat: no-repeat;
              transform: rotate(-180deg);
              border: 0;
              position: absolute;
              top: 38px;
              left: 0;
              @media screen and (min-width: 64em) {
                top: 0px;
              }
            }
          }

          &::before {
            content: ' ';
            width: 60px;
            height: 60px;
            background-color: $link-colour;
            background-image: sax(Chevron, white);
            background-position: center;
            background-repeat: no-repeat;
            border: 0;
            position: absolute;
            top: 38px;
            left: 0;
            @media screen and (min-width: 64em) {
              top: 0px;
            }
          }
        }
      }
      .dropdown-list {
        right: auto; /*replaced*/
        left: 0px;

        li {
          button {
            text-align: right;
          }

          &:first-child {
            &::before {
              right: auto; /*replaced*/
              left: 19px;
            }
            &::after {
              right: auto; /*replaced*/
              left: 20px;
            }
          }
        }
      }
    }
  }
}
