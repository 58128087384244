.header-section {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 40px 0;
  @media only screen and (min-width: 64em) {
    flex-direction: row;
    align-items: flex-start;
    width: $screen-width-medium;
    margin: auto;
    padding: 0 36px;
  }
  @media only screen and (min-width: 77.75em) {
    width: $screen-width-large;
  }
  .header-media {
    position: relative;
    line-height: 0;
    width: 79%;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: var(--landingPage-bg);
      aspect-ratio: 1 / 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: auto;
      height: 100%;
      transform: translate(36%, 30%) scale(1.4);
      transform-origin: bottom right;
      z-index: 1;
      @media only screen and (min-width: 64em) {
        transform: translate(32%, 32%) scale(1.4);
      }
    }
    .main-image {
      width: 100%;
      position: relative;
      height: auto;
      z-index: 2;
      border-radius: 0 0 calc(35% + 1px) 0;
    }
    @media only screen and (min-width: 64em) {
      position: absolute;
      width: 450px;
    }
    @media only screen and (min-width: 77.75em) {
      width: 490px;
    }
  }
}
.header-copy {
  position: relative;
  top: -10px;
  padding: 42px 22px;
  background-color: $cochlear-yellow;
  border-radius: 0 0 64px 0;
  @media only screen and (min-width: 64em) {
    top: 0;
    padding: 60px 0;
    width: $screen-width-medium;
    margin: auto;
    border-radius: 0 0 48px 0;
  }
  @media only screen and (min-width: 77.75em) {
    width: $screen-width-large;
  }

  .heading,
  .sub-heading {
    color: $cool-grey;
    @media only screen and (min-width: 64em) {
      padding-right: 16px;
      margin-inline-start: 53%;
    }
    @media only screen and (min-width: 77.75em){
      margin-inline-start: 51%
    }
  }

  .heading {
    margin-bottom: 0;
    font-size: $font-size-large;
    font-weight: $font-weight-extra-bold;
    line-height: 28px;
    @media only screen and (min-width: 64em) {
    margin-bottom: 12px;
      font-size: $font-size-xxl;
      line-height: 41px;
    }
  }
  .sub-heading {
    margin-top: 0;
    font-size: $font-size-medium;
    font-family: $font-primary-bold;
    font-weight: $font-weight-bold;
    line-height: 22px;
    @media only screen and (min-width: 64em) {
      font-size: $font-size-xl;
      line-height: 31px;
    }
  }
}
.copy-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 20px;
  @media only screen and (min-width: 64em) {
    width: $screen-width-medium;
    margin: auto;
    gap: 32px;
    padding: 32px 36px 104px;
  }
  @media only screen and (min-width: 77.75em) {
    width: $screen-width-large;
  }

  .description {
    color: $cool-grey;
    font-size: $font-size-small;
    line-height: $line-height-medium;
    font-weight: 300;
    font-family: $font-primary-light;
    @media only screen and (min-width: 64em) {
      font-size: 18px;
      font-family: $font-primary-bold;
    }     
  }
  .step-title {
    color: $cool-grey;
    text-align: center;
    font-size: 18px;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    font-family: $font-primary-bold;
    padding: 0 16px;
    @media only screen and (min-width: 64em) {
      text-align: left;
      padding: 0;
    }
     
  }
  .cta-button-wrapper {
    .cta-button {
      @include cta-button;
      padding: 16px 0;
      font-family: unset;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 21px;
      z-index: 1;
      @media only screen and (min-width: 64em){
        padding: 16px 32px;
      }
    }
  }

  .description, .step-title, .cta-button-wrapper > .cta-button {
    @media only screen and (min-width: 64em) {
      margin-inline-start: 53%;
    }
    @media only screen and (min-width: 77.75em){
      margin-inline-start: 51%
    }
  }
}
.hearing-quiz-footer {
  padding: 32px 20px;
  border-top: 1px solid $cool-grey;
  color: $cool-grey;
  @media only screen and (min-width: 64em) {
    padding: 40px 20px;
    border-top: 1px solid $bg-dark-grey;
  }
  .quiz-disclaimer,
  .references {
    font-size: $font-size-x-small;
    font-weight: 300;
    line-height: $line-height-small;
    font-family: $font-primary-bold;
    margin: 0;
    @media only screen and (min-width: 64em) {
      width: $screen-width-medium;
      margin: auto;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      font-family: $font-primary-bold;
      padding: 0 36px;
    }
    @media only screen and (min-width: 77.75em) {
      width: $screen-width-large;
    }
  }
  .quiz-disclaimer {
    padding-bottom: 24px;
  }
}
