.events-calendar-page {
  margin: 0 auto 60px auto;
  min-height: 450px;
  // Loading spinner
  // --------------------------------------------------------
  .lds-dual-ring {
    padding-top: 20px;
    @media only screen and (min-width: 64em) {
      padding-top: 0;
    }
  }
}

/*********************************************************
The following styles are based on the class names created
by the cvents js. If they were to change, this css would
also need to change.
**********************************************************/

/* Filter backdrop */
#calendar-widget-content .SlideUpAnimation__transitionWrapper___XA_de {
  background: rgba(0, 0, 0, 0.6);
}

/* max-width applied to filter overlay */
#calendar-widget-content .SlideUpAnimation__dragContainer___3pg5F {
  transform: translateX(-50%);
  left: 50% !important;
  max-width: 700px;
}

/* text label applied to apply/close button */
#calendar-widget-content .Filters__close___2CwdD::before {
  content: 'Apply';
  font-size: 14px;
  top: -3px;
  position: relative;
  left: -5px;
  margin: 0 0 0 10px;
}
