.hq-contact-form {
  .contact-header {
    background: $cochlear-yellow;
    padding: 20px 22px 25px;
    border-radius: 0;
    color: $cool-grey;
    font-family: $font-header;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    text-align: center;
    @media only screen and (min-width: 64em) {
      padding: 49px 0 51px;
      border-radius: 0px 0px 48px 0px;
      color: $cool-grey;
      font-size: 38px;
      line-height: 45.37px;
    }
  }
  .contact-sub-header {
    font-family: $font-header;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    padding: 32px 20px 0;
    color: $coc-contact-tag;
    @media only screen and (min-width: 48em){
      width: $screen-width-sm;
      margin: auto;
    }
    @media only screen and (min-width: 64em) {
      padding: 50px 0 0;
      width: $screen-width-sm;
      font-weight: 450;
      line-height: 27px;
    }
  }
  #sc-form form {
    margin-bottom: 32px;
    @media only screen and (min-width: 64em) {
      margin: 12px auto 50px;
      width: $screen-width-sm;
    }
    .ccl__button__text{
      font-family: $font-header;
      color: $cochlear-purple;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 21px;
    }
  }
}
