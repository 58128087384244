.LeftRightAlignVideo {
  width: 100%;
  padding: 40px 0;
  @media screen and (max-width: 64em) {
    .l-padding {
      padding: 0;
    }
  }
  .product-media-title-mb {
    display: block;
    padding: 0 20px;
    h2 {
      font-size: 28px;
      color: $font-darkblue;
      margin: 0 0 20px;
      font-weight: 500;
      letter-spacing: -0.03px;
      line-height: 40px;
    }
  }
  .product-media-container {
    .product-media-banner {
      position: relative;
      background: rgba(0, 0, 0, 0);
      .l-padding {
        width: 100%;
        .video-modal-cta {
          background: rgba(0, 0, 0, 0);
          width: 100%;
        }
      }
    }
  }
  .product-media-description {
    padding: 0 20px;

    &.lr-align-left {
      display: none;
    }
  }

  .product-media-title-pc {
    display: none;
  }
  .content {
    color: $font-darkblue;
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: -0.03px;
    font-weight: normal;
    line-height: 28px;

    strong,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  .cta-button-link {
    display: inline-block;
    width: auto;
    height: 53px;
    margin-right: 20px;
    .cta-button-image {
      width: auto;
      height: 50px;
    }
  }
  .cta-button-ni-link {
    @include cta-button-ni-link;
    font-family: $font-circular-regular;

    &.secondary {
      background: transparent;
      box-sizing: border-box;
      color: $link-colour;
      border: 1px solid $link-colour;
      box-sizing: border-box;
      margin-top: 1px;
      margin-inline: 1px;
      &:hover {
        background: transparent;
        border: 2px solid $link-colour;
        margin-top: 0;
        margin-inline: 0;
      }
    }
  }

  @media screen and (min-width: 64em) {
    padding: 80px 0;
    .product-media-title-mb {
      display: none;
    }
    .product-media-container {
      width: 50%;
      display: inline-flex;
      vertical-align: top;
      padding: 0;
      .product-media-banner {
        height: inherit;
        .l-padding {
          padding: 0;
          .video-modal-cta {
            height: inherit;
            &::before {
              top: 45%;
            }
            &::after {
              top: 45%;
            }
          }
          .video-banner {
            height: 100%;
            overflow: hidden;
            background: {
              repeat: no-repeat;
              size: cover;
              position: center;
            }

            @media only screen and (min-width: 64em) {
              height: auto;
              overflow: hidden;
              margin-top: 0;
            }

            img {
              width: 100%;
              height: auto;
              display: inherit;
            }
          }
        }
      }
    }
    .product-media-description {
      width: 50%;
      display: none;

      &.lr-align-left {
        padding: 0 5% 0 0;
        display: none;
        &.is-active {
          display: inline-block;
        }
      }
      &.lr-align-right {
        padding: 0 0 0 5%;
        display: none;
        &.is-active {
          display: inline-block;
          .product-media-title-pc {
            display: block;
          }
        }
      }

      .product-media-title-pc {
        display: block;
        h2 {
          font-size: 34px;
          color: $font-darkblue;
          margin: 0 0 35px;
          font-weight: 500;
          letter-spacing: -0.03px;
          line-height: 40px;
        }
      }
      .content {
        font-size: 18px;
        margin-bottom: 50px;
        color: $font-darkblue;
      }
      a.cta-button-link {
        display: inline-block;
        width: auto;
        height: 53px;
        margin-right: 20px;
        .cta-button-image {
          width: auto;
          height: 53px;
        }
      }
      .cta-button-ni-link {
        width: auto;
        text-align: left;
      }
    }
  }
}

// rtl styles
div#root > div[dir="rtl"] {
  .LeftRightAlignVideo {
    .product-media-description {
      a.cta-button-link {
        margin-left: 20px;
        margin-right: 0;
      }
      .cta-button-ni-link {
        text-align: center;
        padding: 15px 35px 15px 50px;
        margin: 0 0 20px 20px;
        &::after {
          left: 20px;
          right: inherit;
          transform: rotate(90deg);
        }
        &:hover {
          &::after {
            left: 10px;
          }
        }
        &.secondary {
          margin: 1px;
          &:hover {
            margin: 0;
            &::after {
              left: 10px;
            }
          }
        }
      }
    }
    @media only screen and (min-width: 64em) {
      .product-media-description {
        &.lr-align-left {
          padding: 0 0 0 5%;
        }
        &.lr-align-right {
          padding: 0 5% 0 0;
        }
      }
    }
  }
}
