$color_1: #3f1482;
$color_2: #56565a;
$font-family_1: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans',
  'Helvetica Neue', Arial, sans-serif;
$background-color_1: #3f1482;
$background-color_2: white;
$background-color_3: #f6f6f6;
$background-color_4: #fdc82f;
$background-color_5: #efefef;
$background-color_6: #d6d6d6;
$border-color_1: transparent;

.help-me-find-tabs {
  ul {
    margin: 0;

    li {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.help-me-find-links {
  .lto3-ddb {
    ul {
      li {
        margin-top: 0;

        &:not(:last-child) {
          button {
            border-bottom: 1px solid grey;
          }
        }

        a {
          width: 100%;
          text-align: left;
          padding: 10px 30px;
          position: relative;
          background: #fff;
          text-decoration: none !important;
          display: block;

          &:hover {
            color: $color_1;
          }

          &::after {
            content: ' ';
            width: 48px;
            height: 48px;
            background-color: $background-color_1;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFF' d='M17 10l-5 5-5-5' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-position: 50%;
            background-repeat: no-repeat;
            border: initial;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(-90deg);
          }
        }
      }
    }
  }
}

.help-me-find-variant {
  .l-padding {
    position: relative;
    padding: 0 36px;
    margin: 0 auto;
  }

  .tab-dropdown {
    position: relative;

    .dropdown-header {
      &::after {
        z-index: 1;
      }
    }

    .dropdown-body {
      .lto3-ddb {
        ul {
          padding: 0;
          margin-bottom: 0;
          margin-top: 0;

          li {
            list-style: none;
            width: 100%;
            background: #fff;
            color: $color_2;

            button {
              border: none;
              width: 100%;
              text-align: left;
              padding: 10px 30px;
              position: relative;
              background: #fff;
              padding-right: 48px !important;

              span {
                font-size: 18px;
                line-height: 28px;
                color: $color_2;
              }

              &:focus {
                outline: 0;
              }

              &:hover {
                color: $color_1;

                span {
                  color: $color_1;
                  border-bottom: 2px solid #fdc82f;
                }
              }

              &::before {
                content: ' ';
                width: 48px;
                height: 100%;
                background-color: $background-color_1;
                position: absolute;
                top: 0;
                right: 0;
              }

              &::after {
                content: ' ';
                width: 48px;
                height: 48px;
                background-color: $background-color_1;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFF' d='M17 10l-5 5-5-5' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-position: 50%;
                background-repeat: no-repeat;
                border: initial;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) rotate(-90deg);
              }
            }

            &:not(:first-child) {
              button {
                margin-top: -10px;
              }
            }

            &:first-child {
              button {
                margin-top: 0;
              }
            }

            &:not(:last-child) {
              button {
                border-bottom: 1px solid grey;
              }

              a {
                border-bottom: 1px solid grey;
              }
            }

            button.lto3selected {
              color: $color_1;

              &::after {
                transform: translateY(-50%) rotate(0deg);
              }

              span {
                color: $color_1;
                border-bottom: 2px solid #fdc82f;
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}

.react-tabs {
  margin-top: 80px;
  background-color: $background-color_2;
}

.lto3tabs {
  display: none;

  .react-tabs__tab-panel {
    border-top: 3px solid #fdc82f;
  }

  h3 {
    font-weight: 600;
    color: $color_2;
    font-size: 28px;
    line-height: 32px;
  }

  .tab-body-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    vertical-align: bottom;
    align-content: center;
  }
}

.lto3tabs.active {
  display: block;
}

.react-tabs__tab-list {
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #efefef;
}

.react-tabs__tab {
  flex: 1 1 100%;
  margin: 0;
  width: 100%;
  border: initial;
  background-color: $background-color_2;
  padding: 10px 16px 15px;
  font-size: 16px;
}

.react-tabs__tab--selected {
  padding-bottom: 10px;
  border-bottom: 5px solid #3f1482;
}

.react-tabs__tab-panel--selected {
  padding: 20px;
}

@media only screen and (min-width: 64em) {
  .react-tabs {
    margin-top: 10px;
  }

  .react-tabs__tab-list {
    border-bottom: initial;
  }

  .react-tabs__tab {
    background-color: $background-color_3;
    padding: 15px 40px;
    border-bottom: 1px solid #efefef;
    color: $color_1;
    text-align: left;
    font-family: $font-family_1;
    font-weight: 700;
    position: relative;

    &::before {
      content: '';
      transition: height 0.3s;
      background-color: $background-color_4;
      width: 100%;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:not(.react-tabs__tab--selected) {
      &:hover {
        cursor: pointer;
        background-color: $background-color_5;
      }

      &:focus {
        background-color: $background-color_5;
      }

      &:active {
        background-color: $background-color_6;
      }
    }
  }

  .react-tabs__tab--selected {
    background-color: $background-color_2;
    border-color: $border-color_1;

    &::before {
      height: 5px;
    }
  }

  .react-tabs__tab-panel {
    display: flex;
  }

  .react-tabs__tab-panel--selected {
    padding: 40px;
  }

  .help-me-find-variant {
    .tab-dropdown {
      .dropdown-header {
        width: 210px;
        display: inline-block;
        padding: 17px 30px 17px 40px;
        vertical-align: top;
        white-space: pre-wrap;

        &::after {
          border-top: 6px solid transparent;
          border-left: 6px solid #fdc82f;
          right: -12px;
          left: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .help-me-find-variant {
    .tab-dropdown {
      .dropdown-header {
        height: 195px;
      }

      .dropdown-body {
        height: 195px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .help-me-find-variant {
    .tab-dropdown {
      .dropdown-header {
        float: none !important;
      }
    }
  }

  .tab-body-content {
    h3 {
      position: relative !important;
      top: 0 !important;
      transform: none !important;
    }
  }
}
