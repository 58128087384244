// ==========================================================================
// shared
// ==========================================================================

$light-grey: #efefef;
$cochlear-yellow: #fdc82f;
$cochlear-purple: #3f1482;
$color-base-brandxtra-brandnavy: #384967;
$font-darkblue: #384967;
$color-lightmode-semantic-background-highlightsubtle: #faf9f6;

$spacing-1x: 0.5rem;
$spacing-10x: 10 * $spacing-1x;
$spacing-5x: 5 * $spacing-1x;

$font-circular-regular: 'Circular-Regular', 'Trebuchet MS', 'Gill Sans',
  'Helvetica Neue', Arial, sans-serif;

$font-circular-medium: 'Circular-Medium', 'Trebuchet MS', 'Gill Sans',
  'Helvetica Neue', Arial, sans-serif;

// ==========================================================================
// shared
// ==========================================================================
