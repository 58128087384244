$font-size-xxl: 35px;
$font-size-xl: 26px;
$font-size-large: 24px;
$font-size-medium: 19px;
$font-size-small: 16px;
$font-size-x-small: 12px;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;
$line-height-normal: normal;
$line-height-small: 16px;
$line-height-medium: 22px;
$color-charcoal: #404042;
$screen-width-sm: 768px;
$screen-width-small: 100%;
$screen-width-medium: 1024px;
$screen-width-large: 1180px;
$modal-overlay-background: #000000b2;
$result-cta-background: #D6D6D680;
$coc-orange: #f6b00f;
$coc-contact-tag: #5E5E62;