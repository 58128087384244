.mobile-toolbar {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: 60px;
  background-color: white;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);

  @media only screen and (min-width: 64em) {
    display: none;
  }

  ul {
    padding: 0;

    li {
      margin: 0;
      list-style: none;
      width: 25%;
      float: left;
      text-align: center;

      button,
      a {
        line-height: 75px;
        text-decoration: none;
        border: 0;
        font-family: $font-primary;
        font-size: 18px;
        display: block;
        margin: 9px auto 0 auto;
        width: 100%;
        padding: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: top center;

        &:hover,
        &:focus,
        &:active {
          color: $cochlear-purple;
        }

        &.menu {
          background-image: sax(Hamburger, $cochlear-purple);
        }

        &.contact {
          background-image: sax(Phone, $cochlear-purple);
        }

        &.clinic {
          background-image: sax(Location, $cochlear-purple);
        }

        &.search {
          background-image: sax(Search, $cochlear-purple);
        }

        &.close {
          float: none;
          background-image: sax(Close, $cochlear-purple);
        }

        // iPhone X style
        /* stylelint-disable */
        @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
          // padding-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
